/* eslint-disable no-return-await */
/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect, useState, useRef } from 'react';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaRegCircle,
  FaTag,
  FaEye,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import api from '../../services/apiFinanceiro';

function ConsultaContrato() {
  let timeoutConsulta;
  const history = useHistory();
  const [contratos, setContratos] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);

  const [filtros, setFiltros] = useState(null);

  const refCliente = useRef(null);
  const refDocumento = useRef(null);
  const refStatus = useRef(null);

  const debounceConsulta = async (body) =>
    await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });

  useEffect(() => {
    async function getContratos() {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let filtroConsulta = '';
        if (filtros) {
          Object.entries(filtros).forEach(([key, value]) => {
            if (['string', 'number'].includes(typeof value)) {
              filtroConsulta += `${key}=${value}&`;
            } else if (value) {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          });
        }
        const { data, headers } = await api.get(
          `/contratos?page=${selectedPage}&${filtroConsulta}`
        );
        if (data) {
          setTotal(headers['x-total-count']);
          setPages(headers['x-total-pages']);
          setContratos(data);
        }
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar dados!');
        Swal.close();
      }
    }
    getContratos();
  }, [selectedPage, filtros]);

  function viewContract(id) {
    history.push(`/editar-contrato/${id}`);
  }

  async function renovarContrato(id) {
    history.push(`/renovar-contrato/${id}`);
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Contratos</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {total || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              history.push('/novo-contrato');
            }}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Cliente"
                  ref={refCliente}
                  onChange={() =>
                    debounceConsulta({
                      cliente: refCliente?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="Documento"
                  ref={refDocumento}
                  onChange={() =>
                    debounceConsulta({
                      documento: refDocumento?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <select
                  className="form-control"
                  placeholder="Status"
                  name="status"
                  ref={refStatus}
                  onChange={() =>
                    debounceConsulta({
                      status: refStatus?.current?.value,
                    })
                  }
                >
                  <option value="">Todos os status</option>
                  <option value="Vigente">Vigente</option>
                  <option value="Vencido">Vencido</option>
                  <option value="Finalizado">Finalizado</option>
                  <option value="Cancelado">Cancelado</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table className="mb-5" responsive>
          <thead>
            <tr>
              <th scope="col" className="bt-0 t-left">
                Cliente / Fornecedor
              </th>
              <th scope="col" className="bt-0 t-left">
                Descrição
              </th>
              <th scope="col" className="bt-0 t-left">
                Documento
              </th>
              <th scope="col" className="bt-0 t-left">
                Data Vencimento
              </th>
              <th scope="col" className="bt-0 t-left">
                Status
              </th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {contratos?.length ? (
              contratos?.map((contrato) => (
                <tr key={contrato?.id}>
                  <td className="t-left" data-label="Cliente">
                    {contrato?.cliente_fornecedor?.nome}
                  </td>
                  <td className="t-left" data-label="Descricao">
                    {contrato?.tags &&
                      contrato?.tags?.map((e) => (
                        <span>
                          <FaTag color={e.cor} className="mr-2 td-tooltip" />
                          <span className="mr-2 td-tooltiptext">{e.label}</span>
                        </span>
                      ))}
                    {contrato.descricao}
                  </td>
                  <td className="t-left" data-label="Documento">
                    {contrato?.documento}
                  </td>
                  <td className="t-left" data-label="Data vencimento">
                    {contrato?.data_fim_vigencia &&
                    new Date(contrato?.data_fim_vigencia) <
                      new Date('3000-01-01T00:00:00.000+00:00').getTime()
                      ? moment(
                          contrato?.data_fim_vigencia?.split('T')[0]
                        ).format('DD/MM/YYYY')
                      : 'Indeterminado'}
                  </td>
                  <td className="t-left" data-label="Status">
                    {contrato?.status}
                  </td>
                  <td data-label="Ações" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => viewContract(contrato.id)}>
                          <FaEye className="mr-1" />
                          Vizualizar
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => renovarContrato(contrato.id)}
                        >
                          <FaRegCircle className="mr-1" />
                          Renovar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pages || 1}
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ConsultaContrato;
