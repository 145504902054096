import React, { useEffect, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { Button, Col, CardFooter, Row, Card, CardBody } from 'reactstrap';

import api from '../../services/api';
import {
  Image,
  Input,
  ReactSelectAsyncCreatable,
  Select,
  Editor,
} from '../../components/unform/index';

function NovoPost() {
  const formRef = useRef();
  const history = useHistory();
  const selectOptions = useRef();
  const query = useMemo(
    () =>
      String(history?.location?.search || '')
        .replace('?', '')
        .split('&')
        .reduce((a, b) => {
          const [key, value] = b.split('=');

          a[key] = value;

          return a;
        }, {}),
    [history.location]
  );

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/categorias?titulo=${inputValue}`);
    return data.map((e) => ({ value: e.id, label: e.titulo }));
  }

  const optionsCategorias = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function handleSubmit(e) {
    const errors = {};
    Object.keys(e).forEach((f) => {
      if (!e[f] && !(f === 'file' && query?.id)) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);
    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho');
    }

    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    Object.entries(e).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      if (query?.id) {
        await api.put(`/blog/${query?.id}`, formData);
        toast.success('Post alterado com sucesso');
      } else {
        await api.post('/blog', formData);
        toast.success('Post criado com sucesso');
      }
      Swal.close();
      history.push('/blog');
    } catch (er) {
      const msg = er?.response?.data?.error;
      return Swal.fire({
        title: 'Erro ao criar post',
        text: typeof msg === 'string' ? msg : er.message,
        icon: 'error',
      });
    }
  }

  async function handleCriarCategoria(titulo) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.post('/categorias', {
        titulo,
      });
      const categoria = formRef.current.getFieldRef('id_blog_categoria');
      categoria.select.state.value = {
        value: data.id,
        label: titulo,
      };
      categoria.select.updater.enqueueForceUpdate(categoria);

      Swal.close();
    } catch (err) {
      const msg = err?.response?.data?.error;
      return Swal.fire({
        title: 'Erro ao criar categoria',
        text: typeof msg === 'string' ? msg : err.message,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    async function getDadosPost(id) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data } = await api.get(`/blog/${id}`);
        formRef.current.setData({
          ...data,
          file: data.img_url,
          id_blog_categoria: {
            value: data.BlogCategorium.id,
            label: data.BlogCategorium.titulo,
          },
        });
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar post!');
      }
    }
    if (query?.id) {
      getDadosPost(query?.id);
    }
  }, [query]);
  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Novo post blog</h4>
        </Col>
      </Row>
      <Card className="p-2">
        <CardBody>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={4} sm={12}>
                <Image
                  name="file"
                  label="Imagem"
                  objectFit="cover"
                  btnSelect="Selecionar"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <Input
                  name="titulo"
                  placeholder="Título"
                  label="Título"
                  className="form-control"
                />
              </Col>
              <Col lg={3}>
                <ReactSelectAsyncCreatable
                  isClearable
                  loadOptions={(valor) => optionsCategorias(valor)}
                  placeholder="Categoria"
                  name="id_blog_categoria"
                  defaultOptions
                  label="Categoria"
                  onCreateOption={(valor) => handleCriarCategoria(valor)}
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control"
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Ativo',
                      label: 'Ativo',
                    },
                    {
                      value: 'Inativo',
                      label: 'Inativo',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row />
            <hr />
            <Row>
              <Col md={12} sm={12}>
                <Editor name="html" label="Matéria" />
              </Col>
            </Row>
            <CardFooter>
              <Button type="submit">Salvar</Button>
            </CardFooter>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default NovoPost;
