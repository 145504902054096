/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import api from '../../services/api';

// import { Container } from './styles';

function PessoaJuridica() {
  let timeoutConsulta;
  const [clientes, setClientes] = useState([]);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [filtros, setFiltros] = useState(null);

  const [selectedUser, setSelectedUser] = useState(null);

  const [modal, setModal] = useState(false);

  const [total, setTotal] = useState(0);

  const toggleModal = () => setModal(!modal);

  const refNome = useRef(null);
  const refDocumento = useRef(null);

  useEffect(() => {
    async function getClientes() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        let filtroConsulta = '';
        if (filtros) {
          Object.entries(filtros).forEach(([key, value]) => {
            if (['string', 'number'].includes(typeof value)) {
              filtroConsulta += `${key}=${value}&`;
            } else if (value && key !== 'reload') {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          });
        }
        const { data } = await api.get(
          `/pessoa-fisica-juridica/PJ?page=${page + 1}&${filtroConsulta}`
        );
        if (data?.pessoaJuridica) {
          setClientes(data?.pessoaJuridica);
          setPagination(data?.pages);
          setTotal(data?.total);
        }
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar clientes!');
        Swal.close();
      }
    }
    getClientes();
  }, [page, filtros]);

  const debounceConsulta = async (body) =>
    await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });

  async function verInfos(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/cadastro-fisica-juridica/PJ/${id}`);
      if (data) {
        setSelectedUser(data);
        toggleModal();
        Swal.close();
      }
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  async function handleAlterarEmail(dados) {
    if (!dados.email) {
      return toast.error('Informe o email');
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/alterar-email-backoffice', dados);
      toast.success('Email alterado com sucesso');
      setFiltros((old = {}) => ({
        ...old,
        reload: !old?.reaload,
      }));
    } catch (err) {
      console.log(err);
      Swal.close();
      toast.error('Erro interno');
    }
  }

  function swalEmail(c) {
    Swal.fire({
      html: `<h1>Alterar email</h1><br/><strong>Razão social: ${c?.razao_social}<br />CNPJ: ${c?.cnpj}</strong>`,
      text: 'Digite o novo email',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
      },
      inputValue: `${c?.email_comercial}`,
      showCancelButton: true,
      cancelButtonText: 'cancelar',
    }).then(({ isConfirmed, value }) => {
      if (!isConfirmed || !value) return false;

      handleAlterarEmail({
        email: value,
        id: c.id,
        tipo: 'PJ',
      });
    });
  }

  function formataCPF(cpf) {
    if (cpf) {
      cpf = cpf.replace(/[^\d]/g, '');
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return '';
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Pessoa Jurídica</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3  d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted align-items-center">
              Total de registros: {total}
            </h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Nome"
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="Documento"
                  ref={refDocumento}
                  onChange={() =>
                    debounceConsulta({
                      documento: refDocumento?.current?.value,
                    })
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>CNPJ</th>
              <th>Razão Social</th>
              <th>Representante legal</th>
              <th>Telefone</th>
              <th className="justify-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {clientes?.length ? (
              clientes?.map((cli) => (
                <tr key={cli?.id}>
                  <td>{cli?.cnpj}</td>
                  <td>{cli?.razao_social}</td>
                  <td>{cli?.nome_responsavel}</td>
                  <td>{cli?.telefone_comercial}</td>
                  <td className="justify-center">
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => verInfos(cli?.id)}
                          size="xs"
                        >
                          Ver
                        </DropdownItem>
                        <DropdownItem onClick={() => swalEmail(cli)} size="xs">
                          Alterar email
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pagination || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Informações PJ</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>CNPJ:</strong> {selectedUser?.cnpj}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>E-mail:</strong> {selectedUser?.email_comercial}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>Nome fantasia:</strong> {selectedUser?.nome_fantasia}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>Razão social:</strong> {selectedUser?.razao_social}
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>Tamanho:</strong> {selectedUser?.tamanho_empresa}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Tipo:</strong> {selectedUser?.tipo_empresa}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>Representante legal:</strong>{' '}
              {selectedUser?.nome_responsavel}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Telefone:</strong> {selectedUser?.telefone_pessoal}
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>CPF:</strong>{' '}
              {formataCPF(selectedUser?.Representante_legal?.cpf)}
            </Col>
          </Row> */}
          <hr />
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>CEP:</strong> {selectedUser?.Pessoa?.Pessoa_endereco?.cep}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Cidade:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.cidade}
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>Bairro:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.bairro}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Estado:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.estado}
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>Número:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.numero}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Complemento:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.complemento}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <strong>Logradouro:</strong>{' '}
              {selectedUser?.Pessoa?.Pessoa_endereco?.logradouro}
            </Col>
          </Row>
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PessoaJuridica;
