import debounce from 'debounce-promise';
import api from '../services/api';

async function filtrarConsulta({ value = '', route, param = 'nome' }) {
  try {
    const { data } = await api.get(`${route}?options=true&${param}=${value}`);

    return data;
  } catch (err) {
    return [];
  }
}

const getOptions = debounce(filtrarConsulta, 10);

export default getOptions;
