/* eslint-disable no-nested-ternary */
import axios from 'axios';

// const apiFinanceiro = axios.create({
//   baseURL: window.location.origin.includes('https://web.uneobank.com.br')
//     ? 'https://api-financeiro.uneobank.com.br/'
//     : 'https://api-uneo-financeiro.xcoding.com.br/',
// });

// const apiFinanceiro = axios.create({
//   baseURL: window.location.origin.includes('http://localhost')
//     ? 'http://localhost:3300'
//     : 'https://api-financeiro.uneobank.com.br/',
// });

const apiFinanceiro = axios.create({
  baseURL: 'https://api-financeiro.uneobank.com.br/',
});

export default apiFinanceiro;
