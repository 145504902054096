import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 'var(--border-radius)',
    boxShadow: isFocused ? '0 3px 10px 0 rgba(34, 41, 47, 0.1)' : null,
    borderColor: isFocused ? 'var(--color-secondary)' : '#ced4da',
    marginTop: -4,
  }),
};
export default function Select({ name, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <div className={error && 'error-select'}>
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          styles={customStyles}
          {...rest}
        />
      </div>
    </>
  );
}
