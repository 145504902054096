/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FaChevronRight, FaChevronLeft, FaEllipsisH } from 'react-icons/fa';
import { ImageGroup, Image } from 'react-fullscreen-image';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Input, Select } from '../../components/unform/index';

import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

const showLoader = () =>
  Swal.fire({
    title: 'Aguarde',
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

const closeLoader = () => {
  Swal.close();
};

function AnalisePf() {
  const formRef = useRef();
  const [modal, setModal] = useState(null);
  const [dados, setDados] = useState({
    total: 0,
    pages: 1,
    page: 1,
    items: [],
  });

  async function handleSubmit(page = 0) {
    showLoader();
    const query = Object.entries(formRef.current.getData()).reduce(
      (a, [key, value]) => {
        if (value) {
          return `${a}&${key}=${value}`;
        }

        return a;
      },
      `page=${Number(page) + 1}`
    );

    try {
      const { data } = await api.get(`/aprovar-contas/pf?${query}`);
      setDados(data);
    } catch (err) {
      toast.error('Erro ao buscar dados');
    } finally {
      closeLoader();
    }
  }

  const toggleModal = () => {
    setModal(null);
  };

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  async function abrirConta() {
    try {
      await api.post('/aprovar-contas/aprovar', {
        documento: modal?.cnpj || modal?.cpf,
      });
      toast.success('Abertura de conta realizada com sucesso');
      toggleModal();
      handleSubmit(0);
    } catch (err) {
      toast.error('Ocorreu um erro tente novamente mais tarde!');
      Swal.close();
    }
  }

  const processChange = debounce(() => abrirConta());

  async function handleNegar() {
    const result = await swalWithBootstrapButtons.fire({
      title: 'Negar abertura de conta',
      text: 'Ao clicar em confirmar este cliente não poderá realizar outras tentivas de abertura de conta.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    });

    if (result.value) {
      showLoader();
      try {
        await api.post('/aprovar-contas/negar', {
          documento: modal?.cnpj || modal?.cpf,
        });
        toast.success('Abertura de conta negada');
        toggleModal();
        handleSubmit(0);
      } catch (err) {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
        Swal.close();
      }
    }
  }

  async function handleAprovar() {
    const result = await swalWithBootstrapButtons.fire({
      title: 'Aprovar abertura de conta',
      text: 'Ao clicar em confirmar a conta deste cliente será aberta.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    });

    if (result.value) {
      showLoader();
      processChange();
    }
  }

  async function getFotos(obj) {
    try {
      const { data } = await api.post('/aprovar-contas/fotos', {
        documento: obj.cpf,
      });
      const { data: erros } = await api.post('/aprovar-contas/erros', {
        documento: obj.cnpj,
        id_analise: obj?.id,
      });
      setModal({
        ...obj,
        fotos: data,
        erros,
      });
    } catch (err) {
      toast.error('Não foi possivel carregar as fotos/erros');
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getData() {
      showLoader();
      try {
        const { data } = await api.get('/aprovar-contas/pf');
        setDados(data);
      } catch (err) {
        toast.error('Erro ao buscar dados');
      } finally {
        closeLoader();
      }
    }
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Análise pessoa física</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div className="mr-3" />
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Form ref={formRef} onSubmit={() => handleSubmit(0)}>
              <Row>
                <Col lg={4}>
                  <Input
                    onChange={() => formRef.current.submitForm()}
                    name="cpf"
                    className="form-control"
                    placeholder="CPF"
                  />
                </Col>
                <Col lg={4}>
                  <Input
                    onChange={() => formRef.current.submitForm()}
                    name="nome"
                    className="form-control"
                    placeholder="Nome"
                  />
                </Col>
                <Col lg={4}>
                  <Select
                    name="etapa"
                    className="form-control"
                    onChange={() => formRef.current.submitForm()}
                    data={[
                      {
                        label: 'Status',
                        value: '',
                      },
                      {
                        label: 'Aberto',
                        value: '9',
                      },
                      {
                        label: 'Aprovado',
                        value: '8',
                      },
                      {
                        label: 'Negado',
                        value: '7',
                      },
                      {
                        label: 'Erro',
                        value: '6',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>E-mail</th>
              <th className="text-center">Fotos</th>
            </tr>
          </thead>
          <tbody>
            {dados?.items?.map((m) => (
              <tr key={m?.id}>
                <td>{m?.nome}</td>
                <td>{m?.cpf}</td>
                <td>{m?.email}</td>
                <td className="justify-center">
                  <Button color="link" onClick={() => getFotos(m)}>
                    <FaEllipsisH />
                  </Button>
                </td>
              </tr>
            ))}
            {!dados?.total && (
              <tr>
                <td colSpan={5} className="text-center pt-3">
                  Sem registros encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <CardFooter>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={dados?.pages || 1}
            onPageChange={({ selected }) => handleSubmit(selected)}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </CardFooter>
      </Card>
      <Modal isOpen={!!modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>
          Aprovação abertura de conta
        </ModalHeader>
        <ModalBody>
          <h4>Dados</h4>
          <hr className="mt-0" />
          <div className="d-flex-wrap justify-content-between mb-3">
            <h5>
              Nome
              <br />
              <span className="text-muted">{modal?.nome}</span>
            </h5>
            <h5>
              CPF
              <br />
              <span className="text-muted">{modal?.cpf}</span>
            </h5>
            <h5>
              Email
              <br />
              <span className="text-muted">{modal?.email}</span>
            </h5>
            <h5>
              Telefone
              <br />
              <span className="text-muted">{modal?.telefone}</span>
            </h5>
            {modal?.erros?.length > 0 && (
              <h5>
                Erros
                <br />
                <span className="text-muted">{modal?.erros?.join(', ')}</span>
              </h5>
            )}
          </div>
          <hr />
          <div className="d-flex-wrap justify-content-between mb-3">
            <h5>
              Nome da mãe
              <br />
              <span className="text-muted">{modal?.nome_mae}</span>
            </h5>
            <h5>
              Data nascimento
              <br />
              <span className="text-muted">
                {modal?.data_nascimento?.split('T')[0]}
              </span>
            </h5>
          </div>
          <hr />
          <div className="d-flex-wrap justify-content-between mb-3">
            <h5>
              Endereço
              <br />
              <span className="text-muted">
                {modal?.cep} - {modal?.logradouro}, {modal?.numero} -{' '}
                {modal?.bairro} / {modal?.cidade} - {modal?.estado}
              </span>
            </h5>
          </div>
          {modal?.fotos?.length > 0 && (
            <>
              <h4>Fotos</h4>
              <hr className="mt-0" />
              <ImageGroup>
                <ul className="images">
                  {(modal?.fotos || []).map((i) => (
                    <li key={i}>
                      <Image src={i} alt="kyc" />
                    </li>
                  ))}
                </ul>
              </ImageGroup>
            </>
          )}
        </ModalBody>
        {[6, 8, 7].includes(Number(modal?.etapa)) && (
          <ModalFooter>
            {Number(modal?.etapa) === 8 && (
              <Button color="danger" onClick={handleNegar}>
                Negar
              </Button>
            )}
            <Button color="success" onClick={handleAprovar}>
              Aprovar
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
}

export default AnalisePf;
