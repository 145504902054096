/* eslint-disable no-nested-ternary */
import axios from 'axios';

// const api = axios.create({
//   baseURL: window.location.origin.includes('https://web.uneobank.com.br')
//     ? 'http://localhost:3330'
//     : 'https://api.uneobank.com.br',
// });

// const api = axios.create({
//   baseURL: 'https://api-uneo.xcoding.com.br',
// });

const api = axios.create({
  baseURL: 'https://api.uneobank.com.br',
});

// const api = axios.create({
//   baseURL: 'http://localhost:3300',
// });

// const api = axios.create({
//   baseURL: window.location.origin.includes('https://web.uneobank.com.br')
//     ? 'https://api.uneobank.com.br'
//     : window.location.origin.includes('http://localhost:3000')
//     ? 'http://localhost:3300'
//     : 'https://api-uneo.xcoding.com.br',
// });

export default api;
