import { useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../assets/svg/logo-text.svg';
import { Input, CheckBox, InputGroup } from '../../components/unform/index';
import { Spinner } from '../../components/index';
import { useAuth } from '../../contexts/auth';
import bgLogin from '../../assets/images/bg-login.jpg';

export default function Login() {
  const { signIn, loading } = useAuth();
  const formRef = useRef();
  const [showPassword, setShowPassword] = useState(false);

  async function handleSubmit(data) {
    try {
      await signIn(data);
    } catch (err) {
      toast.error(
        err?.error ||
          'Não foi possivel realizar login, tente novamente mais tarde'
      );
    }
  }

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Row className="vh-100 bg-gray0 mr-0">
      <Col className="d-none d-lg-flex align-items-center px-0" lg={8} sm={12}>
        <img
          className="img-fluid vh-100 w-100"
          style={{
            objectFit: 'cover',
          }}
          src={bgLogin}
          alt="Login V2"
        />
      </Col>
      <Col
        className="d-flex align-items-center auth-bg px-2 p-lg-5 bg-white"
        lg={4}
        sm={12}
      >
        <Col className="px-xl-4 mx-auto" sm={8} md={6} lg={12}>
          <h2 className="uneo-solucoes mb-4">UNEO SOLUÇÕES</h2>
          <h4 className="font-weight-bold mb-1 card-title">
            Bem vindo ao grupo UNEO! 👋
          </h4>
          <p className="mb-4 card-text">
            Por favor faça o login para prosseguir
          </p>

          <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
            <Input
              name="email"
              label="Email"
              className="form-control"
              placeholder="Seu email"
            />
            <InputGroup
              name="senha"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              className="form-control-group"
              placeholder="* * * * *"
              append={
                <Button size="sm" color="link" onClick={toggleShowPassword}>
                  {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
                </Button>
              }
            />

            <Button color="primary" className="btn-block" disabled={loading}>
              {loading ? <Spinner /> : 'Login'}
            </Button>
          </Form>
        </Col>
      </Col>
    </Row>
  );
}
