import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ReactJson from 'react-json-view';
import api from '../../services/api';
import ReactSelectAsync from '../../components/reactSelectAsync';

function CampanhaList() {
  const [dados, setDados] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modalCard, setModalCard] = useState(false);

  let timeoutConsulta;

  const toggleModalCard = () => setModalCard(!modalCard);

  function filtrarConsulta(key, value) {
    if (value) {
      setFiltro({ ...filtro, [key]: value, page: 1 });
    } else {
      setFiltro({ ...filtro, [key]: '', page: 1 });
    }
  }

  async function filtrarConsultaOpts({ valor, tipo = 'campanha' }) {
    const result = await api.get(`/campanha/opts?tipo=${tipo}&valor=${valor}`);
    return result.data;
  }

  const promiseOptions = async (val) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsultaOpts(val));
      }, 300);
    });

  useEffect(() => {
    async function getLog() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/campanha?${params}`);
      setDados(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getLog();
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Campanha</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3  d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted align-items-center">
              Total de registros: {dados?.total || 0}
            </h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>

        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <ReactSelectAsync
                  isClearable
                  loadOptions={(valor) =>
                    promiseOptions({ valor, tipo: 'nome' })
                  }
                  placeholder="Nome cliente"
                  onChange={(opt) => filtrarConsulta('id_pessoa', opt?.value)}
                />
              </Col>
              <Col>
                <ReactSelectAsync
                  isClearable
                  loadOptions={(valor) =>
                    promiseOptions({ valor, tipo: 'campanha' })
                  }
                  placeholder="Título"
                  onChange={(opt) =>
                    filtrarConsulta('id_notificacao', opt?.value)
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Título</th>
              <th>nome</th>
              <th>Dados</th>
            </tr>
          </thead>
          <tbody>
            {dados.data?.length ? (
              dados.data?.map((pessoa) => (
                <tr key={pessoa?.id}>
                  <td>
                    {new Date(pessoa?.createdAt)
                      ?.toLocaleString()
                      .substring(0, 16)}
                  </td>
                  <td>{pessoa?.Notificaco?.titulo}</td>
                  <td>{pessoa?.Pessoa?.nome_completo}</td>
                  <td>
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => setModalCard(pessoa.dados)}
                          size="xs"
                        >
                          Visualizar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} className="text-center">
                  Sem registros encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(dados.pages) || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={!!modalCard} toggle={toggleModalCard}>
        <ModalHeader toggle={toggleModalCard}>Dados</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <ReactJson
                displayDataTypes={false}
                iconStyle="circle"
                displayObjectSize={false}
                indentWidth={3}
                collapsed={false}
                src={modalCard || {}}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalCard}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CampanhaList;
