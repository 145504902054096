import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

import AuthRoute from './authRoute';

import Login from '../pages/Login/index';
import Dashboard from '../pages/Dashboard/index';
import Listar from '../pages/Dashboard/listar';
import Layout from '../layouts';
import Contas from '../pages/Contas/index';
import Perfil from '../pages/Perfil';

import ExtratoClientesBMP from '../pages/ExtratoClientesBMP';

import PessoaFisica from '../pages/PessoaFisica';
import PessoaJuridica from '../pages/PessoaJuridica';
import Usuarios from '../pages/Usuarios';
import Tarifador from '../pages/Tarifador';
import Cartoes from '../pages/Cartoes';
import Monitoria from '../pages/Monitoria';
import Planos from '../pages/Planos';

import ModuloSelect from '../pages/Modulo';

// financeiro
import CadastroLancamento from '../pages/Lancamento/Cadastro';
import ConsultaLancamento from '../pages/Lancamento/Consulta';
import RelatorioLancamentos from '../pages/Lancamento/Relatorio';
import DashboardFinanceiro from '../pages/DashboardFinanceiro/index';
import Planejamento from '../pages/Planejamento';
import Demonstrativo from '../pages/Demonstrativo';
import GestaoContratoNovo from '../pages/GestaoContrato/Cadastro';
import GestaoContrato from '../pages/GestaoContrato/Consulta';
import GestaoContratoEditar from '../pages/GestaoContrato/Editar';
import GestaoContratoRenovar from '../pages/GestaoContrato/Renovar';
import ClienteFornecedorEditar from '../pages/ClienteFornecedor/Editar';
import ClienteFornecedorNovo from '../pages/ClienteFornecedor/Cadastro';
import ClienteFornecedor from '../pages/ClienteFornecedor/Consulta';

// Uneo Credito
import GestaoCredito from '../pages/GestaoCredito/Consulta';
import GestaoTarifas from '../pages/GestaoTarifas';
import GestaoFaturas from '../pages/GestaoFaturas';
import ExtratoCliente from '../pages/ExtratoCliente';

// import GiftCards from '../pages/Vendas/giftCard';
import RecargaCelular from '../pages/Vendas/recargaCelular';

// Uneo correspondente
import Estabelecimentos from '../pages/Correspondente/Estabelecimento';
import ControleCaixa from '../pages/Correspondente/ControleCaixa';
import GestaoConsolidacao from '../pages/Correspondente/GestaoConsolidacao';
import Vendas from '../pages/Correspondente/Vendas';

// import PendenciaTarifa from '../pages/PendenciaTarifa';

import Suporte from '../pages/Suporte';
import LogAcessos from '../pages/Acessos';

// import Indicacoes from '../pages/Indicacoes';
import MalaDireta from '../pages/Cliente/MalaDireta';
import Notificacao from '../pages/Cliente/Notificacao';
import Campanha from '../pages/Cliente/Campanha';

import Extrato from '../pages/Gerenciamento/Extrato';
import Transferencia from '../pages/Gerenciamento/Transferencia';

import BoletosClientes from '../pages/Cliente/Boletos';
import PixClientes from '../pages/Cliente/Pix';
import PagamentosClientes from '../pages/Cliente/Pagamentos';
import TransferenciasClientes from '../pages/Cliente/Transferencias';

import AutoAtendimentoTaxas from '../pages/AutoAtendimento/taxas';

import LogsCelcoin from '../pages/Monitoria/logsCelcoin';

import AberturaContasPF from '../pages/AberturaContas/pf';
import AberturaContasPJ from '../pages/AberturaContas/pj';

import Home from '../pages/Home';
import SolicitacoesCredito from '../pages/SolicitacaoCredito/Consulta';
import AnalisePf from '../pages/AberturaContas/analisePf';
import AnalisePj from '../pages/AberturaContas/analisePj';

import Funcionarios from '../pages/Funcionarios/index';
import NovoFuncionario from '../pages/Funcionarios/cadastro';

import Parceiros from '../pages/Parceiros/index';
import NovoParceiro from '../pages/Parceiros/cadastro';
import SolicitacoesEmprestimo from '../pages/Emprestimo/Solicitacoes';
import Emprestimos from '../pages/Emprestimo/Listar';
import SimularTaxas from '../pages/Emprestimo/Taxas';

import Sorteio from '../pages/Sorteio/index';
import ValorCupom from '../pages/Sorteio/valorCupom';
import ValorPremio from '../pages/Sorteio/valorPremio';
import ListarCupons from '../pages/Sorteio/listarCupons';
import ListarHistoricoSorteio from '../pages/Sorteio/listarHistorico';
import Blog from '../pages/Blog/listar';
import NovoPost from '../pages/Blog/criar';

const Routes = () => {
  const { signed, modulo } = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        {signed ? (
          <>
            {modulo ? (
              <>
                <Layout>
                  <Switch>
                    <AuthRoute path="/" exact component={Home} />
                    {modulo === 'UB' && (
                      <>
                        <AuthRoute
                          path="/autoatendimento-taxas"
                          exact
                          component={AutoAtendimentoTaxas}
                        />
                        <AuthRoute
                          path="/pagamentos-clientes"
                          exact
                          component={PagamentosClientes}
                        />
                        <AuthRoute
                          path="/pix-clientes"
                          exact
                          component={PixClientes}
                        />
                        <AuthRoute
                          path="/transferencias-clientes"
                          exact
                          component={TransferenciasClientes}
                        />
                        <AuthRoute
                          path="/boletos-clientes"
                          exact
                          component={BoletosClientes}
                        />

                        <AuthRoute
                          path="/recarga-gift"
                          exact
                          component={RecargaCelular}
                        />

                        <AuthRoute
                          path="/extrato-clientes"
                          exact
                          component={ExtratoClientesBMP}
                        />
                        <AuthRoute path="/perfil" exact component={Perfil} />
                        <AuthRoute
                          path="/dashboard"
                          exact
                          component={Dashboard}
                        />
                        <AuthRoute path="/pf" exact component={PessoaFisica} />
                        <AuthRoute
                          path="/pj"
                          exact
                          component={PessoaJuridica}
                        />
                        <AuthRoute
                          path="/tarifador"
                          exact
                          component={Tarifador}
                        />
                        <AuthRoute path="/cartoes" exact component={Cartoes} />
                        <AuthRoute
                          path="/usuarios"
                          exact
                          component={Usuarios}
                        />
                        <AuthRoute path="/listar" exact component={Listar} />
                        <AuthRoute path="/contas" exact component={Contas} />

                        <AuthRoute
                          path="/abertura-pf"
                          exact
                          component={AberturaContasPF}
                        />
                        <AuthRoute
                          path="/analise-pf"
                          exact
                          component={AnalisePf}
                        />
                        <AuthRoute
                          path="/analise-pj"
                          exact
                          component={AnalisePj}
                        />
                        <AuthRoute
                          path="/abertura-pj"
                          exact
                          component={AberturaContasPJ}
                        />
                        <AuthRoute
                          path="/monitoria"
                          exact
                          component={Monitoria}
                        />
                        <AuthRoute path="/planos" exact component={Planos} />

                        <AuthRoute path="/suporte" exact component={Suporte} />
                        <AuthRoute
                          path="/log-acesso"
                          exact
                          component={LogAcessos}
                        />
                        <AuthRoute
                          path="/log-celcoin"
                          exact
                          component={LogsCelcoin}
                        />

                        <AuthRoute
                          path="/mala-direta"
                          exact
                          component={MalaDireta}
                        />
                        <AuthRoute
                          path="/notificacao"
                          exact
                          component={Notificacao}
                        />
                        <AuthRoute
                          path="/campanha"
                          exact
                          component={Campanha}
                        />
                        <AuthRoute path="/blog" exact component={Blog} />
                        <AuthRoute
                          path="/blog/novo"
                          exact
                          component={NovoPost}
                        />

                        <AuthRoute path="/extrato" exact component={Extrato} />
                        <AuthRoute
                          path="/transferencia"
                          exact
                          component={Transferencia}
                        />

                        <AuthRoute
                          path="/funcionarios"
                          exact
                          component={Funcionarios}
                        />
                        <AuthRoute
                          path="/funcionario-novo"
                          exact
                          component={NovoFuncionario}
                        />
                        <AuthRoute
                          path="/parceiros"
                          exact
                          component={Parceiros}
                        />
                        <AuthRoute
                          path="/parceiro-novo"
                          exact
                          component={NovoParceiro}
                        />
                        <AuthRoute
                          path="/solicitacoes-emprestimo"
                          exact
                          component={SolicitacoesEmprestimo}
                        />
                        <AuthRoute
                          path="/simulador-taxas-emprestimo"
                          exact
                          component={SimularTaxas}
                        />
                        <AuthRoute
                          path="/emprestimos"
                          exact
                          component={Emprestimos}
                        />
                      </>
                    )}

                    {modulo === 'UF' && (
                      <>
                        <AuthRoute
                          path="/lancamento/cadastro"
                          exact
                          component={CadastroLancamento}
                        />
                        <AuthRoute
                          path="/lancamento"
                          exact
                          component={ConsultaLancamento}
                        />
                        <AuthRoute
                          path="/relatorio-lancamento"
                          exact
                          component={RelatorioLancamentos}
                        />
                        <AuthRoute
                          path="/dashboard-financeiro"
                          exact
                          component={DashboardFinanceiro}
                        />
                        <AuthRoute
                          path="/planejamento"
                          exact
                          component={Planejamento}
                        />
                        <AuthRoute
                          path="/demonstrativo"
                          exact
                          component={Demonstrativo}
                        />
                        <AuthRoute
                          path="/renovar-contrato/:id"
                          exact
                          component={GestaoContratoRenovar}
                        />
                        <AuthRoute
                          path="/editar-contrato/:id"
                          exact
                          component={GestaoContratoEditar}
                        />
                        <AuthRoute
                          path="/novo-contrato"
                          exact
                          component={GestaoContratoNovo}
                        />
                        <AuthRoute
                          path="/contrato"
                          exact
                          component={GestaoContrato}
                        />

                        <AuthRoute
                          path="/cliente-fornecedor/:id"
                          exact
                          component={ClienteFornecedorEditar}
                        />
                        <AuthRoute
                          path="/novo-cliente-fornecedor"
                          exact
                          component={ClienteFornecedorNovo}
                        />
                        <AuthRoute
                          path="/cliente-fornecedor"
                          exact
                          component={ClienteFornecedor}
                        />
                      </>
                    )}

                    {modulo === 'UC' && (
                      <>
                        <AuthRoute
                          path="/gestao-credito"
                          exact
                          component={GestaoCredito}
                        />
                        <AuthRoute
                          path="/solicitacoes-credito"
                          exact
                          component={SolicitacoesCredito}
                        />
                        <AuthRoute
                          path="/gestao-tarifas"
                          exact
                          component={GestaoTarifas}
                        />
                        <AuthRoute
                          path="/gestao-faturas"
                          exact
                          component={GestaoFaturas}
                        />
                        <AuthRoute
                          path="/extrato-cliente"
                          exact
                          component={ExtratoCliente}
                        />
                      </>
                    )}

                    {modulo === 'CO' && (
                      <>
                        <AuthRoute
                          path="/estabelecimento"
                          exact
                          component={Estabelecimentos}
                        />
                        <AuthRoute
                          path="/controle-caixa"
                          exact
                          component={ControleCaixa}
                        />
                        <AuthRoute
                          path="/gestao-consolida"
                          exact
                          component={GestaoConsolidacao}
                        />
                        <AuthRoute path="/vendas" exact component={Vendas} />
                      </>
                    )}

                    {modulo === 'PC' && (
                      <>
                        <AuthRoute path="/sorteio" exact component={Sorteio} />
                        <AuthRoute
                          path="/cupons"
                          exact
                          component={ListarCupons}
                        />
                        <AuthRoute
                          path="/valor-cupom"
                          exact
                          component={ValorCupom}
                        />
                        <AuthRoute
                          path="/valor-premio"
                          exact
                          component={ValorPremio}
                        />

                        <AuthRoute
                          path="/historico-sorteios"
                          exact
                          component={ListarHistoricoSorteio}
                        />
                      </>
                    )}
                    <Redirect to="/" />
                  </Switch>
                </Layout>
              </>
            ) : (
              <AuthRoute path="/" exact component={ModuloSelect} />
            )}
          </>
        ) : (
          <>
            <Route path="/" exact component={Login} />
            <Redirect to="/" />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
