/* eslint-disable no-return-await */
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Form } from '@unform/web';
import { Button, Card, Col, Row, CardBody, CardFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import {
  NumberFormat,
  Select,
  Input,
  ReactSelectAsyncCreatable,
} from '../../components/unform/index';
import api from '../../services/apiFinanceiro';
import TipoDespesaReceita from '../TipoDespesaReceita/Cadastro';
import Tag from '../Tag/Cadastro';
import Cliente from '../Cliente/Cadastro';

import addContrato from '../../validations/addContrato';

function CadastroContrato() {
  const formRef = useRef();
  const history = useHistory();
  const [newValue, setNewValue] = React.useState('');
  const [selectedContract, setselectedContract] = useState(null);

  const [tag, setTag] = useState(false);
  const [despRes, setDespRes] = useState(false);
  const [cliente, setcliente] = useState(false);

  const [tipoContrato, setTipoContrato] = useState(null);

  const toggleTag = () => setTag(!tag);
  const toggleDespRes = () => setDespRes(!despRes);
  const toggleCliente = () => setcliente(!cliente);

  async function handleSubmit(data) {
    try {
      if (data?.tags?.length) {
        data.tags = data?.tags?.map((m) => m.value);
      } else {
        data.tags = [];
      }

      await addContrato(data);
      data.tipo = tipoContrato;

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/contrato', data);
      toast.success('Dados salvos com sucesso!');
      Swal.close();
      history.push('/contrato');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
        Swal.close();
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  // eslint-disable-next-line consistent-return
  async function filtrarConsulta(inputValue, tipo) {
    if (tipo === 'cliente') {
      const { data } = await api.get(
        `/cliente-fornecedor?options=true&nome=${inputValue}`
      );
      return data.map((e) => ({ value: e.value, label: e.label }));
    }
    if (tipo === 'id_tipo_contrato') {
      const { data } = await api.get(
        `/tipo-contrato?options=true&nome=${inputValue}`
      );
      return data.map((e) => ({
        value: e.value,
        label: `${e.tipo} - ${e.label}`,
        tipo: e.tipo,
      }));
    }
    if (tipo === 'tags') {
      const { data } = await api.get(`/tags?options=true&nome=${inputValue}`);
      return data.map((e) => ({ value: e.value, label: e.label }));
    }
  }

  let selectOptions;
  const optionsClientes = async (inputValue) =>
    await new Promise((resolve) => {
      clearTimeout(selectOptions);

      selectOptions = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, 'cliente'));
      }, 500);
    });

  const optionsTipoContrato = async (inputValue) =>
    await new Promise((resolve) => {
      clearTimeout(selectOptions);

      selectOptions = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, 'id_tipo_contrato'));
      }, 500);
    });

  const optionsTags = async (inputValue) =>
    await new Promise((resolve) => {
      clearTimeout(selectOptions);

      selectOptions = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, 'tags'));
      }, 500);
    });

  function handleChangeColorSelect(event) {
    setNewValue(event);
    toggleTag();
  }

  function handleChangeTipoContrato(event) {
    setNewValue(event);
    toggleDespRes();
  }

  function handleChangeCliente(event) {
    setNewValue(event);
    toggleCliente();
  }

  async function handleAddColor(valor) {
    Swal.showLoading();
    const { data } = await api.post('/tags', valor);
    if (selectedContract) {
      const newItem = selectedContract;
      newItem.tags.push({ cor: data.cor, label: data.nome, value: data.id });
      setselectedContract(newItem);
    } else {
      const tags = formRef.current.getFieldValue('tags');
      tags.push({
        cor: data?.cor,
        label: data?.nome,
        value: data?.id,
      });

      formRef.current.setFieldValue('tags', tags);
    }
    Swal.close();
    toggleTag();
  }

  async function handleAddTipo(valor) {
    Swal.showLoading();

    const { data } = await api.post('/tipo-contrato', valor);

    const tipo = {
      value: data?.id,
      label: `${data?.tipo} - ${data?.nome}`,
      tipo: data?.tipo,
    };
    setTipoContrato(data?.tipo);
    formRef.current.setFieldValue('id_tipo_contrato', tipo);

    Swal.close();
    toggleDespRes();
  }

  async function handleAddCliente(valor) {
    Swal.showLoading();
    const { data } = await api.post('/cliente-fornecedor', valor);

    const tipo = {
      value: data?.id,
      label: data?.nome,
    };
    formRef.current.setFieldValue('id_cliente_fornecedor', tipo);
    formRef.current.setFieldValue('documento', data?.documento);
    Swal.close();
    toggleCliente();
  }

  function calculoValorParcelamento() {
    const entrada = formRef.current.getFieldValue('valor_entrada');
    let total = formRef.current.getFieldValue('valor_total');

    if (total && entrada) {
      total -= entrada;
    }

    if (total) {
      formRef.current.setFieldValue('valor_parcelamento', total);
    } else {
      formRef.current.setFieldValue('valor_parcelamento', 0);
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Criar contrato</h4>
        </Col>
      </Row>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <CardBody>
            <Row>
              <Col lg={8} md={6} sm={12}>
                <Input
                  name="descricao"
                  label="Descrição"
                  className="form-control"
                  type="text"
                  defaultValue={selectedContract?.descricao}
                />
              </Col>
              <Col lg={4} md={4} sm={12}>
                <ReactSelectAsyncCreatable
                  label="Tipo contrato"
                  name="id_tipo_contrato"
                  isClearable
                  loadOptions={optionsTipoContrato}
                  onCreateOption={handleChangeTipoContrato}
                  onChange={(e) => {
                    setTipoContrato(e?.tipo);
                  }}
                  defaultValue={
                    selectedContract?.id_tipo_contrato?.id && {
                      value: selectedContract?.id_tipo_contrato?.id,
                      label: `${selectedContract?.id_tipo_contrato?.tipo} - ${selectedContract?.id_tipo_contrato?.nome}`,
                    }
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <ReactSelectAsyncCreatable
                  label="Cliente / Fornecedor"
                  name="id_cliente_fornecedor"
                  isClearable
                  loadOptions={optionsClientes}
                  onCreateOption={handleChangeCliente}
                  defaultValue={
                    selectedContract?.id_cliente_fornecedor?.id && {
                      value: selectedContract?.id_cliente_fornecedor?.id,
                      label: selectedContract?.id_cliente_fornecedor?.nome,
                    }
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  name="documento"
                  label="Documento"
                  className="form-control"
                  type="number"
                  defaultValue={selectedContract?.documento}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  name="data_assinatura"
                  label="Data assinatura"
                  className="form-control"
                  type="date"
                  defaultValue={
                    selectedContract?.data_assinatura &&
                    moment(
                      selectedContract?.data_assinatura?.split('T')[0]
                    ).format('YYYY-MM-DD')
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  name="data_inicio_vigencia"
                  label="Data inicio vigência"
                  className="form-control"
                  type="date"
                  defaultValue={
                    selectedContract?.data_inicio_vigencia &&
                    moment(
                      selectedContract?.data_inicio_vigencia?.split('T')[0]
                    ).format('YYYY-MM-DD')
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  name="data_fim_vigencia"
                  label="Data fim vigência"
                  className="form-control"
                  type="date"
                  defaultValue={
                    selectedContract?.data_fim_vigencia &&
                    moment(
                      selectedContract?.data_fim_vigencia?.split('T')[0]
                    ).format('YYYY-MM-DD')
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  name="data_base_pagamento"
                  label="Data base pagamento"
                  className="form-control"
                  type="date"
                  defaultValue={
                    selectedContract?.data_base_pagamento &&
                    moment(
                      selectedContract?.data_base_pagamento?.split('T')[0]
                    ).format('YYYY-MM-DD')
                  }
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <NumberFormat
                  name="valor_total"
                  label="Valor contrato"
                  className="form-control"
                  number
                  value={selectedContract?.valor_total}
                  onChange={() => {
                    calculoValorParcelamento();
                  }}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <NumberFormat
                  name="valor_entrada"
                  label="Valor entrada"
                  className="form-control"
                  number
                  value={selectedContract?.valor_entrada}
                  onChange={() => {
                    calculoValorParcelamento();
                  }}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <NumberFormat
                  name="valor_parcelamento"
                  label="Valor parcelamento"
                  className="form-control"
                  disabled
                />
              </Col>
              <Col lg={4} md={3} sm={6}>
                <Input
                  name="quantidade_parcelas"
                  label="Quantidade de parcelas"
                  className="form-control"
                  type="number"
                  min="1"
                  defaultValue={selectedContract?.quantidade_parcelas}
                />
              </Col>
              <Col lg={4} md={3} sm={6}>
                <ReactSelectAsyncCreatable
                  label="Tag"
                  name="tags"
                  fullObj
                  isClearable
                  onCreateOption={handleChangeColorSelect}
                  isMulti
                  loadOptions={optionsTags}
                  defaultValue={
                    selectedContract?.tags?.length && selectedContract?.tags
                  }
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </CardFooter>
        </Form>
      </Card>
      <TipoDespesaReceita
        toggle={toggleDespRes}
        isOpen={despRes}
        value={newValue}
        onAdd={handleAddTipo}
        title="Novo Tipo Contrato"
      />
      <Tag
        toggle={toggleTag}
        isOpen={tag}
        value={newValue}
        onAdd={handleAddColor}
      />
      <Cliente
        toggle={toggleCliente}
        isOpen={cliente}
        value={newValue}
        onAdd={handleAddCliente}
      />
    </>
  );
}

export default CadastroContrato;
