import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { signed, loading } = useAuth();

  if (loading) {
    return <div>Carregando</div>;
  }
  return (
    <Route
      {...props}
      render={() => (signed ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
