import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    nome: Yup.string().required('Obrigatório'),
    email: Yup.string().required('Obrigatório'),
    cpf: Yup.string().required('Obrigatório'),
    rg: Yup.string().required('Obrigatório'),
    data_nascimento: Yup.string().required('Obrigatório'),
    estado_civil: Yup.string().required('Obrigatório'),
    telefone: Yup.string().required('Obrigatório'),
    telefone_emergencia: Yup.string().required('Obrigatório'),
    data_contratacao: Yup.string().required('Obrigatório'),
    cargo: Yup.string().required('Obrigatório'),
    cep: Yup.string().required('Obrigatório'),
    logradouro: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    cidade: Yup.string().required('Obrigatório'),
    estado: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
