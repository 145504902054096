import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    nome: Yup.string().required('Obrigatório'),
    email: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
