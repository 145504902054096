import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaAngleDown,
  FaCubes,
  FaEye,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { ReactSelectAsync } from '../../components/index';
import getOptions from '../../utils/getOptionsUneo';

import api from '../../services/api';

function ControleCaixa() {
  const formRef = useRef(null);
  const [consolidacao, setConsolidacao] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [filtroVendas, setFiltroVendas] = useState({});
  const [modal, setModal] = useState(false);
  const [modalVendas, setModalVendas] = useState(false);
  const [estabelecimento, setEstabelecimento] = useState(null);

  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [vendas, setVendas] = useState([]);

  const [borderos, setBorderos] = useState(null);

  const [idBordero, setIdBordero] = useState(null);

  const toggle = () => setModal(!modal);
  const toggleVendas = () => setModalVendas(!modalVendas);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function pesquisaExtrato() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    try {
      const url = `/consolidacao-estabelecimento?estabelecimento=${estabelecimento}&${params}`;

      const { data } = await api.get(url);

      setConsolidacao(data);
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  async function verBorderos(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { data } = await api.get(`/consolidacao-bordero/${id}`);
      setBorderos(data);
      toggle();
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar borderos');
      Swal.close();
    }
  }

  async function carregaEstabelecimantos() {
    try {
      const { data } = await api.get('/estabelecimentos');

      setEstabelecimentos(data);
    } catch (err) {
      toast.error('Erro ao buscar estabelecimentos!');
    }
  }

  async function consultaVendas(id) {
    try {
      // const params = Object.keys(filtroVendas)
      //   .map((e) => {
      //     if (e === 'page') {
      //       if (Number.isNaN(filtroVendas[e])) {
      //         return `${e}=${1}`;
      //       }
      //     }
      //     return `${e}=${filtroVendas[e]}`;
      //   })
      //   .join('&');
      const { data } = await api.get(`/vendas/${id}`);

      setIdBordero(id);
      setVendas(data);
      toggleVendas();
    } catch (err) {
      toast.error('Erro ao buscar vendas!');
    }
  }

  useEffect(() => {
    if (estabelecimento) {
      pesquisaExtrato();
    }
    carregaEstabelecimantos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro, estabelecimento]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Gestão de consolidação</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form ref={formRef} onSubmit={() => pesquisaExtrato()}>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <label>Estabelecimento</label>
                  <ReactSelectAsync
                    isClearable
                    loadOptions={async (value) => {
                      const data = await getOptions({
                        value,
                        route: '/estabelecimentos',
                      });
                      return data;
                    }}
                    defaultOptions={estabelecimentos}
                    placeholder=" "
                    onChange={(opt) => setEstabelecimento(opt?.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {estabelecimento && (
          <>
            <div className="p-3 justify-content-between d-flex align-items-center">
              <Col lg={6}>
                <h6 className="txt-muted">
                  Total de registros: {consolidacao.total}
                </h6>
              </Col>
              <Col>
                <Button
                  color="muted"
                  id="toggler"
                  className="d-flex align-items-center"
                >
                  <FiFilter size={20} />
                  &nbsp;&nbsp;Filtros
                </Button>
              </Col>
            </div>
            <div className="filter-collapse px-3 ">
              <UncontrolledCollapse toggler="#toggler" className="pb-3">
                <Row>
                  <Col lg={2}>
                    <label className="form-label">Data</label>
                    <input
                      onChange={(e) => debounceConsulta(e.target.value, 'data')}
                      className="form-control"
                      type="date"
                    />
                  </Col>
                </Row>
              </UncontrolledCollapse>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Data consolidação</th>
                  <th>Sistema</th>
                  <th>Operador</th>
                  <th>Dinheiro</th>
                  <th>Pix</th>
                  <th>Ap inicial</th>
                  <th>Apfinal</th>
                  <th>Diferença</th>
                  <th style={{ textAlign: 'center' }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {consolidacao.consolidacao?.length ? (
                  consolidacao.consolidacao?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>
                        {tar?.data_consolidacao &&
                          new Date(tar?.data_consolidacao)?.toLocaleString()}
                      </td>
                      <td>
                        {Number(tar?.valor_sistema)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_operador)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_dinheiro)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_pix)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.aporte_inicial)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.aporte_final)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.diferenca)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td data-label="Ações">
                        <div className="d-flex justify-content-center">
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              color="link"
                              className=" text-decoration-none padding-0-5"
                            >
                              <FaCog className="mr-2" /> <FaAngleDown />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() => {
                                  verBorderos(tar?.id);
                                }}
                              >
                                <FaCubes className="mr-1" />
                                Ver borderos
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={consolidacao.pages || 1}
              onPageChange={({ selected }) =>
                setFiltro({ ...filtro, page: selected + 1 })
              }
              initialPage={0}
              containerClassName="pagination justify-end mr-1"
              activeClassName="active"
            />
          </>
        )}
      </Card>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>Borderos</ModalHeader>
        <ModalBody>
          <Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>Usuário</th>
                  <th>Abertura</th>
                  <th>Encerramento</th>
                  <th>Sistema</th>
                  <th>Operador</th>
                  <th>Dinheiro</th>
                  <th>Saque</th>
                  <th>Ap inicial</th>
                  <th>Ap final</th>
                  <th>Dif.</th>
                  <th>Vendas</th>
                </tr>
              </thead>
              <tbody>
                {borderos?.length ? (
                  borderos?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>{tar?.Usuario?.nome}</td>
                      <td>
                        {tar?.data_abertura &&
                          new Date(tar?.data_abertura)?.toLocaleString()}
                      </td>
                      <td>
                        {tar?.data_encerramento &&
                          new Date(tar?.data_encerramento)?.toLocaleString()}
                      </td>
                      <td>
                        {Number(tar?.valor_sistema)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_operador)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_dinheiro)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {/* {Number(tar?.valor_pix)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })} */}
                        {tar?.saque
                          ? Number(tar?.saque)?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : Number(0)?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                      </td>
                      <td>
                        {Number(tar?.aporte_inicial)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.aporte_final)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.diferenca)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        <Button
                          onClick={() => consultaVendas(tar?.id)}
                          type="button"
                          block
                        >
                          <FaEye />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalVendas} toggle={toggleVendas} size="xl">
        <ModalHeader toggle={toggleVendas}>Vendas</ModalHeader>
        <ModalBody>
          <Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Forma Pagt.</th>
                  <th>Valor Pago</th>
                  <th>Troco</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {vendas?.vendas?.length ? (
                  vendas?.vendas?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>{tar?.produto}</td>
                      <td>
                        {Number(tar?.valor)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{tar?.forma_pagamento}</td>
                      <td>
                        {Number(tar?.valor_pago)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_troco)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{tar?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={vendas?.pages || 1}
              onPageChange={({ selected }) => {
                setFiltroVendas({ page: selected + 1 });
              }}
              initialPage={0}
              containerClassName="pagination justify-end mr-1"
              activeClassName="active"
            /> */}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ControleCaixa;
