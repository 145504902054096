/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select/async-creatable';
import { useField } from '@unform/core';
import chroma from 'chroma-js';
import customStyles from './stylesSelect';

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  ...customStyles,
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.cor || '#0050ff');

    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.cor
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.cor,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.cor : color.alpha(0.3).css()),
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.cor) }),
};

export default function Select({ name, label, fullObj, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (fullObj || !ref.select.state.value) {
            return ref.select.state.value || [];
          }

          if (ref.select.state.value) {
            return ref.select.state.value.map((option) => option.value);
          }
        }

        if (fullObj || !ref.select.state.value) {
          return ref.select.state.value;
        }

        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.state.value = value;
        ref.select.updater.enqueueForceUpdate(ref);
      },
    });
  }, [fieldName, registerField, rest.isMulti, fullObj]);
  console.log(error);
  return (
    <>
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <div className={error && 'error-select'}>
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          styles={colourStyles}
          cacheOptions
          classNamePrefix="react-select"
          placeholder="Pesquisar"
          noOptionsMessage={() => 'Digite para pesquisar'}
          formatCreateLabel={(text) => `Criar "${text}"`}
          {...rest}
        />
      </div>
    </>
  );
}
