/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardHeader,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { NumberFormat, Radio, Input } from '../../components/unform/index';
import api from '../../services/api';

import addTarifaConta from '../../validations/addTarifaOperacao';

const arrayFranquia = [
  {
    id: 1,
    franquia: 'S',
    tipo: 'TED',
  },
  {
    id: 2,
    franquia: 'V',
    tipo: 'TED',
  },
  {
    id: 3,
    franquia: 'T',
    tipo: 'TED',
  },
  {
    id: 4,
    franquia: 'I',
    tipo: 'TED',
  },
  {
    id: 5,
    franquia: 'S',
    tipo: 'PIX',
  },
  {
    id: 6,
    franquia: 'V',
    tipo: 'PIX',
  },
  {
    id: 7,
    franquia: 'T',
    tipo: 'PIX',
  },
  {
    id: 8,
    franquia: 'I',
    tipo: 'PIX',
  },
  {
    id: 9,
    franquia: 'S',
    tipo: 'BOLETO',
  },
  {
    id: 10,
    franquia: 'V',
    tipo: 'BOLETO',
  },
  {
    id: 11,
    franquia: 'T',
    tipo: 'BOLETO',
  },
  {
    id: 12,
    franquia: 'I',
    tipo: 'BOLETO',
  },
];

function TarifaTransacao() {
  const formRef = useRef();
  const [activeTab, setActiveTab] = useState('1');

  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const [tipoFranquiaTED, setTipoFranquiaTED] = useState(null);
  const [tipoFranquiaPIX, setTipoFranquiaPIX] = useState(null);
  const [tipoFranquiaBOLETO, setTipoFranquiaBOLETO] = useState(null);

  const [planoSelected, setPlanoSelected] = useState(null);
  const [planosPF, setPlanosPF] = useState([]);
  const [planosPJ, setPlanosPJ] = useState([]);

  const [change, setChange] = useState(false);

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    async function getTarifas() {
      if (change === false) {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
      }
      try {
        const { data } = await api.get('/planos');
        if (data) {
          setPlanosPF(
            data.transacao.filter((a) => a.tipo_conta === 'Pessoa Física')
          );
          setPlanosPJ(
            data.transacao.filter((a) => a.tipo_conta === 'Pessoa Jurídica')
          );
        }
        window.scrollTo(0, 0);

        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar planos!');
        Swal.close();
      }
    }
    getTarifas();
  }, [change]);

  function clenObject(body) {
    body.bonifica = formRef.current.getFieldValue('bonifica');
    return body;
  }

  async function handleSubmit(data) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const body = clenObject(data);
      await addTarifaConta(body);
      await api.put(
        `/edita-plano/${planoSelected?.plano}/${planoSelected?.tipo}`,
        body
      );
      setChange(!change);
      toast.success('Plano alterado com sucesso');
      toggleModal();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        Swal.close();
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        Swal.fire({
          text: error,
          icon: 'error',
        });
      }
    }
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function getId(franquia, tipo) {
    return String(
      arrayFranquia.filter(
        (item) => item.franquia === franquia && item.tipo === tipo
      )[0]?.id
    );
  }

  function setRefValues(plano) {
    setTimeout(() => {
      formRef.current.setFieldValue(
        'valor_tarifa_TED',
        Number(plano?.filter((i) => i.tipo_cobranca === 'TED')[0]?.valor_tarifa)
      );
      formRef.current.setFieldValue(
        'franquia_valor_TED',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'TED')[0]?.franquia_valor
        )
      );
      formRef.current.setFieldValue(
        'valor_tarifa_PIX',
        Number(plano?.filter((i) => i.tipo_cobranca === 'PIX')[0]?.valor_tarifa)
      );
      formRef.current.setFieldValue(
        'franquia_valor_PIX',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'PIX')[0]?.franquia_valor
        )
      );
      formRef.current.setFieldValue(
        'valor_tarifa_BOLETO',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]?.valor_tarifa
        )
      );
      formRef.current.setFieldValue(
        'franquia_valor_BOLETO',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]?.franquia_valor
        )
      );
      formRef.current.setFieldValue(
        'valor_tarifa_24 HORAS',
        Number(
          plano?.filter((i) => i.tipo_cobranca === '24 HORAS')[0]?.valor_tarifa
        )
      );
      formRef.current.setFieldValue(
        'valor_tarifa_MANUTENCAO',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'MANUTENCAO')[0]
            ?.valor_tarifa
        )
      );
      formRef.current.setFieldValue(
        'valor_tarifa_CARTAO',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'CARTAO')[0]?.valor_tarifa
        )
      );
      formRef.current.setFieldValue(
        'pontos_indicacao',
        Number(
          plano?.filter((i) => i.tipo_cobranca === 'TED')[0]?.pontos_indicacao
        )
      );
      // formRef.current.setFieldValue(
      //   'valor_bonificacao',
      //   Number(
      //     plano?.filter((i) => i.tipo_cobranca === 'TED')[0]?.valor_bonificacao
      //   )
      // );
    }, 0);
  }

  function setRefValuesAgain(dados) {
    setTimeout(() => {
      formRef.current.setFieldValue(
        'valor_tarifa_TED',
        Number(dados?.valor_tarifa_TED)
      );
      formRef.current.setFieldValue(
        'franquia_valor_TED',
        Number(dados?.franquia_valor_TED)
      );
      formRef.current.setFieldValue(
        'valor_tarifa_PIX',
        Number(dados?.valor_tarifa_PIX)
      );
      formRef.current.setFieldValue(
        'franquia_valor_PIX',
        Number(dados?.franquia_valor_PIX)
      );
      formRef.current.setFieldValue(
        'valor_tarifa_BOLETO',
        Number(dados?.valor_tarifa_BOLETO)
      );
      formRef.current.setFieldValue(
        'franquia_valor_BOLETO',
        Number(dados?.franquia_valor_BOLETO)
      );
      formRef.current.setFieldValue(
        'valor_tarifa_24 HORAS',
        Number(dados['valor_tarifa_24 HORAS'])
      );
      formRef.current.setFieldValue(
        'valor_tarifa_MANUTENCAO',
        Number(dados?.valor_tarifa_MANUTENCAO)
      );
      formRef.current.setFieldValue(
        'valor_tarifa_CARTAO',
        Number(dados?.valor_tarifa_CARTAO)
      );
      formRef.current.setFieldValue(
        'pontos_indicacao',
        Number(dados?.pontos_indicacao)
      );
      // formRef.current.setFieldValue(
      //   'valor_bonificacao',
      //   Number(dados?.valor_bonificacao)
      // );
    }, 0);
  }

  function selectToEdit(tipo, plano) {
    setPlanoSelected({ plano, tipo });
    setSelected(null);
    toggleModal();
    let internal;

    if (tipo === 'PF') {
      setSelected(
        planosPF.length ? planosPF?.filter((a) => a.modelo === plano) : []
      );
      internal = planosPF.length
        ? planosPF?.filter((a) => a.modelo === plano)
        : [];

      setRefValues(planosPF?.filter((a) => a.modelo === plano));
    } else {
      setSelected(
        planosPJ.length ? planosPJ?.filter((a) => a.modelo === plano) : []
      );
      internal = planosPJ.length
        ? planosPJ?.filter((a) => a.modelo === plano)
        : [];

      setRefValues(planosPJ?.filter((a) => a.modelo === plano));
    }
    setTipoFranquiaTED(
      String(
        internal?.filter((i) => i.tipo_cobranca === 'TED')[0]?.tipo_franquia
      )
    );
    setTipoFranquiaPIX(
      String(
        internal?.filter((i) => i.tipo_cobranca === 'PIX')[0]?.tipo_franquia
      )
    );
    setTipoFranquiaBOLETO(
      String(
        internal?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]?.tipo_franquia
      )
    );
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Planos</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' && 'active'}
              onClick={() => {
                toggle('1');
              }}
            >
              Pessoa Física
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' && 'active'}
              onClick={() => {
                toggle('2');
              }}
            >
              Pessoa Jurídica
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col>
                <Card>
                  <CardHeader className="text-center">Plano 1</CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Tipo cobrança</th>
                          <th className="text-center">Tipo franquia</th>
                          <th className="text-center">
                            Franquia de transações
                          </th>
                          <th className="text-center">Franquia de valor</th>
                          <th className="text-center">Tarifa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {planosPF.length ? (
                          planosPF
                            ?.filter((a) => a.modelo === 1)
                            ?.sort((a, b) =>
                              a.tipo_cobranca.localeCompare(b.tipo_cobranca)
                            )
                            ?.map((tar) => (
                              <tr key={tar?.id}>
                                <td>{tar?.tipo_cobranca}</td>
                                <td className="text-center">
                                  {tar?.tipo_franquia}
                                </td>
                                <td className="text-center">
                                  {tar?.franquia_quantidade}
                                </td>
                                <td className="text-center">
                                  {Number(tar?.franquia_valor)?.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                                <td className="text-center">
                                  {Number(tar?.valor_tarifa)?.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td>Sem registros encontrados</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left">Bonifica indicações</th>
                          <th className="text-center">Pontos</th>
                          {/* <th className="text-center">Valor bonificação</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">
                            {planosPF?.filter((a) => a.modelo === 1)[0]
                              ?.bonifica
                              ? 'Sim'
                              : 'Não'}
                          </td>
                          <td className="text-center">
                            {Number(
                              planosPF?.filter((a) => a.modelo === 1)[0]
                                ?.pontos_indicacao || 0
                            )}
                          </td>
                          {/* <td className="text-center">
                            {Number(
                              planosPF?.filter((a) => a.modelo === 1)[0]
                                ?.valor_bonificacao || 0
                            )?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="justify-end">
                        <Button
                          color="primary"
                          onClick={() => {
                            selectToEdit('PF', 1);
                          }}
                        >
                          Editar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col>
                <Card>
                  <CardHeader className="text-center">Plano 1</CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Tipo cobrança</th>
                          <th className="text-center">Tipo franquia</th>
                          <th className="text-center">
                            Franquia de transações
                          </th>
                          <th className="text-center">Franquia de valor</th>
                          <th className="text-center">Tarifa</th>
                        </tr>
                      </thead>
                      <tbody>
                        {planosPJ.length ? (
                          planosPJ
                            ?.filter((a) => a.modelo === 1)
                            ?.sort((a, b) =>
                              a.tipo_cobranca.localeCompare(b.tipo_cobranca)
                            )
                            ?.map((tar) => (
                              <tr key={tar?.id}>
                                <td>{tar?.tipo_cobranca}</td>
                                <td className="text-center">
                                  {tar?.tipo_franquia}
                                </td>
                                <td className="text-center">
                                  {tar?.franquia_quantidade}
                                </td>
                                <td className="text-center">
                                  {Number(tar?.franquia_valor)?.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                                <td className="text-center">
                                  {Number(tar?.valor_tarifa)?.toLocaleString(
                                    'pt-br',
                                    {
                                      style: 'currency',
                                      currency: 'BRL',
                                    }
                                  )}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td>Sem registros encontrados</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th className="text-left">Bonifica indicações</th>
                          <th className="text-center">Pontos</th>
                          {/* <th className="text-center">Valor bonificação</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">
                            {planosPJ?.filter((a) => a.modelo === 1)[0]
                              ?.bonifica
                              ? 'Sim'
                              : 'Não'}
                          </td>
                          <td className="text-center">
                            {Number(
                              planosPJ?.filter((a) => a.modelo === 1)[0]
                                ?.pontos_indicacao || 0
                            )}
                          </td>
                          {/* <td className="text-center">
                            {Number(
                              planosPJ?.filter((a) => a.modelo === 1)[0]
                                ?.valor_bonificacao || 0
                            )?.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col className="justify-end">
                        <Button
                          color="primary"
                          onClick={() => {
                            selectToEdit('PJ', 1);
                          }}
                        >
                          Editar
                        </Button>
                      </Col>
                    </Row>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="90">
        <ModalHeader toggle={toggleModal}>Editar plano</ModalHeader>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={12} md={12} sm={6}>
                <Radio
                  name="tipo_franquia_TED"
                  label="TED"
                  className="radio"
                  defaultValue={getId(
                    selected?.filter((i) => i.tipo_cobranca === 'TED')[0]
                      ?.tipo_franquia,
                    'TED'
                  )}
                  onClick={(e) => {
                    setTipoFranquiaTED(e.target.value);
                    setRefValuesAgain(formRef.current.getData());
                  }}
                  data={[
                    { id: '1', label: 'Sem franquia', value: 'S' },
                    { id: '2', label: 'Franquia por valor', value: 'V' },
                    { id: '3', label: 'Franquia por transação', value: 'T' },
                    { id: '4', label: 'Ilimitado', value: 'I' },
                  ]}
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaTED === 'I'}>
                <NumberFormat
                  name="valor_tarifa_TED"
                  label="Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaTED !== 'V'}>
                <NumberFormat
                  name="franquia_valor_TED"
                  label="Valor da franquia"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={12} sm={6} hidden={tipoFranquiaTED !== 'T'}>
                <Input
                  name="franquia_quantidade_TED"
                  label="Quantidade de transações"
                  className="form-control"
                  type="number"
                  defaultValue={
                    selected?.filter((i) => i.tipo_cobranca === 'TED')[0]
                      ?.franquia_quantidade
                  }
                  min={1}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={6}>
                <Radio
                  name="tipo_franquia_PIX"
                  label="PIX"
                  className="radio"
                  defaultValue={getId(
                    selected?.filter((i) => i.tipo_cobranca === 'PIX')[0]
                      ?.tipo_franquia,
                    'PIX'
                  )}
                  onClick={(e) => {
                    setTipoFranquiaPIX(e.target.value);
                    setRefValuesAgain(formRef.current.getData());
                  }}
                  data={[
                    { id: '5', label: 'Sem franquia', value: 'S' },
                    { id: '6', label: 'Franquia por valor', value: 'V' },
                    { id: '7', label: 'Franquia por transação', value: 'T' },
                    { id: '8', label: 'Ilimitado', value: 'I' },
                  ]}
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaPIX === 'I'}>
                <NumberFormat
                  name="valor_tarifa_PIX"
                  label="Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaPIX !== 'V'}>
                <NumberFormat
                  name="franquia_valor_PIX"
                  label="Valor da franquia"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={4} sm={6} hidden={tipoFranquiaPIX !== 'T'}>
                <Input
                  name="franquia_quantidade_PIX"
                  label="Quantidade de transações"
                  className="form-control"
                  type="number"
                  defaultValue={
                    selected?.filter((i) => i.tipo_cobranca === 'PIX')[0]
                      ?.franquia_quantidade
                  }
                  min={1}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={6}>
                <Radio
                  name="tipo_franquia_BOLETO"
                  label="BOLETO"
                  className="radio"
                  defaultValue={getId(
                    selected?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]
                      ?.tipo_franquia,
                    'BOLETO'
                  )}
                  onChange={(e) => {
                    setTipoFranquiaBOLETO(e.target.value);
                    setRefValuesAgain(formRef.current.getData());
                  }}
                  data={[
                    { id: '9', label: 'Sem franquia', value: 'S' },
                    { id: '10', label: 'Franquia por valor', value: 'V' },
                    { id: '11', label: 'Franquia por transação', value: 'T' },
                    { id: '12', label: 'Ilimitado', value: 'I' },
                  ]}
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaBOLETO === 'I'}>
                <NumberFormat
                  name="valor_tarifa_BOLETO"
                  label="Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaBOLETO !== 'V'}>
                <NumberFormat
                  name="franquia_valor_BOLETO"
                  label="Valor da franquia"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={6} md={6} sm={6} hidden={tipoFranquiaBOLETO !== 'T'}>
                <Input
                  name="franquia_quantidade_BOLETO"
                  label="Quantidade de transações"
                  className="form-control"
                  type="number"
                  defaultValue={
                    selected?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]
                      ?.franquia_quantidade
                  }
                  min={1}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4} md={6} sm={6}>
                <NumberFormat
                  name="valor_tarifa_24 HORAS"
                  label="24 HORAS - Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={4} md={6} sm={6}>
                <NumberFormat
                  name="valor_tarifa_MANUTENCAO"
                  label="MANUTENÇÃO - Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
              <Col lg={4} md={6} sm={6}>
                <NumberFormat
                  name="valor_tarifa_CARTAO"
                  label="EMISSÂO CARTÃO - Valor da tarifa"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6} sm={6}>
                <Radio
                  name="bonifica"
                  label="Bonifica indicação"
                  className="radio"
                  defaultValue={
                    selected?.filter((i) => i.tipo_cobranca === 'BOLETO')[0]
                      ?.bonifica
                  }
                  data={[
                    { id: true, label: 'Sim', value: true },
                    { id: false, label: 'Não', value: false },
                  ]}
                />
              </Col>
              <Col lg={4} md={6} sm={6}>
                <Input
                  name="pontos_indicacao"
                  label="Pontos"
                  className="form-control"
                  type="number"
                />
              </Col>
              {/* <Col lg={4} md={6} sm={6}>
                <NumberFormat
                  name="valor_bonificacao"
                  label="Valor bonificação"
                  className="form-control"
                  placeholder="R$"
                  number
                />
              </Col> */}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default TarifaTransacao;
