/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

function Contas() {
  const [conta, setConta] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modal, setModal] = useState(false);

  const [selectedConta, setSelectedConta] = useState({});

  const toggle = () => setModal(!modal);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'ml-3 btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getConta() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    const { data } = await api.get(`/contas?${params}`);
    setConta(data);
    window.scrollTo(0, 0);

    Swal.close();
  }
  useEffect(() => {
    getConta();
  }, [filtro]);

  async function verTermos(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/termos/${id}`);

      if (data) {
        setSelectedConta({
          data: data?.createdAt,
          celular: data?.modelo,
          id_unico: data?.mac_address,
          ip: data?.ip,
          latitude: data?.latitude,
          longitude: data?.longitude,
          fotos: data?.fotos,
        });
        Swal.close();
      }
      toggle();
    } catch (err) {
      toast.error('Erro ao buscar termos da conta!');
      Swal.close();
    }
  }

  async function encerrarConta(id) {
    Swal.fire({
      title: 'Tem certeza de que deseja encerrar esta conta?',
      text: 'Esta operação não poderá ser revertida!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true,
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        api
          .put(`/encerrar-conta-backoffice/${id}`)
          .then(() => {
            swalWithBootstrapButtons.fire('Conta encerrada!', '', 'success');
            getConta();
          })
          .catch((err) => {
            swalWithBootstrapButtons.fire(
              err.response.data.error ||
                'Ocorreu um erro, tente novamente mais tarde!',
              '',
              'error'
            );
          });
      }
    });
  }

  async function handleBloquear(dados) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post(`/bloquear-conta-backoffice/${dados.id}`, {
        status: dados.status === 'Bloqueado' ? 'Ativo' : 'Bloqueado',
      });
      toast.success(
        `Conta ${dados.status === 'Bloqueado' ? 'desbloqueada' : 'bloqueada'}`
      );
      getConta();
    } catch (err) {
      Swal.close();
      toast.error('Não foi possível bloqeuar/desbloquear esta conta');
    }
  }

  async function handleAnalisar(dados) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post(`/analise-conta-backoffice/${dados.id}`, {
        analise: !dados.analise,
      });
      toast.success(
        `Conta ${
          dados.analise ? 'removida da analise' : 'adicionada para análise'
        }`
      );
      getConta();
    } catch (err) {
      Swal.close();
      toast.error('Erro interno');
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Contas</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {conta.total}</h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'numero_conta')
                  }
                  className="form-control"
                  placeholder="Conta"
                />
              </Col>
              <Col lg={3}>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  placeholder="Status conta"
                >
                  <option value="Ativo">Ativas</option>
                  <option value="Bloqueado">Bloqueadas</option>
                  <option value="Encerrado">Encerradas</option>
                  <option value="">Todas</option>
                </select>
              </Col>
              <Col lg={3}>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'analise')}
                  className="form-control"
                >
                  <option value="">Análise</option>
                  <option value="S">Sim</option>
                  <option value="N">Não</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Responsável</th>
              <th>Tipo Conta</th>
              <th>Agência</th>
              <th>Conta</th>
              <th>Conta Pagamento</th>
              <th>Ispb</th>
              <th>Compe</th>
              <th>Status</th>
              <th>Plano</th>
              <th>Saldo</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {conta?.contas?.length ? (
              conta.contas?.map((c) => (
                <tr key={c?.id}>
                  <td>{c?.Pessoa.nome_completo}</td>
                  <td>
                    {c?.Pessoa?.Pessoa_fisica
                      ? 'Pessoa Física'
                      : 'Pessoa Jurídica'}
                  </td>
                  <td>{c?.agencia}</td>
                  <td>{c?.numero_conta}</td>
                  <td>{c?.conta_pagamento}</td>
                  <td>{c?.ispb}</td>
                  <td>{c?.compe}</td>
                  <td>{c?.status}</td>
                  <td>{c?.plano}</td>
                  <td>
                    {Number(c?.saldo_disponivel || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td className="text-center">
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => {
                            encerrarConta(c?.id);
                          }}
                          size="xs"
                        >
                          Encerrar conta
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleBloquear(c);
                          }}
                          size="xs"
                        >
                          {c.status === 'Bloqueado'
                            ? 'Desbloquear conta'
                            : 'Bloquear conta'}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            handleAnalisar(c);
                          }}
                          size="xs"
                        >
                          {c.analise
                            ? 'Remover da análise'
                            : 'Adicionar para análise'}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={conta.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Termos</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>Modelo Celular:</strong> {selectedConta?.celular}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Endereço IP:</strong> {selectedConta?.ip}
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={4} sm={6}>
              <strong>Latitude:</strong> {selectedConta?.latitude}
            </Col>
            <Col lg={6} md={4} sm={6}>
              <strong>Longitude:</strong> {selectedConta?.longitude}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>ID único:</strong> {selectedConta?.id_unico}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={4} sm={6}>
              <strong>Data do aceite:</strong>{' '}
              {new Date(selectedConta?.data)?.toLocaleString()}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12} md={4} sm={6}>
              Fotos:{' '}
              <a
                href={
                  selectedConta?.fotos?.selfie.includes('https:')
                    ? selectedConta?.fotos?.selfie
                    : `https://api.uneobank.com.br/files/${selectedConta?.fotos?.selfie}`
                }
                target="_blank"
                rel="noreferrer"
              >
                Selfie
              </a>{' '}
              /{' '}
              <a
                href={
                  selectedConta?.fotos?.documento_frente.includes('https:')
                    ? selectedConta?.fotos?.documento_frente
                    : `https://api.uneobank.com.br/files/${selectedConta?.fotos?.documento_frente}`
                }
                target="_blank"
                rel="noreferrer"
              >
                Doc. Frente
              </a>{' '}
              /{' '}
              <a
                href={
                  selectedConta?.fotos?.documento_verso.includes('https:')
                    ? selectedConta?.fotos?.documento_verso
                    : `https://api.uneobank.com.br/files/${selectedConta?.fotos?.documento_verso}`
                }
                target="_blank"
                rel="noreferrer"
              >
                Doc. Verso
              </a>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Contas;
