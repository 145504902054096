import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import React, { useEffect, useState, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Select, Input, TextArea } from '../../components/unform/index';
import api from '../../services/api';

// import { Container } from './styles';

function Notificacoes() {
  const [notificacoes, setNotificacoes] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  let timeoutConsulta;

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  const formRef = useRef(null);

  async function handleSubmit(e) {
    const errors = {};
    ['titulo', 'conteudo'].forEach((f) => {
      if (!e[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);
    if (Object.keys(errors).length) {
      toast.error('Verifique os campos em vermelhos');
      return;
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (Object.keys(modalIsOpen).length) {
        await api.put(`/notificacoes/adm/${modalIsOpen.id}`, e);
        Swal.close();
        formRef.current.reset();
        toast.success('Notificação alterada com sucesso!');
      } else {
        await api.post('/notificacoes/adm', e);
        Swal.close();
        formRef.current.reset();
        toast.success('Notificação enviada com sucesso!');
      }
      setRefresh(!refresh);
      toggleModal();
      return;
    } catch (error) {
      Swal.close();
      toast.error(
        error?.response?.data?.error ||
          'Ocorreu um erro, tente novamente mais tarde'
      );
    }
  }

  useEffect(() => {
    async function getLog() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/notificacoes/adm?${params}`);
      setNotificacoes(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getLog();
  }, [filtro, refresh]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Notificações</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">
            Total de registros: {notificacoes.total || 0}
          </h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button
            className="btn btn-primary"
            onClick={toggleModal}
            color="primary"
          >
            Nova
          </Button>
        </div>

        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'titulo')}
                  className="form-control"
                  placeholder="Título"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'conteudo')}
                  className="form-control"
                  placeholder="Conteudo"
                />
              </Col>
              <Col>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'campanha')}
                  className="form-control"
                  placeholder="Campanha"
                >
                  <option>Campanha</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </Col>
              <Col>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'exibir')}
                  className="form-control"
                  placeholder="Exibir"
                >
                  <option>Exibir</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Título</th>
              <th>Conteudo</th>
              <th>Campanha ?</th>
              <th>Exibir</th>
              <th>Enviado em</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {notificacoes.data?.length ? (
              notificacoes.data?.map((notificacao) => (
                <tr key={notificacao?.id}>
                  <td>{notificacao?.titulo}</td>
                  <td className="w-50">{notificacao?.conteudo}</td>
                  <td>{notificacao?.campanha ? 'Sim' : 'Não'}</td>
                  <td>{notificacao?.exibir ? 'Sim' : 'Não'}</td>
                  <td>
                    {new Date(notificacao?.createdAt)
                      ?.toLocaleString()
                      .substring(0, 16)}
                  </td>
                  <td>
                    {notificacoes && (
                      <UncontrolledDropdown size="xs">
                        <DropdownToggle color="link link-gray" size="xs">
                          <FaEllipsisH size="1.3em" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              setModalIsOpen(notificacao);
                            }}
                            size="xs"
                          >
                            Editar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  Sem registros encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(notificacoes.pages) || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modalIsOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {Object.keys(modalIsOpen).length ? 'Editar' : 'Criar'} Notificação
        </ModalHeader>
        <Form
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
          initialData={modalIsOpen}
        >
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Input label="Título" name="titulo" className="form-control" />
              </Col>
              <Col lg={12}>
                <TextArea
                  label="Conteúdo"
                  name="conteudo"
                  className="form-control"
                />
              </Col>
              {/* <Col lg={6}>
                <Select
                  label="Possui campanha ?"
                  name="campanha"
                  className="form-control"
                  data={[
                    { value: false, label: 'Não' },
                    { value: true, label: 'Sim' },
                  ]}
                />
              </Col> */}
              {Object.keys(modalIsOpen).length > 0 && (
                <Col lg={6}>
                  <Select
                    label="Exibir ?"
                    name="exibir"
                    className="form-control"
                    data={[
                      { value: false, label: 'Não' },
                      { value: true, label: 'Sim' },
                    ]}
                  />
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter className="mb-2">
            <Button color="primary" type="submit">
              {Object.keys(modalIsOpen).length ? 'Editar' : 'Criar'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Notificacoes;
