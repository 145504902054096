import React from 'react';
import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import { AuthProvider } from './contexts/auth';

import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';

function App() {
  return (
    <>
      <AuthProvider>
        <Routes />
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </>
  );
}

export default App;
