import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import api from '../../services/api';

function ListarCupons() {
  const [dados, setDados] = useState([]);
  const [filtro, setFiltro] = useState({});

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }

  // eslint-disable-next-line consistent-return
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getList() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/sorteio-historico?${params}`);
      setDados(data);
      window.scrollTo(0, 0);
    } catch (err) {
      toast.error('Erro ao carregar dados');
    }

    Swal.close();
  }

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Histórico sorteios</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {dados.total}</h6>
          </Col>
          <Col>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
          <div className="d-flex justify-content-center" />
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={2}>
                <label className="form-label">Código</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'codigo')}
                  className="form-control"
                  placeholder="Digite o código"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Mês</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'mes')}
                  className="form-control"
                  placeholder="Digite o mês"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Ano</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'ano')}
                  className="form-control"
                  placeholder="Digite o ano"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Status</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  placeholder="Digite o status"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Código</th>
              <th>Vencedor</th>
              <th className="text-center">Data sorteio</th>
              <th className="text-center">Valor</th>
              <th className="text-center">Id Cupom</th>
              <th className="text-center">Id Pix</th>
            </tr>
          </thead>
          <tbody>
            {dados?.items?.length ? (
              dados?.items?.map((tar) => (
                <tr key={tar?.id}>
                  <td>{tar?.codigo}</td>
                  <td>{tar?.Contum?.Pessoa?.nome_completo}</td>
                  <td className="text-center">
                    {tar?.dia} / {tar?.mes} / {tar?.ano}
                  </td>
                  <td className="text-center">
                    {Number(tar?.valor || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td className="text-center">{tar?.id_cupom}</td>
                  <td className="text-center">{tar?.id_pix}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ListarCupons;
