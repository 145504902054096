/* eslint-disable react/destructuring-assignment */
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Input, Select } from '../../components/unform/index';

function Cliente(props) {
  const formRef = useRef();

  const handleSubmit = (data) => {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      props.onAdd(data);
    }
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <Form ref={formRef} onSubmit={(data) => handleSubmit(data)}>
        <ModalHeader toggle={props.toggle}>Novo cliente</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <Input
                label="Nome"
                className="form-control"
                type="text"
                name="nome"
                defaultValue={props.value}
              />
            </Col>
            <Col lg={6}>
              <Input
                label="Nome para exibição"
                className="form-control"
                type="text"
                name="nome_exibicao"
                defaultValue={props.value}
              />
            </Col>
            <Col lg={6}>
              <Input
                label="Documento"
                className="form-control"
                type="number"
                name="documento"
              />
            </Col>
            <Col lg={6}>
              <Select
                label="Tipo"
                className="form-control"
                name="tipo"
                data={[
                  {
                    label: 'Selecione',
                    value: '',
                  },
                  {
                    label: 'Fornecedor',
                    value: 'F',
                  },
                  {
                    label: 'Cliente',
                    value: 'C',
                  },
                ]}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" type="submit">
            Salvar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default Cliente;
