import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
} from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Select from '../../components/unform/select';
import Input from '../../components/unform/input';
import AsyncSelect from '../../components/reactSelectAsync';
import ReactSelectAsync from '../../components/unform/reactSelectAsync';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Emprestimos() {
  const [creditoList, setCreditoList] = useState([]);
  const [filtro, setFiltro] = useState({
    status: '',
  });
  const [modal, setModal] = useState(false);
  const [pagamentos, setPagamentos] = useState([]);
  const [modalEditPag, setModalEditPag] = useState(false);
  const formRef = useRef();
  const optionsRef = useRef();
  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...(filtro || {}), [value]: e, page: 1 });
  }
  async function filtrarConsultaOpts({ documento }) {
    const result = await api.get(`/options/conta?documento=${documento}`);
    return result.data;
  }

  const toggleModalEditPag = () => setModalEditPag(false);

  const promiseOptions = async (val) =>
    new Promise((resolve) => {
      clearTimeout(optionsRef.current);

      optionsRef.current = setTimeout(() => {
        resolve(filtrarConsultaOpts(val));
      }, 300);
    });

  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getConta() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        if (filtro[e]) {
          return `${e}=${filtro[e]}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');

    try {
      const { data } = await api.get(`/emprestimos-adm?${params}`);

      setCreditoList(data);
      window.scrollTo(0, 0);

      Swal.close();
    } catch (err) {
      Swal.close();
      toast.error(err?.response?.data?.err || 'Ocorreu um erro');
    }
  }

  const toggleModal = () => {
    setModal(false);
  };

  const toggleModalPagamentos = () => setPagamentos([]);

  async function handleResponder(dados) {
    const errors = {};
    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos obrigatórios');
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      if (modal?.id) {
        await api.put(`/editaremprestimo/${modal?.id}`, dados);
        toast.success('Empréstimo alterado');
      } else {
        await api.post('/abrirempreadm', dados);
        toast.success('Empréstimo criado');
      }

      toggleModal();
      Swal.close();
      getConta();
    } catch (err) {
      Swal.close();
      toast.error(
        err?.response?.data?.error || 'Erro interno, tente novamente mais tarde'
      );
    }
  }

  async function handleResponderPag(dados) {
    const errors = {};
    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos obrigatórios');
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.put(`/editaremprepgto/${modalEditPag?.id}`, dados);
      toast.success('Pagamento alterado');
      toggleModalEditPag();
      setPagamentos([]);
      Swal.close();
    } catch (err) {
      Swal.close();
      toast.error(
        err?.response?.data?.error || 'Erro interno, tente novamente mais tarde'
      );
    }
  }

  async function getPagamentos(obj) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/emprepgto/${obj.id}`);
      setPagamentos(data);
      Swal.close();
    } catch (err) {
      toast.error(err.mensagem);
      Swal.close();
    }
  }

  async function baixaManual(obj) {
    swalWithBootstrapButtons
      .fire({
        title: 'Baixa pagamento',
        text: `Ao clicar em confirmar será acrescentado ${Number(
          obj?.valor_parcela
        ).toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        })} ao total pago do empréstimo.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .post(`/baixa-pagamento/${obj.id}`)
            .then(() => {
              Swal.close();
              toast.success('Pagamento baixado');
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  useEffect(() => {
    if (filtro) {
      getConta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Empréstimos</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {creditoList.total}</h6>

          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button color="primary" onClick={() => setModal(true)}>
            Novo
          </Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={6}>
                <AsyncSelect
                  isClearable
                  loadOptions={(documento) => promiseOptions({ documento })}
                  onChange={(e) => debounceConsulta(e?.value, 'id_conta')}
                  placeholder="CPF/CNPJ conta"
                />
              </Col>
              <Col lg={3}>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                >
                  <option value="">Status</option>
                  <option value="Aprovado">Aprovado</option>
                  <option value="Finalizado">Finalizado</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>CPF/CNPJ</th>
              <th>Nome</th>
              <th>Valor total com juros</th>
              <th>Valor pago</th>
              <th>Valor concedido</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {creditoList?.items?.length ? (
              creditoList?.items?.map((c) => (
                <tr key={c?.id}>
                  <td>{c?.Contum?.Pessoa?.documento}</td>
                  <td>{c?.Contum?.Pessoa?.nome_completo}</td>
                  <td>
                    {Number(c?.valor_total_juros).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td>
                    {Number(c?.valor_pago).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td>
                    {Number(c?.valor_concedido).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                  <td data-label="Ação" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => setModal(c)}>
                          Editar
                        </DropdownItem>
                        <DropdownItem onClick={() => getPagamentos(c)}>
                          Pagamentos
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={creditoList.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>
          {`${modal?.Contum?.Pessoa?.nome_completo || 'Novo empréstimo'} - ${
            modal?.Contum?.Pessoa?.documento || ''
          }`}
        </ModalHeader>

        <Form
          ref={formRef}
          onSubmit={handleResponder}
          initialData={typeof modal === 'boolean' ? {} : modal}
        >
          <ModalBody>
            <Row>
              {!modal?.id && (
                <Col lg={6} className="mb-3">
                  <ReactSelectAsync
                    name="id_conta"
                    isClearable
                    label="Conta"
                    loadOptions={(documento) => promiseOptions({ documento })}
                    placeholder="CPF/CNPJ conta"
                  />
                </Col>
              )}
              <Col lg={6} className="mb-3">
                <Select
                  name="status"
                  label="Status"
                  className="form-control"
                  data={[
                    { label: 'Selecione', value: '' },
                    { label: 'Aprovado', value: 'Aprovado' },
                    { label: 'Finalizado', value: 'Finalizado' },
                  ]}
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor_solicitado"
                  label="Valor solicitado"
                  className="form-control"
                  placeholder="0,00"
                  step={0.01}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor_concedido"
                  label="Valor concedido emprestimo"
                  className="form-control"
                  placeholder="0,00"
                  step={0.01}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="fator_emprestimo"
                  label="Juros (%)"
                  className="form-control"
                  placeholder="%"
                  step={0.1}
                  type="number"
                />
              </Col>
              {modal?.id && (
                <>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="valor_total_juros"
                      label="Valor total juros"
                      className="form-control"
                      step={0.01}
                      type="number"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="valor_pago"
                      label="Valor pago"
                      placeholder="Valor já pago pelo cliente"
                      className="form-control"
                      step={0.01}
                      type="number"
                    />
                  </Col>
                </>
              )}

              <Col lg={6} className="mb-3">
                <Input
                  name="parcelas"
                  label="Qtd. parcelas"
                  className="form-control"
                  placeholder="1"
                  step={1}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="data_liberacao"
                  label="Data liberação empréstimo"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="data_primeira_cobranca"
                  label="Data primeira cobrança"
                  className="form-control"
                  type="date"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={pagamentos?.length}
        toggle={toggleModalPagamentos}
        size="90"
      >
        <ModalHeader toggle={toggleModalPagamentos}>
          Pagamentos empréstimo
        </ModalHeader>
        <Table responsive>
          <thead>
            <tr>
              <th>Parcela</th>
              <th>Valor</th>
              <th>Vencimento</th>
              <th>Data pagamento</th>
              <th>Obs.</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {pagamentos?.map((c) => (
              <tr key={c?.id}>
                <td>{c?.parcela}</td>
                <td>
                  {Number(c?.valor_parcela).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </td>
                <td>
                  {new Date(c?.data_vencimento).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                  })}
                </td>
                <td>
                  {c?.data_pagamento &&
                    new Date(c?.data_pagamento).toLocaleDateString('pt-BR', {
                      day: '2-digit',
                      month: 'short',
                      year: '2-digit',
                    })}
                </td>
                <td>{c?.observacao}</td>
                <td style={{ textAlign: 'center' }}>{c?.status}</td>
                <td data-label="Ação" className="text-center">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FaCog className="mr-2" /> <FaAngleDown />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => setModalEditPag(c)}>
                        Editar
                      </DropdownItem>
                      <DropdownItem onClick={() => baixaManual(c)}>
                        Baixa pagamento
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal>

      <Modal isOpen={modalEditPag} toggle={toggleModalEditPag} size="md">
        <ModalHeader toggle={toggleModalEditPag}>Editar pagamento</ModalHeader>

        <Form
          ref={formRef}
          onSubmit={handleResponderPag}
          initialData={typeof modalEditPag === 'boolean' ? {} : modalEditPag}
        >
          <ModalBody>
            <Row>
              <Col lg={6} className="mb-3">
                <Select
                  name="status"
                  label="Status"
                  className="form-control"
                  data={[
                    { label: 'Selecione', value: '' },
                    { label: 'Pendente', value: 'Pendente' },
                    { label: 'Pago', value: 'Pago' },
                  ]}
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="forma_pagamento"
                  label="Forma pagamento"
                  className="form-control"
                />
              </Col>
              <Col lg={12} className="mb-3">
                <Input
                  name="observacao"
                  label="Obervação"
                  className="form-control"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor_parcela"
                  label="Valor parcela"
                  className="form-control"
                  step={0.01}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="parcela"
                  label="Parcela"
                  className="form-control"
                  placeholder="1"
                  step={1}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="data_vencimento"
                  label="Data vencimento"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="data_pagamento"
                  label="Data pagamento"
                  className="form-control"
                  type="date"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Emprestimos;
