import React from 'react';
import Select from 'react-select/async';
import customStyles from './unform/stylesSelect';

export default function AsyncSelect(props) {
  return (
    <Select
      cacheOptions
      classNamePrefix="react-select"
      styles={customStyles}
      {...props}
    />
  );
}
