import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCollapse,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {
  FaChevronLeft,
  FaChevronRight,
  FaBarcode,
  FaCog,
  FaAngleDown,
  FaCubes,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import { FiFilter } from 'react-icons/fi';
import api from '../../services/api';

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [day, month, year].join('/');
}

const urlBankly = window.location.origin.includes('http://localhost')
  ? 'https://api.sandbox.bankly.com.br'
  : 'https://api.bankly.com.br';

function GestaoDeTarifas() {
  const [filtro, setFiltro] = useState({});
  const [faturas, setFaturas] = useState([]);
  const [compras, setCompras] = useState([]);

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function pesquisaFaturas() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    try {
      const { data } = await api.get(`/faturas-backoffice?${params}`);
      window.scrollTo(0, 0);

      setFaturas(data);
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  useEffect(() => {
    pesquisaFaturas();
  }, [filtro]);

  async function verCompras(idFatura) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { data } = await api.get(`/faturas-parcela/${idFatura}`);

      setCompras(data);
      toggleModal();
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  // async function infosPagamento(idFatura) {
  //   Swal.fire({
  //     title: 'Aguarde',
  //     allowOutsideClick: false,
  //     showConfirmButton: false,
  //     didOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });

  //   try {
  //     const { data } = await api.get(`/fatura-boleto/${idFatura}`);
  //     if (data) {

  //     }
  //     Swal.close();
  //   } catch (err) {
  //     toast.error('Erro ao buscar dados!');
  //     Swal.close();
  //   }
  // }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Gestão de faturas</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {faturas.total}</h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'conta')}
                  className="form-control"
                  placeholder="Conta"
                />
              </Col>
              <Col>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  placeholder="Status conta"
                >
                  <option value="Aberto">Aberto</option>
                  <option value="Pago">Pago</option>
                  <option value="">Todas</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Conta</th>
              <th style={{ textAlign: 'center' }}>Nome</th>
              <th style={{ textAlign: 'center' }}>Data vencimento</th>
              <th style={{ textAlign: 'center' }}>Valor</th>
              <th style={{ textAlign: 'center' }}>Data pagamento</th>
              <th style={{ textAlign: 'center' }}>Valor pago</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th style={{ textAlign: 'center' }}>Observação</th>
              <th style={{ textAlign: 'center' }}>Código de autenticação</th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {faturas?.faturas?.length ? (
              faturas?.faturas?.map((c) => (
                <tr key={c?.id}>
                  <td style={{ textAlign: 'center' }}>{c?.conta}</td>
                  <td style={{ textAlign: 'center' }}>{c?.nome}</td>
                  <td style={{ textAlign: 'center' }}>
                    {formatDate(c?.data_vencimento)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(c?.valor).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {c?.data_pagamento ? formatDate(c?.data_pagamento) : ''}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(c?.valor_pago).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                  <td style={{ textAlign: 'center' }}>{c?.observacao}</td>
                  <td style={{ textAlign: 'center' }}>
                    {c?.Transferencia?.codigo_autenticacao}
                  </td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-center">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          color="link"
                          className=" text-decoration-none padding-0-5"
                        >
                          <FaCog className="mr-2" /> <FaAngleDown />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              verCompras(c?.id);
                            }}
                          >
                            <FaCubes className="mr-1" />
                            Ver compras
                          </DropdownItem>
                          {/* <div tabIndex="-1" className="dropdown-divider" />
                          <DropdownItem
                            onClick={() => {
                              infosPagamento(c?.id);
                            }}
                          >
                            <FaBarcode className="mr-1" />
                            Info. pagamento
                          </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={faturas.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Compras</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Parcela</th>
                    <th style={{ textAlign: 'center' }}>Valor</th>
                    <th style={{ textAlign: 'center' }}>Valor parcela</th>
                    <th style={{ textAlign: 'center' }}>Valor total</th>
                    <th style={{ textAlign: 'center' }}>Status</th>
                    <th style={{ textAlign: 'center' }}>Estabelecimento</th>
                    <th style={{ textAlign: 'center' }}>Data da compra</th>
                  </tr>
                </thead>
                <tbody>
                  {compras?.length ? (
                    compras?.map((c) => (
                      <tr key={c?.id}>
                        <td style={{ textAlign: 'center' }}>
                          {c?.parcela} / {c?.Compra?.total_parcelas}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {Number(c?.Compra?.valor).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency',
                          })}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {Number(c?.valor_parcela).toLocaleString('pt-BR', {
                            currency: 'BRL',
                            style: 'currency',
                          })}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {Number(c?.Compra?.valor_total).toLocaleString(
                            'pt-BR',
                            {
                              currency: 'BRL',
                              style: 'currency',
                            }
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>{c?.status}</td>
                        <td style={{ textAlign: 'center' }}>
                          {c?.Compra?.estabelecimento}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {formatDate(c?.Compra?.createdAt)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default GestaoDeTarifas;
