import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaAngleDown,
  FaCheck,
  FaTimes,
  FaAlignLeft,
  FaUserFriends,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import { FiFilter } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { Select, Input, MaskInput } from '../../components/unform/index';
import api from '../../services/api';
import addEstabelecimento from '../../validations/addEstabelecimento';
import addFuncionario from '../../validations/addFuncionario';

import { cnpjMask, celMask, cepMask } from '../../utils/mask';

function EstabelecimentoFuncionario() {
  const fomrRefEstabelecimento = useRef();
  const fomrRefFuncionario = useRef();
  const [filtro, setFiltro] = useState({});
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [infos, setInfos] = useState([]);

  const [idEstabelecimento, setIdEstabelecimento] = useState([]);

  const [modalAddFuncionario, setModalAddFuncionario] = useState(false);
  const [modalEstabelecimento, setModalEstabelecimento] = useState(false);
  const [modalInfos, setModalInfos] = useState(false);
  const [modalFuncionarios, setModalFuncionarios] = useState(false);

  const toggleModalAddFuncionario = () =>
    setModalAddFuncionario(!modalAddFuncionario);
  const toggleModalAddEstabelecimento = () =>
    setModalEstabelecimento(!modalEstabelecimento);
  const toggleModalInfos = () => setModalInfos(!modalInfos);
  const toggleModalFuncionarios = () =>
    setModalFuncionarios(!modalFuncionarios);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getEstabelecimentos() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get('/lista-estabelecimentos');

      setEstabelecimentos(data);
      Swal.close();
    } catch (error) {
      toast.error('Erro ao buscar estabelecimentos!');
      Swal.close();
    }
  }

  async function detalhes(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/infos-estabelecimento/${id}`);

      setInfos(data);
      toggleModalInfos();
      Swal.close();
    } catch (error) {
      toast.error('Erro ao buscar infos!');
      Swal.close();
    }
  }

  async function funcionariosEstabelecimento(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/lista-funcionarios/${id}`);

      setFuncionarios(data);
      setIdEstabelecimento(id);
      toggleModalFuncionarios();
      Swal.close();
    } catch (error) {
      toast.error('Erro ao buscar funcionários!');
      Swal.close();
    }
  }

  async function handleSubmitEstabelecimento(body) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await addEstabelecimento(body);

      const { data } = await api.post('/cria-estabelecimento', body);

      setEstabelecimentos([...estabelecimentos, data]);
      toast.success('Estabelecimento adicionado com sucesso!');
      toggleModalAddEstabelecimento();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        fomrRefEstabelecimento.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
        Swal.close();
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  async function handleAtivaDesativaEstabelecimento(id) {
    try {
      await api.put(`/ativa-desativa-estabelecimento/${id}`);
      toast.success('Estabelecimento ativado/desativado com sucesso!');
      await getEstabelecimentos();
      Swal.close();
    } catch (error) {
      toast.error('Erro ao ativar/desativar estabelecimento');
    }
  }

  async function handleAtivaDesativaFuncionario(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await api.put(`/ativa-desativa-funcionario/${id}`);

      toggleModalFuncionarios();
      toast.success('Funcionário ativado/desativado com sucesso!');
      Swal.close();
    } catch (error) {
      toast.error('Erro ao ativar/desativar funcionário');
      Swal.close();
    }
  }

  async function handleSubmitFuncionario(body) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await addFuncionario(body);
      body.estabelecimento = idEstabelecimento;
      const { data } = await api.post('/cria-funcionario', body);

      setFuncionarios([...funcionarios, data]);
      toggleModalAddFuncionario();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        fomrRefFuncionario.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
        Swal.close();
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  useEffect(() => {
    getEstabelecimentos();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Estabelecimentos</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-end d-flex align-items-center">
          <Button
            className="btn btn-primary"
            onClick={toggleModalAddEstabelecimento}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <Table className="mb-5" responsive>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Nome</th>
              <th style={{ textAlign: 'center' }}>CNPJ</th>
              <th style={{ textAlign: 'center' }}>Responsável</th>
              <th style={{ textAlign: 'center' }}>Telefone</th>
              <th style={{ textAlign: 'center' }}>Cidade</th>
              <th style={{ textAlign: 'center' }}>Estado</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th style={{ textAlign: 'center' }}>Uneobank</th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {estabelecimentos?.length ? (
              estabelecimentos?.map((c) => (
                <tr key={c?.id}>
                  <td style={{ textAlign: 'center' }}>{c?.nome}</td>
                  <td style={{ textAlign: 'center' }}>{c?.cnpj}</td>
                  <td style={{ textAlign: 'center' }}>{c?.responsavel}</td>
                  <td style={{ textAlign: 'center' }}>{c?.telefone}</td>
                  <td style={{ textAlign: 'center' }}>{c?.cidade}</td>
                  <td style={{ textAlign: 'center' }}>{c?.estado}</td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                  <th style={{ textAlign: 'center' }}>
                    {c?.uneobank ? (
                      <FaCheck color="green" />
                    ) : (
                      <FaTimes color="red" />
                    )}
                  </th>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-center">
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <FaCog className="mr-2" /> <FaAngleDown />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              detalhes(c?.id);
                            }}
                          >
                            <FaAlignLeft className="mr-1" />
                            Ver mais detalhes
                          </DropdownItem>
                          <div tabIndex="-1" className="dropdown-divider" />
                          <DropdownItem
                            onClick={() => {
                              funcionariosEstabelecimento(c?.id);
                            }}
                          >
                            <FaUserFriends className="mr-1" />
                            Ver funcionários
                          </DropdownItem>
                          <div tabIndex="-1" className="dropdown-divider" />
                          <DropdownItem
                            onClick={() => {
                              handleAtivaDesativaEstabelecimento(c?.id);
                            }}
                          >
                            {c?.status && c?.status === 'A' ? (
                              <>
                                <FaTimes color="red" className="mr-1" />
                                Inativar
                              </>
                            ) : (
                              <>
                                <FaCheck color="green" className="mr-1" />
                                Ativar
                              </>
                            )}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={estabelecimentos.pages || 1}
          onPageChange={({ selected }) => setFiltro({ ...filtro, page: selected + 1 })}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        /> */}
      </Card>

      <Modal
        isOpen={modalFuncionarios}
        toggle={toggleModalFuncionarios}
        size="lg"
      >
        <ModalHeader toggle={toggleModalFuncionarios}>Funcionários</ModalHeader>
        <div className="p-3 justify-content-end d-flex align-items-center">
          <Button
            className="btn btn-primary"
            onClick={toggleModalAddFuncionario}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Matrícula</th>
                    <th>Email</th>
                    <th>Nome</th>
                    <th>Tipo</th>
                    <th style={{ textAlign: 'center' }}>Status</th>
                    <th style={{ textAlign: 'center' }}>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {funcionarios?.length ? (
                    funcionarios?.map((c) => (
                      <tr key={c?.id}>
                        <td>{c?.id}</td>
                        <td>{c?.email}</td>
                        <td>{c?.nome}</td>
                        <td>
                          {c?.tipo === 'A' ? 'Administrador' : 'Operador'}
                        </td>
                        <td style={{ textAlign: 'center' }}>{c?.status}</td>
                        <td data-label="Ações">
                          <div className="d-flex justify-content-center">
                            <UncontrolledButtonDropdown>
                              <DropdownToggle
                                color="link"
                                className="text-decoration-none padding-0-5"
                              >
                                <FaCog className="mr-2" /> <FaAngleDown />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem
                                  onClick={() => {
                                    handleAtivaDesativaFuncionario(c?.id);
                                  }}
                                >
                                  {c?.status && c?.status === 'A' ? (
                                    <>
                                      <FaTimes color="red" className="mr-1" />
                                      Inativar
                                    </>
                                  ) : (
                                    <>
                                      <FaCheck color="green" className="mr-1" />
                                      Ativar
                                    </>
                                  )}
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleModalFuncionarios}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalInfos} toggle={toggleModalInfos} size="lg">
        <ModalHeader toggle={toggleModalInfos}>
          Informações Estabelecimento
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={6}>
              <b>Nome: </b>
              {infos && infos?.nome}
            </Col>
            <Col lg={6}>
              <b>CNPJ: </b>
              {infos && infos?.cnpj}
            </Col>
            <Col lg={6}>
              <b>Responsável: </b>
              {infos && infos?.responsavel}
            </Col>
            <Col lg={6}>
              <b>Telefone: </b>
              {infos && infos?.telefone}
            </Col>
            <Col lg={6}>
              <b>Email: </b>
              {infos && infos?.email}
            </Col>
            <Col lg={6}>
              <b>CEP: </b>
              {infos && infos?.cep}
            </Col>
            <Col lg={6}>
              <b>Logradouro: </b>
              {infos && infos?.logradouro}
            </Col>
            <Col lg={6}>
              <b>Número: </b>
              {infos && infos?.numero}
            </Col>
            <Col lg={6}>
              <b>Bairro: </b>
              {infos && infos?.bairro}
            </Col>
            <Col lg={6}>
              <b>Cidade: </b>
              {infos && infos?.cidade}
            </Col>
            <Col lg={6}>
              <b>Estado: </b>
              {infos && infos?.estado}
            </Col>
            <Col lg={6}>
              <b>UneoBank: </b>
              {infos && infos?.uneobank ? (
                <FaCheck color="green" />
              ) : (
                <FaTimes color="red" />
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleModalInfos}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalEstabelecimento}
        toggle={toggleModalAddEstabelecimento}
        size="lg"
      >
        <Form
          ref={fomrRefEstabelecimento}
          onSubmit={(data) => handleSubmitEstabelecimento(data)}
        >
          <ModalHeader toggle={toggleModalAddEstabelecimento}>
            Cadastrar estabelecimento
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={6}>
                <Input
                  label="Nome"
                  className="form-control"
                  type="text"
                  name="nome"
                />
              </Col>
              <Col lg={3}>
                <MaskInput
                  name="cnpj"
                  label="CNPJ"
                  className="form-control"
                  mask={cnpjMask}
                />
              </Col>
              <Col lg={3}>
                <MaskInput
                  name="telefone"
                  label="Telefone"
                  className="form-control"
                  mask={celMask}
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Responsável"
                  className="form-control"
                  type="text"
                  name="responsavel"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Email"
                  className="form-control"
                  type="text"
                  name="email"
                />
              </Col>
              <Col lg={3}>
                <MaskInput
                  name="cep"
                  label="CEP"
                  className="form-control"
                  mask={cepMask}
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Logradouro"
                  className="form-control"
                  type="text"
                  name="logradouro"
                />
              </Col>
              <Col lg={3}>
                <Input
                  label="Número"
                  className="form-control"
                  type="text"
                  name="numero"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Bairro"
                  className="form-control"
                  type="text"
                  name="bairro"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Cidade"
                  className="form-control"
                  type="text"
                  name="cidade"
                />
              </Col>
              <Col lg={4}>
                <Input
                  label="Estado"
                  className="form-control"
                  type="text"
                  name="estado"
                />
              </Col>
              <Col lg={5}>
                <Input
                  label="Completo"
                  className="form-control"
                  type="text"
                  name="complemento"
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="uneobank"
                  label="UneoBank"
                  className="form-control"
                  data={[
                    { label: 'Não', value: false },
                    { label: 'Sim', value: true },
                  ]}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={modalAddFuncionario}
        toggle={toggleModalAddFuncionario}
        size="lg"
      >
        <Form
          ref={fomrRefFuncionario}
          onSubmit={(data) => handleSubmitFuncionario(data)}
        >
          <ModalHeader toggle={toggleModalAddFuncionario}>
            Cadastrar funcionario
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={6}>
                <Input
                  label="Email"
                  className="form-control"
                  type="text"
                  name="email"
                />
              </Col>
              <Col lg={6}>
                <Input
                  label="Nome"
                  className="form-control"
                  type="text"
                  name="nome"
                />
              </Col>
            </Row>
            <Col lg={3}>
              <Select
                name="tipo"
                label="Tipo"
                className="form-control"
                data={[
                  { label: 'Operador', value: 'F' },
                  { label: 'Administrador', value: 'A' },
                ]}
              />
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default EstabelecimentoFuncionario;
