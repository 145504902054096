/* eslint-disable operator-linebreak */
import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { Form } from '@unform/web';
import { FiSearch } from 'react-icons/fi';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Input } from '../../components/unform/index';

import api from '../../services/api';

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

function formatDateBR(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [day, month, year].join('/');
}

function formatGraph(saida, entrada) {
  const arrayReturn = [];
  if (saida?.length) {
    saida.forEach((saidaItem) => {
      if (arrayReturn.length) {
        arrayReturn.forEach((arrayretItem) => {
          if (arrayretItem.Dia === formatDateBR(saidaItem?.data_evento)) {
            if (arrayretItem.Saida) {
              arrayretItem.Saida += Math.abs(Number(saidaItem?.valor));
            } else {
              arrayretItem.Saida = Math.abs(Number(saidaItem?.valor));
            }
          }
        });
        const newArr = arrayReturn.filter(
          (arrayretItem) =>
            arrayretItem.Dia === formatDateBR(saidaItem?.data_evento)
        );
        if (!newArr.length) {
          arrayReturn.push({
            Dia: formatDateBR(saidaItem?.data_evento),
            Saida: Math.abs(Number(saidaItem?.valor)),
          });
        }
      } else {
        arrayReturn.push({
          Dia: formatDateBR(saidaItem?.data_evento),
          Saida: Math.abs(Number(saidaItem?.valor)),
        });
      }
    });
  }

  if (entrada?.length) {
    entrada.forEach((entradaItem) => {
      if (arrayReturn.length) {
        arrayReturn.forEach((arrayretItem) => {
          if (arrayretItem.Dia === formatDateBR(entradaItem?.data_evento)) {
            if (arrayretItem.Entrada) {
              arrayretItem.Entrada += Number(entradaItem?.valor);
            } else {
              arrayretItem.Entrada = Number(entradaItem?.valor);
            }
          }
        });
        const newArr = arrayReturn.filter(
          (arrayretItem) =>
            arrayretItem.Dia === formatDateBR(entradaItem?.data_evento)
        );
        if (!newArr.length) {
          arrayReturn.push({
            Dia: formatDateBR(entradaItem?.data_evento),
            Entrada: Number(entradaItem?.valor),
          });
        }
      } else {
        arrayReturn.push({
          Dia: formatDateBR(entradaItem?.data_evento),
          Entrada: Number(entradaItem?.valor),
        });
      }
    });
  }

  return arrayReturn;
}

function formatGraph2(saida, entrada) {
  const arrayReturn = [];
  if (saida?.length) {
    saida.forEach((saidaItem) => {
      if (arrayReturn.length) {
        arrayReturn.forEach((arrayretItem) => {
          if (arrayretItem.Dia === formatDateBR(saidaItem?.data_evento)) {
            if (arrayretItem.Saida) {
              arrayretItem.Saida += 1;
            } else {
              arrayretItem.Saida = 1;
            }
          }
        });
        const newArr = arrayReturn.filter(
          (arrayretItem) =>
            arrayretItem.Dia === formatDateBR(saidaItem?.data_evento)
        );
        if (!newArr.length) {
          arrayReturn.push({
            Dia: formatDateBR(saidaItem?.data_evento),
            Saida: 1,
          });
        }
      } else {
        arrayReturn.push({
          Dia: formatDateBR(saidaItem?.data_evento),
          Saida: 1,
        });
      }
    });
  }

  if (entrada?.length) {
    entrada.forEach((entradaItem) => {
      if (arrayReturn.length) {
        arrayReturn.forEach((arrayretItem) => {
          if (arrayretItem.Dia === formatDateBR(entradaItem?.data_evento)) {
            if (arrayretItem.Entrada) {
              arrayretItem.Entrada += 1;
            } else {
              arrayretItem.Entrada = 1;
            }
          }
        });
        const newArr = arrayReturn.filter(
          (arrayretItem) =>
            arrayretItem.Dia === formatDateBR(entradaItem?.data_evento)
        );
        if (!newArr.length) {
          arrayReturn.push({
            Dia: formatDateBR(entradaItem?.data_evento),
            Entrada: 1,
          });
        }
      } else {
        arrayReturn.push({
          Dia: formatDateBR(entradaItem?.data_evento),
          Entrada: 1,
        });
      }
    });
  }

  return arrayReturn;
}

function Dashboard() {
  const [dados, setDados] = useState(null);
  const [dadosGrafico, setDadosGrafico] = useState([]);
  const [dadosGrafico2, setDadosGrafico2] = useState([]);
  const formRef = useRef();

  async function getDados(datas) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await api.get(
        `/dashboard-saldo?startDate=${datas?.startDate}&endDate=${datas?.endDate}`
      );
      if (data) {
        setDados(data);
        setDadosGrafico(formatGraph(data?.volumeSaida, data?.volumeEntrada));
        setDadosGrafico2(
          formatGraph2(data?.volumesSaida, data?.volumesEntrada)
        );
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }
  useEffect(() => {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    const lastDay = new Date(y, m + 1, 0);
    formRef.current.setFieldValue('startDate', formatDate(firstDay));
    formRef.current.setFieldValue('endDate', formatDate(lastDay));
    getDados({ startDate: formatDate(firstDay), endDate: formatDate(lastDay) });
  }, []);

  return (
    <>
      <Form ref={formRef} onSubmit={(e) => getDados(e)}>
        <Row className="justify-start">
          <Col lg={7}>
            <h4 className="mt-2">Dashboard</h4>
          </Col>
          {/* <Col lg={2}>
            <Input type="date" name="startDate" className="form-control" />
          </Col>
          <Col lg={2}>
            <Input type="date" name="endDate" className="form-control" />
          </Col>
          <Col
            lg={1}
            className="justify-center pt-2"
            style={{ paddingBottom: '20px' }}
          >
            <Button color="primary" block>
              <FiSearch />
            </Button>
          </Col> */}
        </Row>
      </Form>
      <Row>
        <Col lg={12} md={6}>
          <Card>
            <div className="p-3">
              <h6 className="txt-muted text-center">Saldo Celcoin</h6>
              <h2 className="text-center">
                {dados?.saldoCelcoin
                  ? Number(dados?.saldoCelcoin)?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'R$ 0,00'}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={12} md={6}>
          <Card>
            <div className="p-3">
              <h6 className="txt-muted text-center">Saldo Total Contas</h6>
              <h2 className="text-center">
                {Number(dados?.saldoTotal?.valor)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </h2>
            </div>
          </Card>
        </Col>
        <Col lg={6} md={6}>
          <Card>
            <div className="p-3">
              <h6 className="txt-muted text-center">Saldo Total PF</h6>
              <h3 className="text-center">
                {Number(dados?.saldoPF?.valor)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </h3>
            </div>
          </Card>
        </Col>
        <Col lg={6} md={6}>
          <Card>
            <div className="p-3">
              <h6 className="txt-muted text-center">Saldo Total PJ</h6>
              <h3 className="text-center">
                {Number(dados?.saldoPJ?.valor)?.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </h3>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Card className="p-3">
        <Row>
          <Col lg={6} className="my-3">
            <Row>
              <Col lg={12}>
                <h5 className="txt-muted text-center">Transação por volume</h5>
              </Col>
              <Col
                style={{
                  minHeight: 310,
                }}
                lg={12}
              >
                <ResponsiveContainer>
                  <LineChart
                    data={dadosGrafico}
                    margin={{ top: 5, right: 20, left: 50, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Dia" />
                    <YAxis
                      tickFormatter={(tick) =>
                        tick.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      }
                    />
                    <Tooltip
                      formatter={(value) =>
                        value.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })
                      }
                    />
                    <Legend
                      payload={[
                        {
                          id: 'total',
                          type: 'rect',
                          value: `Total transações
                          ${
                            Number(dados?.volumeEntrada?.qtd) &&
                            Number(dados?.volumeSaida?.qtd)
                              ? Math.abs(Number(dados?.volumeEntrada?.qtd)) +
                                Math.abs(Number(dados?.volumeSaida?.qtd))
                              : 0
                          }`,
                          color: '#000',
                        },
                        {
                          id: 'Entrada',
                          type: 'line',
                          value: 'Entrada',
                          color: '#538DFF',
                        },
                        {
                          id: 'Saída',
                          type: 'line',
                          value: 'Saída',
                          color: '#ff0000',
                        },
                      ]}
                    />
                    <Line type="monotone" dataKey="Entrada" stroke="#538DFF" />
                    <Line type="monotone" dataKey="Saida" stroke="red" />
                  </LineChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Col>
          <Col lg={6} className="my-3">
            <Row>
              <Col lg={12}>
                <h5 className="txt-muted text-center">
                  Transação por quantidade
                </h5>
              </Col>
              <Col
                style={{
                  minHeight: 310,
                }}
                lg={12}
              >
                <ResponsiveContainer>
                  <LineChart
                    data={dadosGrafico2}
                    margin={{ top: 5, right: 30, left: 40, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Dia" />
                    <YAxis />
                    <Tooltip />
                    <Legend
                      payload={[
                        {
                          id: 'total',
                          type: 'rect',
                          value: `Total transações
                          ${
                            Number(dados?.volumeEntrada?.qtd) &&
                            Number(dados?.volumeSaida?.qtd)
                              ? Math.abs(Number(dados?.volumeEntrada?.qtd)) +
                                Math.abs(Number(dados?.volumeSaida?.qtd))
                              : 0
                          }`,
                          color: '#000',
                        },
                        {
                          id: 'Entrada',
                          type: 'line',
                          value: 'Entrada',
                          color: '#538DFF',
                        },
                        {
                          id: 'Saída',
                          type: 'line',
                          value: 'Saída',
                          color: '#ff0000',
                        },
                      ]}
                    />
                    <Line type="monotone" dataKey="Entrada" stroke="#538DFF" />
                    <Line type="monotone" dataKey="Saida" stroke="red" />
                  </LineChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card> */}
    </>
  );
}

export default Dashboard;
