import React from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';

// import { Container } from './styles';

function Dashboard() {
  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Dasboard</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link className="btn btn-primary" to="/criar" color="primary">
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input className="form-control" placeholder="nome" />
              </Col>
              <Col>
                <input className="form-control" placeholder="documento" />
              </Col>
              <Col>
                <input className="form-control" placeholder="email" />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Username</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Mark</td>
              <td>Otto</td>
              <td>@mdo</td>
            </tr>
            <tr>
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr>
            <tr>
              <td>Larry</td>
              <td>the Bird</td>
              <td>@twitter</td>
            </tr>
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(5) || 1}
          // onPageChange={({ selected }) => setPage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Dashboard;
