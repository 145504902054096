import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  Button,
  FormGroup,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { ReactSelectAsync } from '../../components/index';
import getOptions from '../../utils/getOptionsUneo';

import api from '../../services/api';

function ControleCaixa() {
  const formRef = useRef(null);
  const [borderos, setBorderos] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [estabelecimento, setEstabelecimento] = useState(null);

  const [estabelecimentos, setEstabelecimentos] = useState([]);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function pesquisaExtrato() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    try {
      const url = `/borderos-estabelecimento?estabelecimento=${estabelecimento}&${params}`;

      const { data } = await api.get(url);

      setBorderos(data);
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  async function carregaEstabelecimantos() {
    try {
      const { data } = await api.get('/estabelecimentos');

      setEstabelecimentos(data);
    } catch (err) {
      toast.error('Erro ao buscar estabelecimentos!');
    }
  }

  useEffect(() => {
    if (estabelecimento) {
      pesquisaExtrato();
    }
    carregaEstabelecimantos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro, estabelecimento]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Controle de caixa</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form ref={formRef} onSubmit={() => pesquisaExtrato()}>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <label>Estabelecimento</label>
                  <ReactSelectAsync
                    isClearable
                    loadOptions={async (value) => {
                      const data = await getOptions({
                        value,
                        route: '/estabelecimentos',
                      });
                      return data;
                    }}
                    defaultOptions={estabelecimentos}
                    placeholder=" "
                    onChange={(opt) => setEstabelecimento(opt?.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {estabelecimento && (
          <>
            <div className="p-3 justify-content-between d-flex align-items-center">
              <Col lg={6}>
                <h6 className="txt-muted">
                  Total de registros: {borderos.total}
                </h6>
              </Col>
              <Col>
                <Button
                  color="muted"
                  id="toggler"
                  className="d-flex align-items-center"
                >
                  <FiFilter size={20} />
                  &nbsp;&nbsp;Filtros
                </Button>
              </Col>
            </div>
            <div className="filter-collapse px-3 ">
              <UncontrolledCollapse toggler="#toggler" className="pb-3">
                <Row>
                  <Col lg={2}>
                    <label className="form-label">Data</label>
                    <input
                      onChange={(e) => debounceConsulta(e.target.value, 'data')}
                      className="form-control"
                      type="date"
                    />
                  </Col>
                  <Col lg={2}>
                    <label className="form-label">Status</label>
                    <select
                      className="form-control"
                      placeholder="Status"
                      name="status"
                      onChange={(e) =>
                        debounceConsulta(e.target.value, 'status')
                      }
                    >
                      <option value="">Todos os status</option>
                      <option value="Aberto">Aberto</option>
                      <option value="Fechado">Fechado</option>
                      <option value="Encerrado">Encerrado</option>
                    </select>
                  </Col>
                </Row>
              </UncontrolledCollapse>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Usuário</th>
                  <th>Abertura</th>
                  <th>Fechamento</th>
                  <th>Encerramento</th>
                  <th>Sistema</th>
                  <th>Operador</th>
                  <th>Dinheiro</th>
                  <th>Pix</th>
                  <th>Ap inicial</th>
                  <th>Apfinal</th>
                  <th>Diferença</th>
                  <th>Consolidado</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {borderos.borderos?.length ? (
                  borderos.borderos?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>{tar?.Usuario?.nome}</td>
                      <td>
                        {tar?.data_abertura &&
                          new Date(tar?.data_abertura)?.toLocaleString()}
                      </td>
                      <td>
                        {tar?.data_fechamento &&
                          new Date(tar?.data_fechamento)?.toLocaleString()}
                      </td>
                      <td>
                        {tar?.data_encerramento &&
                          new Date(tar?.data_encerramento)?.toLocaleString()}
                      </td>
                      <td>
                        {Number(tar?.valor_sistema)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_operador)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_dinheiro)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_pix)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.aporte_inicial)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.aporte_final)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.diferenca)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{tar?.consolidacao ? 'Sim' : 'Não'}</td>
                      <td>{tar?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={borderos.pages || 1}
              onPageChange={({ selected }) =>
                setFiltro({ ...filtro, page: selected + 1 })
              }
              initialPage={0}
              containerClassName="pagination justify-end mr-1"
              activeClassName="active"
            />
          </>
        )}
      </Card>
    </>
  );
}

export default ControleCaixa;
