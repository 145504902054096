// import React from 'react';
// import {
//   FaFilter,
//   FaSearch,
//   FaTable,
//   FaFileExcel,
//   FaPrint,
//   FaFilePdf,
// } from 'react-icons/fa';
// import { Form } from '@unform/web';
// import Swal from 'sweetalert2';
// import {
//   Card,
//   CardBody,
//   CardText,
//   Col,
//   Row,
//   Table,
//   Button,
//   UncontrolledCollapse,
//   UncontrolledButtonDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from 'reactstrap';

// import api from '../../services/api';
// import CustomInput from '../../components/unform/Input/Input';
// import CustomSelect from '../../components/SearchSelect/CustomSelect';
// import AsyncSelect from '../../components/SearchSelect/Async';
// import exportFromJSON from 'export-from-json';
// import { toast } from 'react-toastify';

// const options = [
//   {
//     value: 'dataPag',
//     label: 'Data de Pagamento',
//   },
//   {
//     value: 'identificacao',
//     label: 'Identificação',
//   },
//   {
//     value: 'nmrParcela',
//     label: 'Parcela',
//   },
//   {
//     value: 'valorParcela',
//     label: 'Valor',
//   },
//   {
//     value: 'endereco',
//     label: 'Endereço',
//   },
//   {
//     value: 'cliente',
//     label: 'Nome/Razão',
//   },
//   {
//     value: 'cpf/cnpj',
//     label: 'CPF/CNPJ',
//   },
//   {
//     value: 'email',
//     label: 'E-mail',
//   },
// ];

// const RelatorioLancamentos = () => {
//   const [parcelas, setParcelas] = React.useState([]);
//   const [filtros, setFiltros] = React.useState(null);
//   const [campos, setCampos] = React.useState(options);
//   const [registros, setregistros] = React.useState(0);
//   const [page, setPage] = React.useState(1);
//   const formRef = React.useRef(null);

//   let select;

//   const optionsClientes = async (inputValue) => {
//     return await new Promise((resolve) => {
//       clearTimeout(select);

//       select = setTimeout(() => {
//         resolve(filtrarConsulta(inputValue));
//       }, 500);
//     });
//   };

//   async function filtrarConsulta(inputValue) {
//     const result = await api.get(`generica/contato?param=${inputValue}`);

//     return result.data;
//   }

//   async function handleSubmit({ campos: c, ...body }) {
//     formRef.current.setErrors({});
//     const erros = {};
//     if (!c.length) {
//       erros.campos = 'Informe ao menos um campo!';
//     }
//     if (Object.entries(erros).length) {
//       return formRef.current.setErrors(erros);
//     }
//     Swal.fire({
//       allowOutsideClick: false,
//       onBeforeOpen: () => {
//         Swal.showLoading();
//       },
//     });
//     try {
//       const { data, headers } = await api.post(`/relatorio/lancamentos`, {
//         filtros: body,
//         campos: c,
//       });
//       const { 'x-total-count': t } = headers;
//       setregistros(t);
//       if (page > 1) {
//         setPage(1);
//       }
//       setFiltros(body);
//       setCampos(c.map((m) => options.find((f) => f.value == m)));
//       setParcelas(data);
//       Swal.close();
//     } catch ({ response }) {
//       Swal.close();
//       if (response.data?.error) {
//         toast.error(response.data.error);
//       }
//     }
//   }

//   async function handleMoreItens() {
//     if (registros > parcelas.length) {
//       const { data } = await api.post(`/relatorio/lancamentos?page=${page + 1}`, {
//         filtros,
//         campos: campos.map((m) => m.value),
//       });
//       setPage(page + 1);
//       if (data) {
//         setParcelas([...parcelas, ...data]);
//       }
//     }
//   }

//   return (
//     <>
//       <HeaderMain
//         title='Relatório de fechamento'
//         className='mb-3 mt-3 print-hidden'
//       />
//       <Card className='mb-3'>
//         <CardBody>
//           <Row className='justify-end print-hidden'>
//             {parcelas.length > 0 && (
//               <UncontrolledButtonDropdown
//                 style={{
//                   marginBottom: -25,
//                   marginRight: 15,
//                   zIndex: 2,
//                   borderBottomRightRadius: 3,
//                   borderTopRightRadius: 3,
//                 }}
//               >
//                 <DropdownToggle
//                   color='link'
//                   style={{
//                     borderBottomRightRadius: 3,
//                     borderTopRightRadius: 3,
//                     boxShadow: '0 0 10px #0000001f',
//                   }}
//                 >
//                   <FaPrint /> Salvar
//                 </DropdownToggle>
//                 <DropdownMenu right>
//                   <DropdownItem
//                     onClick={() => {
//                       if (parcelas) {
//                         const fileName = 'DespesasReceitas';
//                         exportFromJSON({
//                           data: parcelas,
//                           fileName,
//                           exportType: exportFromJSON.types.xls,
//                         });
//                       }
//                     }}
//                     className='color-success'
//                   >
//                     <FaFileExcel /> XLS
//                   </DropdownItem>
//                   <DropdownItem
//                     onClick={() => window.print()}
//                     className='color-danger'
//                   >
//                     <FaFilePdf /> PDF
//                   </DropdownItem>
//                 </DropdownMenu>
//               </UncontrolledButtonDropdown>
//             )}
//             <Col lg={12} className='mb-1'>
//               <Button
//                 className='accordin-toggle jutify-between d-flex w-100 '
//                 color='link'
//                 id='toggler'
//               >
//                 <div
//                   className='w-100 d-flex justify-between'
//                   style={{ marginLeft: '-14px' }}
//                 >
//                   <div>
//                     {parcelas.length > 0 && (
//                       <CardText>
//                         Exibindo {parcelas.length} de {registros}
//                       </CardText>
//                     )}
//                   </div>
//                   <div>Configurações do relatório</div>
//                   <div style={{ width: 55 }}></div>
//                 </div>
//               </Button>
//               <UncontrolledCollapse toggler='#toggler' className='toggle-bg'>
//                 <Form onSubmit={handleSubmit} ref={formRef}>
//                   <Row className='px-1'>
//                     <Col lg={12}>
//                       <strong>
//                         Filtros <FaFilter />
//                       </strong>
//                     </Col>
//                     <Col lg={5} md={7} sm={12}>
//                       <Row>
//                         <Col lg={6} md={6} sm={4} xs={6}>
//                           Data pagamento inicial
//                           <CustomInput type='date' name='data_inicio' />
//                         </Col>
//                         <Col lg={6} md={6} sm={4} xs={6}>
//                           Data pagamento final
//                           <CustomInput type='date' name='data_fim' />
//                         </Col>
//                       </Row>
//                     </Col>
//                     <Col lg={2} sm={3} xs={6}>
//                       Tipo
//                       <CustomInput type='select' name='controletipolancamento'>
//                         <option value='R'>Receita</option>
//                         <option value='D'>Depesa</option>
//                         <option value=''>Todos</option>
//                       </CustomInput>
//                     </Col>
//                     <Col lg={5} sm={4}>
//                       Cliente
//                       <AsyncSelect
//                         name='cliente'
//                         isClearable
//                         loadOptions={optionsClientes}
//                       />
//                     </Col>
//                   </Row>

//                   <Row className='px-1 pb-2'>
//                     <Col lg={12}>
//                       <strong>
//                         Campos <FaTable />
//                       </strong>
//                     </Col>
//                     <Col lg={6} md={8} sm={12}>
//                       <CustomSelect
//                         closeMenuOnSelect={false}
//                         isMulti
//                         name='campos'
//                         defaultValue={[
//                           options[0],
//                           options[1],
//                           options[2],
//                           options[3],
//                           options[4],
//                           options[5],
//                           options[6],
//                           options[7],
//                         ]}
//                         options={options}
//                       />
//                     </Col>
//                     <Col>
//                       <Button color='primary' type='submit'>
//                         Consultar <FaSearch />
//                       </Button>
//                     </Col>
//                   </Row>
//                 </Form>
//               </UncontrolledCollapse>
//             </Col>
//           </Row>
//           {parcelas.length ? (
//             <div className='relatorio-table'>
//               <Table className='mb-0 rwd-table'>
//                 <thead>
//                   <tr>
//                     {campos.map((e, i) => (
//                       <th scope='col' className='bt-0 t-left' key={i}>
//                         {e.label}
//                       </th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {parcelas.map((processo, i) => (
//                     <tr key={i}>
//                       {campos.map((f, i2) => (
//                         <td
//                           data-label={f.label}
//                           className='t-left'
//                           key={i2}
//                           style={{ fontSize: 12 }}
//                         >
//                           {processo[f.label]}
//                         </td>
//                       ))}
//                     </tr>
//                   ))}
//                 </tbody>
//                 {parcelas.length < registros && (
//                   <tfoot className='print-hidden'>
//                     <tr>
//                       <td colSpan={campos.length}>
//                         <Button
//                           color='info'
//                           className='w-100'
//                           onClick={handleMoreItens}
//                         >
//                           Carregar mais
//                         </Button>
//                       </td>
//                     </tr>
//                   </tfoot>
//                 )}
//               </Table>
//             </div>
//           ) : (
//             <div className='w-100 my-3 d-flex justify-content-center'>
//               Edite as configurações para filtrar e consultar
//             </div>
//           )}
//         </CardBody>
//       </Card>
//     </>
//   );
// };

// export default RelatorioLancamentos;
