/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import api from '../../services/api';

// import { Container } from './styles';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});
function Cartoes() {
  let timeoutConsulta;
  const [cartoes, setCartoes] = useState([]);
  const [page, setPage] = useState(0);
  const [pagination, setPagination] = useState(0);
  const [filtros, setFiltros] = useState(null);

  const [total, setTotal] = useState(0);

  const refResponsavel = useRef(null);
  const refConta = useRef(null);

  const debounceConsulta = async (body) =>
    await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });

  useEffect(() => {
    async function getCards() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        let filtroConsulta = '';
        if (filtros) {
          Object.entries(filtros).forEach(([key, value]) => {
            if (['string', 'number'].includes(typeof value)) {
              filtroConsulta += `${key}=${value}&`;
            } else if (value) {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          });
        }
        const { data } = await api.get(
          `/cartoes-backoffice?page=${page + 1}&${filtroConsulta}`
        );
        if (data?.cartoes) {
          setCartoes(data?.cartoes);
          setPagination(data?.pages);
          setTotal(data?.total);
        }
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar cartões!');
        Swal.close();
      }
    }
    getCards();
  }, [page, filtros]);

  async function bloqueiaCartao(proxy) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja cancelar este cartão?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .patch(`/bloqueiocartoes/${proxy}`)
            .then(() => {
              const cartoesArray = [];
              cartoes.forEach((c) => {
                if (c.proxy === proxy) {
                  c.status = 'Cancelado pela UneoBank';
                }
                cartoesArray.push(c);
              });
              setCartoes(cartoesArray);
              Swal.close();
              toast.success('Cartão cancelado com sucesso');
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Cartões</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3  d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted align-items-center">
              Total de registros: {total}
            </h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Responsável"
                  ref={refResponsavel}
                  onChange={() =>
                    debounceConsulta({
                      responsavel: refResponsavel?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="Conta"
                  ref={refConta}
                  onChange={() =>
                    debounceConsulta({
                      conta: refConta?.current?.value,
                    })
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Responsável</th>
              <th>Proxy</th>
              <th>Codigo ativação</th>
              <th className="text-center">Conta</th>
              <th className="text-center">Virtual</th>
              <th className="text-center">Contactless</th>
              <th>Status</th>
              <th className="justify-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {cartoes?.length ? (
              cartoes?.map((car) => (
                <tr key={car?.id}>
                  <td>{car?.Contum?.Pessoa?.nome_completo}</td>
                  <td>{car?.proxy}</td>
                  <td>{car?.activate_code}</td>
                  <td className="text-center">{car?.Contum?.numero_conta}</td>
                  <td className="text-center">
                    {car?.virtual === true ? 'Sim' : 'Não'}
                  </td>
                  <td className="text-center">
                    {car?.contactless === true ? 'Sim' : 'Não'}
                  </td>
                  <td>{car?.status}</td>
                  <td className="justify-center">
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          size="xs"
                          onClick={() => bloqueiaCartao(car?.proxy)}
                          disabled={car?.status === 'Cancelado pela UneoBank'}
                        >
                          Cancelar cartão
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pagination || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Cartoes;
