import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { FaArrowCircleRight, FaFileExport, FaFileImport } from 'react-icons/fa';
import EmailEditor from 'react-email-editor';
import { FileUploader } from 'react-drag-drop-files';
import fileDownload from 'js-file-download';
import { Radio, Input } from '../../components/unform/index';

import api from '../../services/api';

const fileTypes = ['JSON'];

function MalaDireta() {
  const formRef = useRef(null);
  const formRefEnviar = useRef(null);

  const [isReady, setIsReady] = useState(false);
  const [isTodos, setIsTodos] = useState('T');

  const [html, setHtml] = useState('');

  const [modal, setModal] = useState(false);
  const [modalEnviar, setModalEnviar] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleModalEnviar = () => setModalEnviar(!modalEnviar);

  const saveDesign = () => {
    formRef.current.editor.saveDesign((data) => {
      fileDownload(JSON.stringify(data), 'template-email.json');
    });
  };

  const handleChange = (file) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      formRef.current.editor.loadDesign(JSON.parse(reader.result));
      toggleModal();
    };
  };

  const onReady = () => {
    setIsReady(true);
  };

  async function handleSubmit(data) {
    formRefEnviar.current.setErrors({});
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const errors = {};

      Object.keys(data).forEach((f) => {
        if (!data[f]) {
          if (f === 'email' && data.todos !== 'T') {
            errors[f] = 'Obrigatório';
          } else if (f !== 'email') {
            errors[f] = 'Obrigatório';
          }
        }
      });

      formRefEnviar.current.setErrors(errors);

      if (!Object.keys(errors).length) {
        data.html = html;
        await api.post('/mala-direta', data);
        toast.success('Enviado com sucesso!');

        toggleModalEnviar();
        setIsTodos('T');
      }
      Swal.close();
    } catch (err) {
      toast.error('Erro ao salvar dados!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Mala Direta</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div>
            {isReady && (
              <div className="btn-toolbar">
                <div className="left-group">
                  <Button type="button" onClick={saveDesign} color="tertiary">
                    <FaFileExport /> Exportar template
                  </Button>{' '}
                  <Button type="button" onClick={toggleModal} color="tertiary">
                    <FaFileImport /> Importar template
                  </Button>{' '}
                </div>
                <div className="right-group">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      toggleModalEnviar();
                      formRef.current.editor.exportHtml((editor) => {
                        const { html: editorHtml } = editor;
                        setHtml(String(editorHtml));
                      });
                    }}
                  >
                    <FaArrowCircleRight /> Enviar email
                  </Button>
                </div>
              </div>
            )}

            <EmailEditor ref={formRef} onReady={onReady} locale="pt-BR" />
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Importar template</ModalHeader>
        <ModalBody>
          <FileUploader
            handleChange={handleChange}
            name="template"
            types={fileTypes}
            label="Importe aqui o template"
            hoverTitle="Jogue aqui o arquivo"
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modalEnviar} toggle={toggleModalEnviar} size="lg">
        <ModalHeader toggle={toggleModalEnviar}>Enviar email</ModalHeader>
        <Form
          ref={formRefEnviar}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <Input
                label="Assunto"
                name="assunto"
                className="form-control"
                type="text"
              />
            </Row>
            <Row>
              <Radio
                name="todos"
                label="Para quem deseja enviar?"
                className="radio"
                defaultValue="T"
                onChange={(e) => {
                  setIsTodos(e.target.value);
                }}
                data={[
                  { id: 'T', label: 'Todos os clientes', value: 'T' },
                  { id: 'S', label: 'Digitar email', value: 'S' },
                ]}
              />
            </Row>
            <Row hidden={isTodos === 'T'}>
              <Input
                label="Email"
                name="email"
                className="form-control"
                type="text"
                placeholder="Caso seja mais de um e-mail separe por ; (ponto e vigula) sem espaços em branco."
              />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Enviar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default MalaDireta;
