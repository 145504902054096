import React, { useEffect, useState, useRef } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { FaEllipsisH, FaSearch } from 'react-icons/fa';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import api from '../../services/api';
import ComprovanteResumido from './ComprovanteResumido';
import { InputGroup } from '../../components/unform/index';

function Extrato() {
  const formRef = useRef(null);
  const [extrato, setExtrato] = useState([]);
  const [modal, setModal] = useState(false);
  const [saldoConta, setSaldoCOnta] = useState(0);
  const [selectedItem, setSelectedItem] = useState({});
  const [ano, setAno] = useState(new Date().getFullYear());
  const [mes, setMes] = useState(new Date().getMonth() + 1);

  const [documento, setDocumento] = useState(null);

  const toggle = () => setModal(!modal);

  const incrementarAno = () => {
    setAno(ano + 1);
  };

  const decrementarAno = () => {
    setAno(ano - 1);
  };

  async function getSdaldoConta(e) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await api.get(`/saldo-cliente/${e}`);
      setSaldoCOnta(data);
    } catch (err) {
      toast.error('Erro ao buscar infos da conta!');
    } finally {
      Swal.close();
    }
  }

  async function getExtrato(e) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      // Obter o dia inicial do mês atual
      const firstDayOfMonth = new Date(ano, mes - 1, 1);
      const initialDay = firstDayOfMonth.getDate();

      // Obter o dia final do mês atual
      const lastDayOfMonth = new Date(ano, mes, 0);
      const finalDay = lastDayOfMonth.getDate();

      const body = {
        diainicial: initialDay,
        diafinal: finalDay,
        mes,
        ano,
      };
      const { data } = await api.post(`/extrato-clientes/${e}`, body);
      setExtrato(data);
      window.scrollTo(0, 0);

      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar extrato!');
      Swal.close();
    }
  }

  async function verInfos(
    codigo,
    codigoTransacao,
    origemTransacao,
    tipolancamento,
    extratoItem
  ) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      if (tipolancamento === 'D') {
        const body = {
          codigo,
          origem_transacao: origemTransacao,
          codigo_transacao: codigoTransacao,
        };

        const { data } = await api.post(
          '/comprovante-info-pj-backoffice',
          body
        );

        if (data) {
          data.comprovante.tipo = 'D';
          setSelectedItem(data);
          Swal.close();
        }
      } else {
        const comprovante = {
          comprovante: {
            documentoFederal: extratoItem?.documentoFederal,
            nome: extratoItem?.nome,
            tipo: 'C',
            vlrTransacao: extratoItem?.vlrMovimento,
            codigoTransacao,
          },
        };
        setSelectedItem(comprovante);
        Swal.close();
      }
      toggle();
    } catch (err) {
      toast.error('Erro ao buscar informações do extrato!');
      Swal.close();
    }
  }

  async function handleSubmit(e) {
    setDocumento(e?.documento);
    getExtrato(e?.documento);
    getSdaldoConta(e?.documento);
  }

  return (
    <>
      <Row>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Row>
            <Col>
              <InputGroup
                name="documento"
                label="Documento"
                type="number"
                className="form-control-group"
                placeholder="Digite o numero do documento para ver o extrato"
                append={
                  <Button size="sm" color="link" type="submit">
                    <FaSearch />
                  </Button>
                }
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <Row hidden={!saldoConta}>
        <Col>
          <h4 className="mb-3">Infos</h4>
        </Col>
      </Row>
      <Row hidden={!saldoConta}>
        <Col>
          <Card>
            <Row>
              <Col>
                <div className="p-3">
                  <h6 className="txt-muted text-center">Conta</h6>
                  <h5 className="text-center">
                    N° {saldoConta?.conta?.['Conta.Conta']}{' '}
                    {saldoConta?.conta?.['Conta.ContaDigito']} - Ag{' '}
                    {saldoConta?.conta?.['Conta.Agencia']} - Banco 274
                  </h5>
                </div>
              </Col>
              <Col>
                <div className="p-3">
                  <h6 className="txt-muted text-center">Saldo</h6>
                  <h2 className="text-center">
                    {Number(saldoConta?.saldo?.vlrSaldo || 0).toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </h2>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4 className="mb-3">Extrato</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 btn-toolbar">
          <Col lg={2}>
            <select
              defaultValue={mes}
              onChange={(e) => setMes(e?.target?.value)}
              className="form-control"
            >
              <option value="1">Janeiro</option>
              <option value="2">Fevereiro</option>
              <option value="3">Março</option>
              <option value="4">Abril</option>
              <option value="5">Maio</option>
              <option value="6">Junho</option>
              <option value="7">Julho</option>
              <option value="8">Agosto</option>
              <option value="9">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </select>
          </Col>
          <Col lg={1}>
            <div className="ano-component-div">
              <Button color="" type="button" onClick={decrementarAno}>
                &#8592;
              </Button>
              <h4 className="mt-1 ml-0">{ano}</h4>
              <Button color="" type="button" onClick={incrementarAno}>
                &#8594;
              </Button>
            </div>
          </Col>
          <Col lg={2} className="right-group">
            <Button
              color=""
              id="toggler"
              onClick={() => {
                getExtrato(documento);
                getSdaldoConta(documento);
              }}
            >
              <FiRefreshCcw size={20} className="mr-3" /> Atualizar ou pesquisar
            </Button>
          </Col>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Tipo</th>
              <th>Valor</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {extrato?.movimentos?.length ? (
              extrato?.movimentos
                ?.sort(
                  (a, b) => new Date(b.dtMovimento) - new Date(a.dtMovimento)
                )
                ?.filter(
                  (mv) =>
                    mv.vlrMovimento > 0 &&
                    !mv.descricaoOperacao.includes('SALDO')
                )
                ?.map((c) => (
                  <tr key={c?.id}>
                    <td>{new Date(c?.dtMovimento)?.toLocaleString()}</td>
                    <td>{c?.descricaoOperacao}</td>
                    <td
                      style={{
                        color: c?.tipoLancamento === 'D' ? 'red' : 'green',
                      }}
                    >
                      <Row>
                        <Col lg={1}>
                          {c?.tipoLancamento === 'D' ? <p>-</p> : <p>+</p>}
                        </Col>
                        <Col>
                          {Number(c?.vlrMovimento || 0).toLocaleString(
                            'pt-BR',
                            {
                              style: 'currency',
                              currency: 'BRL',
                            }
                          )}
                        </Col>
                      </Row>
                    </td>
                    <td className="text-center">
                      <UncontrolledDropdown size="xs">
                        <DropdownToggle color="link link-gray" size="xs">
                          <FaEllipsisH size="1.3em" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => {
                              verInfos(
                                c?.codigo,
                                c?.codigoTransacao,
                                c?.origemTransacao,
                                c?.tipoLancamento,
                                c
                              );
                            }}
                            size="xs"
                          >
                            Ver informações
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Infos / Comprovante</ModalHeader>
        <ModalBody>
          <ComprovanteResumido comprovante={selectedItem?.comprovante} />
        </ModalBody>
      </Modal>
    </>
  );
}

export default Extrato;
