/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { FaArrowUp } from 'react-icons/fa';

import { useAuth } from '../../contexts/auth';

import { ReactComponent as LogoIcon } from '../../assets/svg/logo-icon.svg';
import { ReactComponent as Uneocard } from '../../assets/svg/uneocard.svg';
import { ReactComponent as Finance } from '../../assets/svg/finance.svg';
import { ReactComponent as Correspondente } from '../../assets/svg/caixa-eletronico.svg';
import { ReactComponent as Pay } from '../../assets/svg/payment-method.svg';

function Modulo() {
  const { setModulo } = useAuth();

  return (
    <div className="d-flex bg-gray0 min-100h modulo-container">
      <div className="acordeon">
        <div className="celda" onClick={() => setModulo('UB')}>
          <p>UNEOBANK</p>
          <LogoIcon
            className="white-img"
            style={{
              height: 100,
              width: 90,
            }}
          />
          <span>
            <FaArrowUp size={50} />
            <br />
            Acessar módulo
          </span>
        </div>
        <div className="celda" onClick={() => setModulo('UC')}>
          <p>UNEO CRÉDITO</p>
          <Uneocard
            className="white-img"
            style={{
              height: 130,
              width: 120,
            }}
          />
          <span>
            <FaArrowUp size={50} />
            <br />
            Acessar módulo
          </span>
        </div>
        <div className="celda" onClick={() => setModulo('UF')}>
          <p>UNEO FINANÇAS</p>
          <Finance
            className="white-img"
            style={{
              height: 80,
              width: 70,
            }}
          />
          <span>
            <FaArrowUp size={50} />
            <br />
            Acessar módulo
          </span>
        </div>

        <div className="celda" onClick={() => setModulo('CO')}>
          <p>UNEO CORRESPONDENTE</p>
          <Correspondente
            className="white-img"
            style={{
              height: 80,
              width: 70,
            }}
          />
          <span>
            <FaArrowUp size={50} />
            <br />
            Acessar módulo
          </span>
        </div>

        <div className="celda" onClick={() => setModulo('PC')}>
          <p>Uneo Green</p>
          <Pay
            className="white-img"
            style={{
              height: 80,
              width: 70,
            }}
          />
          <span>
            <FaArrowUp size={50} />
            <br />
            Acessar módulo
          </span>
        </div>
      </div>
    </div>
  );
}

export default Modulo;
