import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Input from '../../components/unform/input';

function SimularTaxas() {
  const [creditoList, setCreditoList] = useState([]);
  const [modal, setModal] = useState(false);
  const formRef = useRef();
  const toggleModal = () => setModal((prev) => !prev);
  const parcelaMaxima = creditoList[creditoList?.length]?.parcela;

  useEffect(() => {
    async function getConta() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const { data } = await api.get('/taxas-emprestimo');

        setCreditoList(data);
        window.scrollTo(0, 0);

        Swal.close();
      } catch (err) {
        Swal.close();
        toast.error(err?.response?.data?.err || 'Ocorreu um erro');
      }
    }
    getConta();
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Simular taxas cartão de crédito</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {creditoList.total}</h6>

          <Button color="primary" onClick={toggleModal}>
            Simular
          </Button>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th
                style={{
                  width: 100,
                }}
              >
                Parcela
              </th>
              <th>Taxa</th>
            </tr>
          </thead>
          <tbody>
            {creditoList?.length ? (
              creditoList?.map((c) => (
                <tr key={c?.id}>
                  <td>{c?.parcela}</td>
                  <td>{Number(c?.taxa_total).toFixed(2)}%</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Simulador</ModalHeader>

        <Form
          ref={formRef}
          onSubmit={(e) => {
            if (Number(e.parcelas) > parcelaMaxima) {
              return toast.error(`Parcela deve ser até ${parcelaMaxima}`);
            }

            const taxa = creditoList.find(
              (f) => f.parcela === Number(e.parcelas)
            )?.taxa_total;
            const total = Number((Number(taxa) / 100 + 1) * Number(e.valor));

            formRef.current.setFieldValue(
              'valor_parcela',
              (total / Number(e.parcelas)).toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })
            );
            formRef.current.setFieldValue(
              'valor_final',
              Number(total).toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })
            );
          }}
        >
          <ModalBody>
            <Row>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor"
                  label="Valor"
                  className="form-control"
                  placeholder="0,00"
                  step={0.01}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="parcelas"
                  label="Parcelas"
                  className="form-control"
                  defaultValue={1}
                  step={1}
                  min={1}
                  max={parcelaMaxima}
                  type="number"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor_final"
                  label="Valor total final"
                  className="form-control"
                  placeholder="0,00"
                  disabled
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="valor_parcela"
                  label="Valor por parcela"
                  className="form-control"
                  placeholder="0,00"
                  disabled
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Calcular</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default SimularTaxas;
