/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardBody,
  CardHeader,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { FaEllipsisH } from 'react-icons/fa';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function TarifaTransacao() {
  const formRef = useRef();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    title: 'Pagamento',
    filter: 'pagamento',
  });

  const [modal, setModal] = useState(false);

  const [taxas, setTaxas] = useState([]);

  const [selected, setSelected] = useState(null);

  const toggleModal = (tipo) => setModal({ open: !modal?.open, tipo });

  async function getTaxas() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const { data } = await api.get('/auto-atendimento-taxas');
      if (data) {
        setTaxas(data);
      }
      window.scrollTo(0, 0);
    } catch (err) {
      toast.error('Erro ao buscar planos!');
    } finally {
      Swal.close();
    }
  }

  useEffect(() => {
    getTaxas();
  }, []);

  async function handleSubmit(data) {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        if (modal?.tipo === 'new') {
          await api.post('/auto-atendimento-taxas', data);
        } else {
          await api.put(`/auto-atendimento-taxas/${selected?.id}`, data);
        }
        toast.success('Taxa criada com sucesso');
        getTaxas();
        setSelected(null);
        toggleModal();
      } catch (err) {
        toast.error('Erro ao salvar taxa');
      } finally {
        Swal.close();
      }
    }
  }

  const toggle = (tab, title, filter) => {
    if (activeTab?.id !== tab) {
      setActiveTab({
        id: tab,
        title,
        filter,
      });
    }
  };

  async function deleteTaxa(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir essa taxa?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/auto-atendimento-taxas/${id}`)
            .then(() => {
              getTaxas();
              Swal.close();
              toast.success('Taxa excluida com sucesso');
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Taxas</h4>
        </Col>
      </Row>
      <Card className="px-3 py-3">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab?.id === '1' && 'active'}
              onClick={() => {
                toggle('1', 'Pagamento', 'pagamento');
              }}
            >
              Pagamento
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab?.id === '2' && 'active'}
              onClick={() => {
                toggle('2', 'Empréstimo', 'emprestimo');
              }}
            >
              Empréstimo
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab?.id === '3' && 'active'}
              onClick={() => {
                toggle('3', 'Recarga / Gift', 'recarga');
              }}
            >
              Recarga / Gift
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={activeTab?.id === '4' && 'active'}
              onClick={() => {
                toggle('4', 'Consulta veicular', 'consulta-veicular');
              }}
            >
              Consulta veicular
            </NavLink>
          </NavItem> */}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row>
              <Col>
                <Card>
                  <CardHeader className="text-center">
                    {activeTab?.title}
                  </CardHeader>
                  <CardBody>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th className="text-center">Parcela</th>
                          <th className="text-center">Taxa operadora</th>
                          <th className="text-center">Taxa uneobank</th>
                          <th className="text-center">Taxa total</th>
                          <th className="text-center">Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {taxas.length ? (
                          taxas
                            ?.filter((a) => a.tipo === activeTab?.filter)
                            ?.sort((a, b) => {
                              if (a.tipo_pagamento < b.tipo_pagamento) {
                                return 1;
                              }
                              if (a.tipo_pagamento > b.tipo_pagamento) {
                                return -1;
                              }
                              return a.parcela - b.parcela;
                            })
                            ?.map((tar) => (
                              <tr key={tar?.id}>
                                <td>
                                  {tar?.tipo_pagamento === 'credito' ? (
                                    <span
                                      style={{
                                        backgroundColor: '#FFE787',
                                        padding: '5px',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      Crédito
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        backgroundColor: '#A1E8AF',
                                        padding: '5px',
                                        borderRadius: '10px',
                                      }}
                                    >
                                      Débito
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">{tar?.parcela}</td>
                                <td className="text-center">
                                  {Number(
                                    tar?.taxa_transacao_operadora
                                  )?.toFixed(2)}{' '}
                                  %
                                </td>
                                <td className="text-center">
                                  {Number(tar?.taxa_uneobank)?.toFixed(2)} %
                                </td>
                                <td className="text-center">
                                  {Number(tar?.taxa_total)?.toFixed(2)} %
                                </td>
                                <td className="text-center">
                                  <UncontrolledDropdown size="xs">
                                    <DropdownToggle
                                      color="link link-gray"
                                      size="xs"
                                    >
                                      <FaEllipsisH size="1.3em" />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem
                                        onClick={() => {
                                          toggleModal('edit');
                                          setSelected(tar);
                                        }}
                                        size="xs"
                                      >
                                        Editar
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => deleteTaxa(tar?.id)}
                                        size="xs"
                                      >
                                        Excluir
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td>Sem registros encontrados</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <CardFooter>
          <Row>
            <Col className="justify-end">
              <Button color="primary" onClick={() => toggleModal('new')}>
                Nova taxa
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
      <Modal
        isOpen={modal?.open}
        toggle={() => {
          toggleModal();
          setSelected(null);
        }}
        size="md"
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
            setSelected(null);
          }}
        >
          {modal?.tipo === 'new' ? 'Nova' : 'Editar'} taxa
        </ModalHeader>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col>
                <Select
                  name="tipo"
                  label="Tipo"
                  className="form-control"
                  defaultValue={selected?.tipo}
                  data={[
                    { label: 'Pagamento', value: 'pagamento' },
                    { label: 'Empréstimo', value: 'emprestimo' },
                    { label: 'Recarga / Gift', value: 'recarga' },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  name="tipo_pagamento"
                  label="Tipo pagamento"
                  className="form-control"
                  defaultValue={selected?.tipo_pagamento}
                  data={[
                    { label: 'Débito', value: 'debito' },
                    { label: 'Crédito', value: 'credito' },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="parcela"
                  label="Pacela"
                  defaultValue={selected?.parcela}
                  className="form-control"
                  type="number"
                />
              </Col>
              <Col>
                <Input
                  name="taxa_transacao_operadora"
                  label="Taxa operadora"
                  defaultValue={selected?.taxa_transacao_operadora}
                  className="form-control"
                  type="number"
                  step="any"
                />
              </Col>
              <Col>
                <Input
                  name="taxa_uneobank"
                  label="Taxa UneoBank"
                  defaultValue={selected?.taxa_uneobank}
                  className="form-control"
                  type="number"
                  step="any"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>
            <Button
              color="secondary"
              toggle={() => {
                toggleModal();
                setSelected(null);
              }}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default TarifaTransacao;
