import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    razao_social: Yup.string().required('Obrigatório'),
    nome_fantasia: Yup.string().required('Obrigatório'),
    cnpj: Yup.string().required('Obrigatório'),
    data_contratacao: Yup.string().required('Obrigatório'),
    telefone: Yup.string().required('Obrigatório'),
    logradouro: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    cidade: Yup.string().required('Obrigatório'),
    estado: Yup.string().required('Obrigatório'),
    cep: Yup.string().required('Obrigatório'),
    email: Yup.string().required('Obrigatório'),
    finalidade_parceria: Yup.string().required('Obrigatório'),

    socio_responsavel: Yup.string().required('Obrigatório'),
    socio_cpf: Yup.string().required('Obrigatório'),
    socio_logradouro: Yup.string().required('Obrigatório'),
    socio_numero: Yup.string().required('Obrigatório'),
    socio_cep: Yup.string().required('Obrigatório'),
    socio_cidade: Yup.string().required('Obrigatório'),
    socio_estado: Yup.string().required('Obrigatório'),
    socio_data_nascimento: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
