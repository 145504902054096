/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaChartBar } from 'react-icons/fa';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  ReferenceLine,
} from 'recharts';
import {
  Card,
  CardBody,
  Row,
  Table,
  Label,
  Input,
  FormGroup,
  Col,
  CardHeader,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import api from '../../services/apiFinanceiro';

const nomeMes = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Mai',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
};

const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ratio1 = window.screen.width > 768 ? 5.0 / 1.5 : 1;
const ratio2 = window.screen.width > 768 ? 10.0 / 2.0 : 1;

const Planejamento = () => {
  const [ano, setAno] = React.useState(new Date().getFullYear());
  const [planejamento, setPlanejamento] = React.useState(null);

  const [modalGraphR, setModalGraphR] = React.useState(false);
  const [modalGraphD, setModalGraphD] = React.useState(false);
  const [modalGraphRs, setModalGraphRs] = React.useState(false);

  const toggleModalR = () => {
    setModalGraphR(!modalGraphR);
    setModalGraphD(false);
    setModalGraphRs(false);
  };

  const toggleModalD = () => {
    setModalGraphD(!modalGraphD);
    setModalGraphR(false);
    setModalGraphRs(false);
  };

  const toggleModalRs = () => {
    setModalGraphRs(!modalGraphRs);
    setModalGraphR(false);
    setModalGraphD(false);
  };

  function calculaPercentual(realizado, projetado) {
    const valRealizado = Number(realizado || 0);
    const valProjetado = Number(projetado || 0);

    if (!valRealizado || !valProjetado) {
      return 0;
    }

    const result = (valRealizado / valProjetado) * 100;
    return result;
  }

  function retornaValor(tipo, mr, mes, format = false) {
    const result = planejamento?.[tipo]?.find((f) => f?._id === mes)?.[mr] || 0;

    return format
      ? result?.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : result;
  }

  function retornaResultado(mr, mes, format = false) {
    // const val1 =
    //   planejamento?.receitas?.find((f) => f?._id === mes)?.[`${mr}receita`] ||
    //   0;
    // const val2 =
    //   planejamento?.despesas?.find((f) => f?._id === mes)?.[`${mr}despesa`] ||
    //   0;
    // const result = val1 - val2 || 0;
    // return format
    //   ? result?.toLocaleString('pt-BR', {
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     })
    //   : result;
  }

  function retornaResultadoTotal(mr) {
    // const val1 = planejamento?.receitas?.reduce(
    //   (a, b) => a + b?.[`${mr}receita`],
    //   0
    // );
    // const val2 = planejamento?.despesas?.reduce(
    //   (a, b) => a + b?.[`${mr}despesa`],
    //   0
    // );
    // const result = val1 - val2 || 0;
    // return result;
  }

  useEffect(() => {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    async function initDataPlanejamento() {
      try {
        const { data } = await api.get(`/planejamento?ano=${ano}`);
        data.difDespesa = Array.from(Array(12).keys()).map((m) =>
          calculaPercentual(
            data.despesasRealizadas[m].valor,
            data.despesaProjetada[m].valor
          )
        );
        data.difReceita = Array.from(Array(12).keys()).map((m) =>
          calculaPercentual(
            data.receitasRealizadas[m].valor,
            data.receitaProjetada[m].valor
          )
        );

        data.totalRealizadoReceita = data.receitasRealizadas.reduce(
          (a, b) => a + Number(b.valor),
          0
        );
        data.totalProjetadoReceita = data.receitaProjetada.reduce(
          (a, b) => a + Number(b.valor),
          0
        );

        data.totalRealizadoDespesa = data.despesasRealizadas.reduce(
          (a, b) => a + Number(b.valor),
          0
        );
        data.totalProjetadoDespesa = data.despesaProjetada.reduce(
          (a, b) => a + Number(b.valor),
          0
        );

        setPlanejamento(data);

        Swal.close();
      } catch (e) {
        console.log(e);
        Swal.close();
        toast.error(
          e?.response?.data?.error || 'Não foi possível carregar os dados!'
        );
      }
    }
    initDataPlanejamento();
  }, [ano]);

  const dataR = Array.from(Array(12).keys()).map((m) => ({
    name: nomeMes[m + 1],
    Projetado: planejamento?.receitaProjetada[m]?.valor,
    Realizado: planejamento?.receitasRealizadas[m]?.valor,
  }));

  const dataD = Array.from(Array(12).keys()).map((m) => ({
    name: nomeMes[m + 1],
    Projetado: planejamento?.despesaProjetada[m]?.valor,
    Realizado: planejamento?.despesasRealizadas[m]?.valor,
  }));

  const dataRs = Array.from(Array(12).keys()).map((m) => ({
    name: nomeMes[m + 1],
    Projetado: planejamento?.resultadoProjetado[m]?.valor,
    Realizado: planejamento?.resultadoRealizado[m]?.valor,
  }));

  const dataDifR = Array.from(Array(12).keys()).map((m) => ({
    name: nomeMes[m + 1],
    Resultado: calculaPercentual(
      planejamento?.receitasRealizadas[m]?.valor,
      planejamento?.receitaProjetada[m]?.valor
    )?.toFixed(2),
  }));

  const dataDifD = Array.from(Array(12).keys()).map((m) => ({
    name: nomeMes[m + 1],
    Resultado: calculaPercentual(
      planejamento?.despesasRealizadas[m]?.valor,
      planejamento?.despesaProjetada[m]?.valor
    )?.toFixed(2),
  }));

  return (
    <>
      <Row className="mb-2 mt-0">
        <Col className="d-flex justify-between">
          <h4>Planejamento</h4>

          <div>
            <Input
              type="select"
              onChange={async (e) => {
                setAno(e?.target?.value);
              }}
              className="mb-1"
              style={{
                width: 150,
              }}
              defaultValue={new Date().getFullYear()}
            >
              {Array.from(Array(10).keys()).map((m) => {
                const hoje = new Date().getFullYear();
                const year = hoje - (m - 4);

                return (
                  <option
                    value={year}
                    key={year}
                    defaultChecked={hoje === year}
                  >
                    {year}
                  </option>
                );
              })}
            </Input>
          </div>
        </Col>
      </Row>

      <Card>
        <Row>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid lime',
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CardHeader
              className="mb-0 mt-0"
              style={{
                textAlign: 'center',
                color: 'lime',
                fontSize: 15,
                paddingBottom: 0,
              }}
            >
              Receitas
              <Button
                color="btn-primary"
                className="btn-sm ml-2 color-lime btn-hover"
                onClick={() => toggleModalR()}
              >
                <FaChartBar />
              </Button>
            </CardHeader>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid lime',
              }}
            />
          </Col>
        </Row>
        <Table className="rwd-table table-fixed" responsive>
          <thead style={{ fontSize: 10 }}>
            <tr>
              <th
                className="bt-0 t-center"
                style={{
                  width: 120,
                }}
              />
              <th className="bt-0 t-center  w-7">Jan</th>
              <th className="bt-0 t-center  w-7">Fev</th>
              <th className="bt-0 t-center  w-7">Mar</th>
              <th className="bt-0 t-center  w-7">Abr</th>
              <th className="bt-0 t-center  w-7">Mai</th>
              <th className="bt-0 t-center  w-7">Jun</th>
              <th className="bt-0 t-center  w-7">Jul</th>
              <th className="bt-0 t-center  w-7">Ago</th>
              <th className="bt-0 t-center  w-7">Set</th>
              <th className="bt-0 t-center  w-7">Out</th>
              <th className="bt-0 t-center  w-7">Nov</th>
              <th className="bt-0 t-center  w-7">Dez</th>
              <th className="bt-0 t-center  w-7">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }}>Projetado</p>
              </td>
              {planejamento?.receitaProjetada?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label style={{ fontSize: 12 }}>
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}

              <td className="t-center" data-label="Total">
                <Label style={{ fontSize: 12 }}>
                  {planejamento?.totalProjetadoReceita?.toLocaleString(
                    'pt-BR',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </Label>
              </td>
            </tr>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }}>Realizado</p>
              </td>
              {planejamento?.receitasRealizadas?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label style={{ fontSize: 12 }}>
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}
              <td className="t-center" data-label="Total">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Label style={{ fontSize: 12 }}>
                    {planejamento?.totalRealizadoReceita?.toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Label>
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }} />
              </td>
              {planejamento?.difReceita &&
                planejamento?.difReceita?.map((m, i) => (
                  <td
                    className="t-center"
                    data-label={'Dif. ' + nomeMes[i + 1]}
                  >
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 11,
                          color: m >= 100 ? 'lime' : 'orangered',
                        }}
                      >
                        {m?.toLocaleString('pt-BR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                        %
                      </Label>
                    </FormGroup>
                  </td>
                ))}
              <td className="t-center" data-label="Dif. Total">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Label
                    style={{
                      fontSize: 11,
                      color:
                        calculaPercentual(
                          planejamento?.totalRealizadoReceita,
                          planejamento?.totalProjetadoReceita
                        ) >= 100
                          ? 'lime'
                          : 'orangered',
                    }}
                  >
                    {calculaPercentual(
                      planejamento?.totalRealizadoReceita,
                      planejamento?.totalProjetadoReceita
                    )?.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    %
                  </Label>
                </FormGroup>
              </td>
            </tr>
          </tbody>
        </Table>

        <Row>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid orangered',
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CardHeader
              className="mb-0 mt-0"
              style={{
                textAlign: 'center',
                color: 'orangered',
                fontSize: 15,
                paddingBottom: 0,
              }}
            >
              Despesas
              <Button
                color="btn-primary"
                className="btn-sm ml-2 color-orangered btn-hover"
                onClick={() => toggleModalD()}
              >
                <FaChartBar />
              </Button>
            </CardHeader>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid orangered',
              }}
            />
          </Col>
        </Row>
        <Table className="rwd-table table-fixed" responsive>
          <thead style={{ fontSize: 10 }}>
            <tr>
              <th
                className="bt-0 t-center"
                style={{
                  width: 120,
                }}
              />
              <th className="bt-0 t-center  w-7 ">Jan</th>
              <th className="bt-0 t-center  w-7">Fev</th>
              <th className="bt-0 t-center  w-7">Mar</th>
              <th className="bt-0 t-center  w-7">Abr</th>
              <th className="bt-0 t-center  w-7">Mai</th>
              <th className="bt-0 t-center  w-7">Jun</th>
              <th className="bt-0 t-center  w-7">Jul</th>
              <th className="bt-0 t-center  w-7">Ago</th>
              <th className="bt-0 t-center  w-7">Set</th>
              <th className="bt-0 t-center  w-7">Out</th>
              <th className="bt-0 t-center  w-7">Nov</th>
              <th className="bt-0 t-center  w-7">Dez</th>
              <th className="bt-0 t-center  w-7">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="t-center" data-label="">
                <p style={{ fontSize: 11 }}>Projetado</p>
              </td>
              {planejamento?.despesaProjetada?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label style={{ fontSize: 12 }}>
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}
              <td className="t-center" data-label="Total">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Label style={{ fontSize: 12 }}>
                    {planejamento?.totalProjetadoDespesa?.toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Label>
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }}>Realizado</p>
              </td>
              {planejamento?.despesasRealizadas?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label style={{ fontSize: 12 }}>
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}
              <td className="t-center" data-label="Total">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Label style={{ fontSize: 12 }}>
                    {planejamento?.totalRealizadoDespesa?.toLocaleString(
                      'pt-BR',
                      {
                        style: 'currency',
                        currency: 'BRL',
                      }
                    )}
                  </Label>
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }} />
              </td>
              {planejamento?.difDespesa &&
                planejamento?.difDespesa?.map((m, i) => (
                  <td
                    className="t-center"
                    data-label={'Dif. ' + nomeMes[i + 1]}
                  >
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 11,
                          color: m < 100 ? 'lime' : 'orangered',
                        }}
                      >
                        {m?.toLocaleString('pt-BR', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                        %
                      </Label>
                    </FormGroup>
                  </td>
                ))}
              <td className="t-center" data-label="Dif. Total">
                <FormGroup style={{ marginBottom: 0 }}>
                  <Label
                    style={{
                      fontSize: 11,
                      color:
                        calculaPercentual(
                          planejamento?.totalRealizadoDespesa,
                          planejamento?.totalProjetadoDespesa
                        ) <= 0
                          ? 'lime'
                          : 'orangered',
                    }}
                  >
                    {calculaPercentual(
                      planejamento?.totalRealizadoDespesa,
                      planejamento?.totalProjetadoDespesa
                    )?.toLocaleString('pt-BR', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    %
                  </Label>
                </FormGroup>
              </td>
            </tr>
          </tbody>
        </Table>

        <Row>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid orange',
              }}
            />
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CardHeader
              className="mb-0 mt-0"
              style={{
                textAlign: 'center',
                color: 'orange',
                fontSize: 15,
                paddingBottom: 0,
              }}
            >
              Resultado
              <Button
                color="btn-primary"
                className="btn-sm ml-2 color-orange btn-hover"
                onClick={() => toggleModalRs()}
              >
                <FaChartBar />
              </Button>
            </CardHeader>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <hr
              style={{
                width: '90%',
                paddingBottom: 0,
                marginTop: 23,
                border: '1px solid orange',
              }}
            />
          </Col>
        </Row>
        <Table className="rwd-table table-fixed" responsive>
          <thead style={{ fontSize: 10 }}>
            <tr>
              <th
                className="bt-0 t-center"
                style={{
                  width: 120,
                }}
              />
              <th className="bt-0 t-center w-7">Jan</th>
              <th className="bt-0 t-center w-7">Fev</th>
              <th className="bt-0 t-center w-7">Mar</th>
              <th className="bt-0 t-center w-7">Abr</th>
              <th className="bt-0 t-center w-7">Mai</th>
              <th className="bt-0 t-center w-7">Jun</th>
              <th className="bt-0 t-center w-7">Jul</th>
              <th className="bt-0 t-center w-7">Ago</th>
              <th className="bt-0 t-center w-7">Set</th>
              <th className="bt-0 t-center w-7">Out</th>
              <th className="bt-0 t-center w-7">Nov</th>
              <th className="bt-0 t-center w-7">Dez</th>
              <th className="bt-0 t-center w-7">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }}>Projetado</p>
              </td>
              {planejamento?.resultadoProjetado?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label
                    style={{
                      fontSize: 12,
                      color: Number(m.valor || 0) >= 0 ? 'green' : 'orangered',
                    }}
                  >
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}
              <td className="t-center" data-label="Total">
                <FormGroup
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Label
                    style={{
                      fontSize: 12,
                      color:
                        planejamento?.totalProjetadoReceita -
                          planejamento?.totalProjetadoDespesa >=
                        0
                          ? 'green'
                          : 'orangered',
                    }}
                  >
                    {(
                      planejamento?.totalProjetadoReceita -
                      planejamento?.totalProjetadoDespesa
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </Label>
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td className="t-center">
                <p style={{ fontSize: 11 }}>Realizado</p>
              </td>
              {planejamento?.resultadoRealizado?.map((m, i) => (
                <td className="t-center" data-label={nomeMes[i + 1]}>
                  <Label
                    style={{
                      fontSize: 12,
                      color: Number(m.valor || 0) >= 0 ? 'green' : 'orangered',
                    }}
                  >
                    {currencyFormatter.format(Number(m.valor || 0))}
                  </Label>
                </td>
              ))}
              <td className="t-center" data-label="Total">
                <FormGroup
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Label
                    style={{
                      fontSize: 12,
                      color:
                        planejamento?.totalRealizadoReceita -
                          planejamento?.totalRealizadoDespesa >=
                        0
                          ? 'green'
                          : 'orangered',
                    }}
                  >
                    {(
                      planejamento?.totalRealizadoReceita -
                      planejamento?.totalRealizadoDespesa
                    ).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </Label>
                </FormGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      </Card>

      <Modal isOpen={modalGraphR} toggle={toggleModalR} size="lg">
        <ModalHeader toggle={toggleModalR}>Receitas:</ModalHeader>
        <ModalBody>
          <Row>
            <div
              style={{
                marginLeft: -15,
              }}
            >
              <ResponsiveContainer width="100%" aspect={ratio1}>
                <LineChart
                  width={700}
                  height={100}
                  data={dataR}
                  margin={{
                    top: 5,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1" />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={100}
                    tickFormatter={(value) => currencyFormatter.format(value)}
                  />
                  <Tooltip
                    formatter={(value) => currencyFormatter.format(value)}
                  />
                  <Legend
                    wrapperStyle={{
                      position: 'relative',
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="Projetado"
                    stroke="purple"
                    strokeDasharray="1"
                  />
                  <Line
                    type="monotone"
                    dataKey="Realizado"
                    stroke="lime"
                    strokeDasharray="1"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <br />
            <ResponsiveContainer width="100%" aspect={ratio2}>
              <BarChart
                width={400}
                height={100}
                data={dataDifR}
                margin={{
                  top: 30,
                  right: 20,
                  left: 5,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  wrapperStyle={{
                    position: 'relative',
                  }}
                />{' '}
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="Resultado" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Row>
          <div
            style={{
              marginBottom: 10,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModalR}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalGraphD} toggle={toggleModalD} size="lg">
        <ModalHeader toggle={toggleModalD}>Despesas:</ModalHeader>
        <ModalBody>
          <Row>
            <div
              style={{
                marginLeft: -15,
              }}
            >
              <ResponsiveContainer width="100%" aspect={ratio1}>
                <LineChart
                  width={400}
                  height={100}
                  data={dataD}
                  margin={{
                    top: 5,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1" />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={100}
                    tickFormatter={(value) => currencyFormatter.format(value)}
                  />
                  <Tooltip
                    formatter={(value) => currencyFormatter.format(value)}
                  />
                  <Tooltip />
                  <Legend
                    wrapperStyle={{
                      position: 'relative',
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="Projetado"
                    stroke="purple"
                    strokeDasharray="1"
                  />
                  <Line
                    type="monotone"
                    dataKey="Realizado"
                    stroke="orangered"
                    strokeDasharray="1"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <ResponsiveContainer width="100%" aspect={ratio2}>
              <BarChart
                width={400}
                height={100}
                data={dataDifD}
                margin={{
                  top: 30,
                  right: 20,
                  left: 5,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  wrapperStyle={{
                    position: 'relative',
                  }}
                />
                <ReferenceLine y={0} stroke="#000" />
                <Bar dataKey="Resultado" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Row>
          <div
            style={{
              marginBottom: 10,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModalD}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalGraphRs} toggle={toggleModalRs} size="lg">
        <ModalHeader toggle={toggleModalRs}>Resultado:</ModalHeader>
        <ModalBody>
          <Row>
            <div
              style={{
                marginLeft: -15,
                marginBottom: 10,
              }}
            >
              <ResponsiveContainer width="100%" aspect={ratio1}>
                <LineChart
                  width={400}
                  height={100}
                  data={dataRs}
                  margin={{
                    top: 5,
                    right: 20,
                    left: 5,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="1" />
                  <XAxis dataKey="name" />
                  <YAxis
                    width={100}
                    tickFormatter={(value) => currencyFormatter.format(value)}
                  />
                  <Tooltip
                    formatter={(value) => currencyFormatter.format(value)}
                  />
                  <Legend
                    wrapperStyle={{
                      position: 'relative',
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="Projetado"
                    stroke="purple"
                    strokeDasharray="1"
                  />
                  <Line
                    type="monotone"
                    dataKey="Realizado"
                    stroke="Orange"
                    strokeDasharray="1"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModalRs}>
            Sair
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Planejamento;
