import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { FaSearch, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import { InputGroup, Input } from '../../components/unform/index';

import api from '../../services/api';

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [day, month, year].join('/');
}

function formatDateDateField(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [year, month, day].join('-');
}

function ExtratoCliente() {
  const formRef = useRef(null);
  const [compras, setCompras] = useState([]);
  const [pages, setPages] = useState(1);
  const [selectedPage, setSelectedPage] = useState(1);

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    const date = new Date();
    formRef.current.setFieldValue(
      'data_inicio',
      formatDateDateField(new Date(date.getFullYear(), date.getMonth(), 1))
    );
    formRef.current.setFieldValue(
      'data_fim',
      formatDateDateField(new Date(date.getFullYear(), date.getMonth() + 1, 0))
    );
  }, []);

  async function pesquisaExtrato(dados) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      let url = `/extrato-cliente?data_inicio=${dados?.data_inicio}&data_fim=${dados?.data_fim}&page=${selectedPage}`;
      if (dados?.conta) {
        url += `&conta=${dados?.conta}`;
      }
      const { data, headers } = await api.get(url);

      setCompras(data);
      setPages(headers['x-total-pages']);
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Extrato por cliente</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form ref={formRef} onSubmit={(e) => pesquisaExtrato(e)}>
            <Row>
              <Col lg={3}>
                <Input
                  label="Data início"
                  name="data_inicio"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={3}>
                <Input
                  label="Data fim"
                  name="data_fim"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={6}>
                <InputGroup
                  name="conta"
                  label="Conta"
                  type="number"
                  className="form-control-group"
                  placeholder="Digite o numero da conta para ver o extrato"
                  append={
                    <Button size="sm" color="link" type="submit">
                      <FaSearch />
                    </Button>
                  }
                />
              </Col>
            </Row>
          </Form>
        </CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Data</th>
              <th style={{ textAlign: 'center' }}>Comprador</th>
              <th style={{ textAlign: 'center' }}>Estabelecimento</th>
              <th style={{ textAlign: 'center' }}>Parcelas</th>
              <th style={{ textAlign: 'center' }}>Valor</th>
              <th style={{ textAlign: 'center' }}>Valor com juros</th>
              <th style={{ textAlign: 'center' }}>Taxa juros</th>
              <th style={{ textAlign: 'center' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {compras?.length ? (
              compras?.map((c) => (
                <tr key={c?.id}>
                  <td style={{ textAlign: 'center' }}>
                    {formatDate(c?.createdAt)}
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.conta}</td>
                  <td style={{ textAlign: 'center' }}>{c?.estabelecimento}</td>
                  <td style={{ textAlign: 'center' }}>{c?.total_parcelas}</td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(c?.valor).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(c?.valor_total).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {c?.taxa_juros > 0 ? Number(c?.taxa_juros) * 100 : 0} %
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pages || 1}
          onPageChange={({ selected }) => {
            setSelectedPage(selected + 1);
            // pesquisaExtrato();
          }}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        /> */}
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Compras</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12} />
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ExtratoCliente;
