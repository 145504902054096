import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function Select({
  name,
  label,
  type,
  data = [{ label: 'Selecione' }],
  className = '',
  defaultValue,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue: doidera,
    registerField,
    error,
  } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>

      <select
        ref={inputRef}
        defaultValue={defaultValue || doidera}
        className={[className, error ? 'error' : ''].join(' ')}
        {...rest}
      >
        {data.map((m) => (
          <option value={m.value}>{m.label}</option>
        ))}
      </select>
    </div>
  );
}
