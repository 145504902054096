/* eslint-disable no-return-await */
import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { Button, Card, Col, Row, CardBody, CardFooter } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ValidationError } from 'yup';
import { Input, Radio } from '../../components/unform/index';
import api from '../../services/apiFinanceiro';

import editClienteForncedor from '../../validations/editClienteFornecedor';

function EditarContato() {
  const formRef = useRef();
  const history = useHistory();
  const { id } = useParams();
  const [tipo, setTipo] = useState(null);

  useEffect(() => {
    async function viewContract() {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        if (id) {
          const { data } = await api.get(`/cliente-fornecedor/${id}`);
          if (data) {
            Object.keys(data).forEach((e) => {
              formRef.current.setFieldValue(e, data[e]);
            });
            setTipo(data?.tipo);
          }
        }
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar dados!');
        Swal.close();
      }
    }
    viewContract();
  }, [id]);

  async function handleSubmit(data) {
    try {
      await editClienteForncedor(data);

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await api.put(`/cliente-fornecedor/${id}`, data);
      toast.success('Dados salvos com sucesso!');
      Swal.close();
      history.push('/cliente-fornecedor');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
        Swal.close();
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        toast.error(error);
        Swal.close();
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Editar contrato</h4>
        </Col>
      </Row>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <CardBody>
            <Row>
              <Col lg={5} md={6} sm={12}>
                <Input
                  label="Nome"
                  name="nome"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <Input
                  label="Nome exibição"
                  name="nome_exibicao"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Radio
                  name="tipo"
                  label="Tipo"
                  className="radio"
                  defaultValue={tipo}
                  data={[
                    { label: 'Cliente', value: 'C', id: 'C' },
                    { label: 'Fornecedor', value: 'F', id: 'F' },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <Input
                  label="Documento"
                  name="documento"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={2} md={4} sm={6}>
                <Input
                  label="Código de país"
                  name="codigo_pais"
                  className="form-control"
                  type="text"
                />
              </Col>
              <Col lg={3} md={4} sm={6}>
                <Input
                  label="Celular"
                  name="celular"
                  className="form-control"
                  type="number"
                />
              </Col>
              <Col lg={3} md={4} sm={6}>
                <Input
                  label="E-mail"
                  name="email"
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit">
              Editar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default EditarContato;
