/* eslint-disable camelcase */
import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    'valor_tarifa_24 HORAS': Yup.string().required('Obrigatório'),
    valor_tarifa_MANUTENCAO: Yup.string().required('Obrigatório'),
    valor_tarifa_CARTAO: Yup.string().required('Obrigatório'),

    valor_tarifa_TED:
      data.tipo_franquia_TED !== 'I'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_valor_TED:
      data.tipo_franquia_TED === 'V'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_quantidade_TED:
      data.tipo_franquia_TED === 'T'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),

    valor_tarifa_PIX:
      data.tipo_franquia_PIX !== 'I'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_valor_PIX:
      data.tipo_franquia_PIX === 'V'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_quantidade_PIX:
      data.tipo_franquia_PIX === 'T'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),

    valor_tarifa_BOLETO:
      data.tipo_franquia_BOLETO !== 'I'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_valor_BOLETO:
      data.tipo_franquia_BOLETO === 'V'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
    franquia_quantidade_BOLETO:
      data.tipo_franquia_BOLETO === 'T'
        ? Yup.string().required('Obrigatório')
        : Yup.string(),
  });

  await schema.validate(data, { abortEarly: false });
}
