import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useField } from '@unform/core';
import defaultImg from '../../assets/images/default.jpg';

export default function Image({
  name,
  label,
  handleRemove,
  defaultValue,
  btnSelect = 'Alterar',
  radius = false,
  objectFit = 'cover',
  returnPreview = false,
  onChange,
  width = 250,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    defaultValue: defaultVal,
    registerField,
    error,
  } = useField(name);
  const [preview, setPreview] = useState(defaultVal || defaultValue);
  const handlePreview = useCallback(
    (e) => {
      const file = e.target.files?.[0];
      if (!file) {
        setPreview(null);
        return;
      }
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);
      if (onChange) {
        onChange({ file, path: previewURL });
      }
    },
    [onChange]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      getValue(ref) {
        if (returnPreview) {
          return {
            file: ref.files[0] || null,
            preview,
          };
        }
        return ref.files[0] || null;
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField, returnPreview, preview]);

  return (
    <div>
      {label && <label>{label}</label>}
      <div className="image-preview-group">
        <div>
          <img
            className={`image-preview ${radius ? 'radius' : ''} ${
              objectFit === 'cover' ? 'object-cover' : 'object-fit'
            } ${preview ? '' : 'preview'}`.trim()}
            src={preview || defaultImg}
            alt="Preview"
            width={width}
          />

          <div className="upload-options">
            {handleRemove && (
              <button
                type="button"
                onClick={() => handleRemove(() => setPreview(defaultImg))}
              >
                Remover
              </button>
            )}
            <label>
              {btnSelect}
              <input
                ref={inputRef}
                type="file"
                onChange={handlePreview}
                className="image-upload"
                accept="image/png,image/jpg,image/jpeg,image/webp"
                {...rest}
              />
            </label>
          </div>
        </div>
      </div>
      {error && <small className="error">{error}</small>}
    </div>
  );
}
