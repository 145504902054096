/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FaTrashAlt, FaKey } from 'react-icons/fa';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';
import { Input, CheckBox } from '../../components/unform/index';
import addUserValidator from '../../validations/addUserValidator';

import api from '../../services/api';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Usuarios() {
  let timeoutConsulta;
  const formRef = useRef();
  const formRefAdd = useRef();
  const [usuarios, setUsuarios] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [userId, setUserId] = useState(null);
  const [filtros, setFiltros] = useState(null);

  const [modal, setModal] = useState(false);
  const [modalCad, setModalCad] = useState(false);

  const refNome = useRef(null);
  const refEmail = useRef(null);

  const toggleModal = () => setModal(!modal);
  const toggleModalCad = () => setModalCad(!modalCad);

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });
  };

  useEffect(() => {
    async function getUsers() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        let filtroConsulta = '';
        if (filtros) {
          Object.entries(filtros).forEach(([key, value]) => {
            if (['string', 'number'].includes(typeof value)) {
              filtroConsulta += `${key}=${value}&`;
            } else if (value) {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          });
        }
        const { data } = await api.get(
          `/listar-usuario-backoffice?${filtroConsulta}`
        );
        setUsuarios(data);
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar usuários!');
        Swal.close();
      }
    }
    getUsers();
  }, [filtros]);

  async function handleDeleteUser(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir este usuário?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/deletar-usuario-backoffice/${id}`)
            .then(() => {
              setUsuarios(usuarios?.filter((f) => f.id !== id));
              Swal.close();
              toast.success('Excluido com sucesso');
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  async function getPermissions(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/listar-permissao-usuario/${id}`);
      if (data) {
        setPermissoes(data.map((a) => a.tela));
      }
      setUserId(id);
      Swal.close();
      toggleModal();
    } catch (err) {
      toast.error('Erro ao buscar permissões!');
      Swal.close();
    }
  }

  async function handleSubmitPermissoes(perms) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (perms) {
      const arrayPermissoes = [];
      Object.keys(perms).forEach((key) => {
        if (Boolean(perms[key]) === true) {
          arrayPermissoes.push(Number(key));
        }
      });
      const body = { acessos: arrayPermissoes };
      try {
        await api.patch(`/alterar-acesso-backoffice/${userId}`, body);
        toast.success('Permissões alteradas com sucesso');
        Swal.close();
        toggleModal();
      } catch (err) {
        toast.error('Erro ao salvar permissões!');
        Swal.close();
      }
    }
  }

  async function handleSubmitAddUser(dados) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await addUserValidator(dados);
      const arrayPermissoes = [];
      Object.keys(dados).forEach((key) => {
        if (typeof dados[key] !== 'string') {
          if (Boolean(dados[key]) === true) {
            arrayPermissoes.push(Number(key));
          }
        }
      });
      const body = {
        email: dados.email,
        senha: dados.senha,
        nome: dados.nome,
        acessos: arrayPermissoes,
      };
      const { data } = await api.post('/criar-usuario-backoffice', body);
      setUsuarios(
        usuarios.concat({
          id: data.id,
          nome: data.nome,
          email: data.email,
        })
      );
      toast.success('Usuário criado com sucesso');
      toggleModalCad();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        Swal.close();
        formRefAdd.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        Swal.fire({
          text: error,
          icon: 'error',
        });
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Usuários</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">
            Total de registros: {usuarios?.length || 0}
          </h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button
            className="btn btn-primary"
            onClick={toggleModalCad}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Nome"
                  name="nome"
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="E-mail"
                  name="email"
                  ref={refEmail}
                  onChange={() =>
                    debounceConsulta({
                      email: refEmail?.current?.value,
                    })
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th className="text-center">Permissão</th>
              <th className="text-center">Excluir</th>
            </tr>
          </thead>
          <tbody>
            {usuarios?.length ? (
              usuarios?.map((u) => (
                <tr key={u?.id}>
                  <td>{u?.nome}</td>
                  <td>{u?.email}</td>
                  <td className="text-center">
                    <Button
                      color="link"
                      onClick={() => getPermissions(u?.id)}
                      disabled={Number(u?.id) === 1}
                    >
                      <FaKey />
                    </Button>
                  </td>
                  <td className="justify-center">
                    <Button
                      color="link"
                      onClick={() => handleDeleteUser(u?.id)}
                      disabled={Number(u?.id) === 1}
                    >
                      <FaTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Modal isOpen={modalCad} toggle={toggleModalCad} size="lg">
        <ModalHeader toggle={toggleModalCad}>Adicionar usuário</ModalHeader>
        <Form
          ref={formRefAdd}
          onSubmit={(e) => {
            handleSubmitAddUser(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col>
                <Input name="nome" label="Nome" className="form-control" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name="email" label="E-mail" className="form-control" />
              </Col>
              <Col>
                <Input
                  name="senha"
                  label="Senha"
                  type="password"
                  className="form-control"
                />
              </Col>
            </Row>
            <Row>
              <label className="label-form">Permissões UneoBank</label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="1" label="Dashboard" value="1" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="2" label="Pes. Fis." value="2" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="3" label="Pes. Jur." value="3" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="4" label="Contas" value="4" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="5" label="Cartões" value="5" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="6" label="Usuários backoffice" value="6" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="7" label="Planos" value="7" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="8" label="Tarifador" value="8" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="9" label="Monitoria" value="9" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="27" label="Suporte" value="27" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="10" label="Aberturas PF" value="10" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="11" label="Aberturas PJ" value="11" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="105"
                  label="Auto atendimento taxas"
                  value="105"
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="106" label="Recarga / Gift Card" value="106" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="22" label="Recarga Celular" value="22" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="28" label="Log Acessos" value="28" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="29" label="Indicações" value="29" />
              </Col> */}
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="30" label="Pendência tarifa" value="30" />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="31" label="Mala direta" value="31" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="35" label="Notificação" value="35" />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox name="35" label="Campanha" value="36" />
              </Col> */}

              <Col lg={4} md={4} sm={6}>
                <CheckBox name="32" label="Extrato Uneobank" value="32" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="33" label="Transferência Uneobank" value="33" />
              </Col>

              <Col lg={4} md={4} sm={6}>
                <CheckBox name="100" label="Extrato Clientes" value="100" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="101" label="Pagamentos Clientes" value="101" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="102" label="Pix Clientes" value="102" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="103"
                  label="Transferências Clientes"
                  value="103"
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="104" label="Boletos Clientes" value="104" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="107" label="Logs Celcoin" value="107" />
              </Col>

              <Col lg={4} md={4} sm={6}>
                <CheckBox name="201" label="Abertura contas PF" value="201" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="202" label="Abertura contas PJ" value="202" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="250" label="Funcionários" value="250" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="108" label="Empréstimo" value="108" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="250" label="Funcionários" value="250" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="251" label="Parceiros" value="251" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="255" label="Blog" value="255" />
              </Col>
            </Row>
            <Row>
              <label className="label-form">Permissões Financeiro</label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="12" label="Dashboard" value="12" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="13" label="Contratos" value="13" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="14" label="Lançamentos" value="14" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="15" label="Planej." value="15" />
              </Col>
              <Col lg={6} md={4} sm={6}>
                <CheckBox name="16" label="Cliente Fornecedor" value="16" />
              </Col>
            </Row>
            <Row>
              <label className="label-form">Permissões Uneo Cŕedito</label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="17" label="Gestão de crédito" value="17" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="18" label="Gestão de tarifas" value="18" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="19" label="Gestão de faturas" value="19" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="20" label="Extrato por cliente" value="20" />
              </Col>
            </Row>
            <Row>
              <label className="label-form">
                Permissões Uneo Correspondente
              </label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="23" label="Estabelecimento" value="23" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="24" label="Controle de caixa" value="24" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="25" label="Gestão consolidação" value="25" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="26" label="Vendas" value="26" />
              </Col>
            </Row>
            <Row>
              <label className="label-form">Permissões Uneo green</label>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="300" label="Sorteio" value="300" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="301" label="Valor cupom de caixa" value="301" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="302" label="Valor Prêmio" value="302" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox name="303" label="Cupons" value="303" />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="304"
                  label="Histórico de sorteios"
                  value="304"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>{' '}
            <Button color="secondary" onClick={toggleModalCad}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Permissões</ModalHeader>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmitPermissoes(e);
          }}
        >
          <ModalBody>
            <Row>
              <h5>UneoBank</h5>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="1"
                  label="Dashboard"
                  value="1"
                  onChange={() => {
                    if (permissoes?.includes(1)) {
                      setPermissoes(permissoes?.filter((f) => f !== 1));
                    } else {
                      setPermissoes(permissoes.concat(1));
                    }
                  }}
                  checked={permissoes?.includes(1)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="105"
                  label="Auto atendimento taxas"
                  value="105"
                  onChange={() => {
                    if (permissoes?.includes(105)) {
                      setPermissoes(permissoes?.filter((f) => f !== 105));
                    } else {
                      setPermissoes(permissoes.concat(105));
                    }
                  }}
                  checked={permissoes?.includes(105)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="2"
                  label="Pes. Fis."
                  value="2"
                  onChange={() => {
                    if (permissoes?.includes(2)) {
                      setPermissoes(permissoes?.filter((f) => f !== 2));
                    } else {
                      setPermissoes(permissoes.concat(2));
                    }
                  }}
                  checked={permissoes?.includes(2)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="3"
                  label="Pes. Jur."
                  value="3"
                  onChange={() => {
                    if (permissoes?.includes(3)) {
                      setPermissoes(permissoes?.filter((f) => f !== 3));
                    } else {
                      setPermissoes(permissoes.concat(3));
                    }
                  }}
                  checked={permissoes?.includes(3)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="4"
                  label="Contas"
                  value="4"
                  onChange={() => {
                    if (permissoes?.includes(4)) {
                      setPermissoes(permissoes?.filter((f) => f !== 4));
                    } else {
                      setPermissoes(permissoes.concat(4));
                    }
                  }}
                  checked={permissoes?.includes(4)}
                />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="5"
                  label="Cartões"
                  value="5"
                  onChange={() => {
                    if (permissoes?.includes(5)) {
                      setPermissoes(permissoes?.filter((f) => f !== 5));
                    } else {
                      setPermissoes(permissoes.concat(5));
                    }
                  }}
                  checked={permissoes?.includes(5)}
                />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="6"
                  label="Usuários backoffice"
                  value="6"
                  onChange={() => {
                    if (permissoes?.includes(6)) {
                      setPermissoes(permissoes?.filter((f) => f !== 6));
                    } else {
                      setPermissoes(permissoes.concat(6));
                    }
                  }}
                  checked={permissoes?.includes(6)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="7"
                  label="Planos"
                  value="7"
                  onChange={() => {
                    if (permissoes?.includes(7)) {
                      setPermissoes(permissoes?.filter((f) => f !== 7));
                    } else {
                      setPermissoes(permissoes.concat(7));
                    }
                  }}
                  checked={permissoes?.includes(7)}
                />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="8"
                  label="Tarifador"
                  value="8"
                  onChange={() => {
                    if (permissoes?.includes(8)) {
                      setPermissoes(permissoes?.filter((f) => f !== 8));
                    } else {
                      setPermissoes(permissoes.concat(8));
                    }
                  }}
                  checked={permissoes?.includes(8)}
                />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="9"
                  label="Monitoria"
                  onChange={() => {
                    if (permissoes?.includes(9)) {
                      setPermissoes(permissoes?.filter((f) => f !== 9));
                    } else {
                      setPermissoes(permissoes.concat(9));
                    }
                  }}
                  checked={permissoes?.includes(9)}
                />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="10"
                  label="Aberturas PF"
                  onChange={() => {
                    if (permissoes?.includes(10)) {
                      setPermissoes(permissoes?.filter((f) => f !== 10));
                    } else {
                      setPermissoes(permissoes.concat(10));
                    }
                  }}
                  checked={permissoes?.includes(10)}
                />
              </Col> */}
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="11"
                  label="Aberturas PJ"
                  onChange={() => {
                    if (permissoes?.includes(11)) {
                      setPermissoes(permissoes?.filter((f) => f !== 11));
                    } else {
                      setPermissoes(permissoes.concat(11));
                    }
                  }}
                  checked={permissoes?.includes(11)}
                />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="27"
                  label="Suporte"
                  onChange={() => {
                    if (permissoes?.includes(27)) {
                      setPermissoes(permissoes?.filter((f) => f !== 27));
                    } else {
                      setPermissoes(permissoes.concat(27));
                    }
                  }}
                  checked={permissoes?.includes(27)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="106"
                  label="Recarga / Gift Card"
                  onChange={() => {
                    if (permissoes?.includes(106)) {
                      setPermissoes(permissoes?.filter((f) => f !== 106));
                    } else {
                      setPermissoes(permissoes.concat(106));
                    }
                  }}
                  checked={permissoes?.includes(106)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="28"
                  label="Log Acessos"
                  onChange={() => {
                    if (permissoes?.includes(28)) {
                      setPermissoes(permissoes?.filter((f) => f !== 28));
                    } else {
                      setPermissoes(permissoes.concat(28));
                    }
                  }}
                  checked={permissoes?.includes(28)}
                />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="29"
                  label="Indicações"
                  onChange={() => {
                    if (permissoes?.includes(29)) {
                      setPermissoes(permissoes?.filter((f) => f !== 29));
                    } else {
                      setPermissoes(permissoes.concat(29));
                    }
                  }}
                  checked={permissoes?.includes(29)}
                />
              </Col> */}
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="30"
                  label="Pendencia Tarifa"
                  onChange={() => {
                    if (permissoes?.includes(30)) {
                      setPermissoes(permissoes?.filter((f) => f !== 30));
                    } else {
                      setPermissoes(permissoes.concat(30));
                    }
                  }}
                  checked={permissoes?.includes(30)}
                />
              </Col> */}
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="31"
                  label="Mala direta"
                  onChange={() => {
                    if (permissoes?.includes(31)) {
                      setPermissoes(permissoes?.filter((f) => f !== 31));
                    } else {
                      setPermissoes(permissoes.concat(31));
                    }
                  }}
                  checked={permissoes?.includes(31)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="32"
                  label="Extrato Uneobank"
                  onChange={() => {
                    if (permissoes?.includes(32)) {
                      setPermissoes(permissoes?.filter((f) => f !== 32));
                    } else {
                      setPermissoes(permissoes.concat(32));
                    }
                  }}
                  checked={permissoes?.includes(32)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="33"
                  label="Transferência Uneobank"
                  onChange={() => {
                    if (permissoes?.includes(33)) {
                      setPermissoes(permissoes?.filter((f) => f !== 33));
                    } else {
                      setPermissoes(permissoes.concat(33));
                    }
                  }}
                  checked={permissoes?.includes(33)}
                />
              </Col>

              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="100"
                  label="Extrato Clientes"
                  onChange={() => {
                    if (permissoes?.includes(100)) {
                      setPermissoes(permissoes?.filter((f) => f !== 100));
                    } else {
                      setPermissoes(permissoes.concat(100));
                    }
                  }}
                  checked={permissoes?.includes(100)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="101"
                  label="Pagamentos Clientes"
                  onChange={() => {
                    if (permissoes?.includes(101)) {
                      setPermissoes(permissoes?.filter((f) => f !== 101));
                    } else {
                      setPermissoes(permissoes.concat(101));
                    }
                  }}
                  checked={permissoes?.includes(101)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="102"
                  label="Pix Clientes"
                  onChange={() => {
                    if (permissoes?.includes(102)) {
                      setPermissoes(permissoes?.filter((f) => f !== 102));
                    } else {
                      setPermissoes(permissoes.concat(102));
                    }
                  }}
                  checked={permissoes?.includes(102)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="103"
                  label="Transferências Clientes"
                  onChange={() => {
                    if (permissoes?.includes(103)) {
                      setPermissoes(permissoes?.filter((f) => f !== 103));
                    } else {
                      setPermissoes(permissoes.concat(103));
                    }
                  }}
                  checked={permissoes?.includes(103)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="104"
                  label="Boletos Clientes"
                  onChange={() => {
                    if (permissoes?.includes(104)) {
                      setPermissoes(permissoes?.filter((f) => f !== 104));
                    } else {
                      setPermissoes(permissoes.concat(104));
                    }
                  }}
                  checked={permissoes?.includes(104)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="107"
                  label="Logs Celcoin"
                  onChange={() => {
                    if (permissoes?.includes(107)) {
                      setPermissoes(permissoes?.filter((f) => f !== 107));
                    } else {
                      setPermissoes(permissoes.concat(107));
                    }
                  }}
                  checked={permissoes?.includes(107)}
                />
              </Col>

              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="106"
                  label="Recarga / Gift Card"
                  onChange={() => {
                    if (permissoes?.includes(106)) {
                      setPermissoes(permissoes?.filter((f) => f !== 106));
                    } else {
                      setPermissoes(permissoes.concat(106));
                    }
                  }}
                  checked={permissoes?.includes(106)}
                />
              </Col>

              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="201"
                  label="Abertura contas PF"
                  onChange={() => {
                    if (permissoes?.includes(201)) {
                      setPermissoes(permissoes?.filter((f) => f !== 201));
                    } else {
                      setPermissoes(permissoes.concat(201));
                    }
                  }}
                  checked={permissoes?.includes(201)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="202"
                  label="Abertura contas PJ"
                  onChange={() => {
                    if (permissoes?.includes(202)) {
                      setPermissoes(permissoes?.filter((f) => f !== 202));
                    } else {
                      setPermissoes(permissoes.concat(202));
                    }
                  }}
                  checked={permissoes?.includes(202)}
                />
              </Col>

              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="21"
                  label="Gift Cards"
                  onChange={() => {
                    if (permissoes?.includes(21)) {
                      setPermissoes(permissoes?.filter((f) => f !== 21));
                    } else {
                      setPermissoes(permissoes.concat(21));
                    }
                  }}
                  checked={permissoes?.includes(21)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="22"
                  label="Recarga Celular"
                  onChange={() => {
                    if (permissoes?.includes(22)) {
                      setPermissoes(permissoes?.filter((f) => f !== 22));
                    } else {
                      setPermissoes(permissoes.concat(22));
                    }
                  }}
                  checked={permissoes?.includes(22)}
                />
              </Col> */}

              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="35"
                  label="Notificação"
                  onChange={() => {
                    if (permissoes?.includes(35)) {
                      setPermissoes(permissoes?.filter((f) => f !== 35));
                    } else {
                      setPermissoes(permissoes.concat(35));
                    }
                  }}
                  checked={permissoes?.includes(35)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="108"
                  label="Empréstimo"
                  onChange={() => {
                    if (permissoes?.includes(108)) {
                      setPermissoes(permissoes?.filter((f) => f !== 108));
                    } else {
                      setPermissoes(permissoes.concat(108));
                    }
                  }}
                  checked={permissoes?.includes(108)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="250"
                  label="Funcionários"
                  onChange={() => {
                    if (permissoes?.includes(250)) {
                      setPermissoes(permissoes?.filter((f) => f !== 250));
                    } else {
                      setPermissoes(permissoes.concat(250));
                    }
                  }}
                  checked={permissoes?.includes(250)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="251"
                  label="Parceiros"
                  onChange={() => {
                    if (permissoes?.includes(251)) {
                      setPermissoes(permissoes?.filter((f) => f !== 251));
                    } else {
                      setPermissoes(permissoes.concat(251));
                    }
                  }}
                  checked={permissoes?.includes(251)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="255"
                  label="Blog"
                  onChange={() => {
                    if (permissoes?.includes(255)) {
                      setPermissoes(permissoes?.filter((f) => f !== 255));
                    } else {
                      setPermissoes(permissoes.concat(255));
                    }
                  }}
                  checked={permissoes?.includes(255)}
                />
              </Col>
              {/* <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="36"
                  label="Campanha"
                  onChange={() => {
                    if (permissoes?.includes(36)) {
                      setPermissoes(permissoes?.filter((f) => f !== 36));
                    } else {
                      setPermissoes(permissoes.concat(36));
                    }
                  }}
                  checked={permissoes?.includes(36)}
                />
              </Col> */}
            </Row>
            <Row>
              <h5>Financeiro</h5>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="12"
                  label="Dashboard"
                  value="12"
                  onChange={() => {
                    if (permissoes?.includes(12)) {
                      setPermissoes(permissoes?.filter((f) => f !== 12));
                    } else {
                      setPermissoes(permissoes.concat(12));
                    }
                  }}
                  checked={permissoes?.includes(12)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="13"
                  label="Contratos"
                  value="13"
                  onChange={() => {
                    if (permissoes?.includes(13)) {
                      setPermissoes(permissoes?.filter((f) => f !== 13));
                    } else {
                      setPermissoes(permissoes.concat(13));
                    }
                  }}
                  checked={permissoes?.includes(13)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="14"
                  label="Lançamentos"
                  value="14"
                  onChange={() => {
                    if (permissoes?.includes(14)) {
                      setPermissoes(permissoes?.filter((f) => f !== 14));
                    } else {
                      setPermissoes(permissoes.concat(14));
                    }
                  }}
                  checked={permissoes?.includes(14)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="15"
                  label="Planej."
                  value="15"
                  onChange={() => {
                    if (permissoes?.includes(15)) {
                      setPermissoes(permissoes?.filter((f) => f !== 15));
                    } else {
                      setPermissoes(permissoes.concat(15));
                    }
                  }}
                  checked={permissoes?.includes(15)}
                />
              </Col>
              <Col lg={6} md={4} sm={6}>
                <CheckBox
                  name="16"
                  label="Cliente Fornecedor"
                  value="16"
                  onChange={() => {
                    if (permissoes?.includes(16)) {
                      setPermissoes(permissoes?.filter((f) => f !== 16));
                    } else {
                      setPermissoes(permissoes.concat(16));
                    }
                  }}
                  checked={permissoes?.includes(16)}
                />
              </Col>
            </Row>
            <Row>
              <h5>Uneo Crédito</h5>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="17"
                  label="Gestão de crédito"
                  value="17"
                  onChange={() => {
                    if (permissoes?.includes(17)) {
                      setPermissoes(permissoes?.filter((f) => f !== 17));
                    } else {
                      setPermissoes(permissoes.concat(17));
                    }
                  }}
                  checked={permissoes?.includes(17)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="18"
                  label="Gestão de tarifas"
                  value="18"
                  onChange={() => {
                    if (permissoes?.includes(18)) {
                      setPermissoes(permissoes?.filter((f) => f !== 18));
                    } else {
                      setPermissoes(permissoes.concat(18));
                    }
                  }}
                  checked={permissoes?.includes(18)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="19"
                  label="Gestão de faturas"
                  value="19"
                  onChange={() => {
                    if (permissoes?.includes(19)) {
                      setPermissoes(permissoes?.filter((f) => f !== 19));
                    } else {
                      setPermissoes(permissoes.concat(19));
                    }
                  }}
                  checked={permissoes?.includes(19)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="20"
                  label="Extrato por cliente"
                  value="20"
                  onChange={() => {
                    if (permissoes?.includes(20)) {
                      setPermissoes(permissoes?.filter((f) => f !== 20));
                    } else {
                      setPermissoes(permissoes.concat(20));
                    }
                  }}
                  checked={permissoes?.includes(20)}
                />
              </Col>
            </Row>
            <Row>
              <h5>Uneo Correspondente</h5>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="23"
                  label="Estabelecimentos"
                  value="23"
                  onChange={() => {
                    if (permissoes?.includes(23)) {
                      setPermissoes(permissoes?.filter((f) => f !== 23));
                    } else {
                      setPermissoes(permissoes.concat(23));
                    }
                  }}
                  checked={permissoes?.includes(23)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="24"
                  label="Controle caixa"
                  value="24"
                  onChange={() => {
                    if (permissoes?.includes(24)) {
                      setPermissoes(permissoes?.filter((f) => f !== 24));
                    } else {
                      setPermissoes(permissoes.concat(24));
                    }
                  }}
                  checked={permissoes?.includes(24)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="25"
                  label="Gestão consolidação"
                  value="25"
                  onChange={() => {
                    if (permissoes?.includes(25)) {
                      setPermissoes(permissoes?.filter((f) => f !== 25));
                    } else {
                      setPermissoes(permissoes.concat(25));
                    }
                  }}
                  checked={permissoes?.includes(25)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="26"
                  label="Vendas"
                  value="26"
                  onChange={() => {
                    if (permissoes?.includes(26)) {
                      setPermissoes(permissoes?.filter((f) => f !== 26));
                    } else {
                      setPermissoes(permissoes.concat(26));
                    }
                  }}
                  checked={permissoes?.includes(26)}
                />
              </Col>
            </Row>
            <Row>
              <h5>Uneo Green</h5>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="300"
                  label="Sorteios"
                  onChange={() => {
                    if (permissoes?.includes(300)) {
                      setPermissoes(permissoes?.filter((f) => f !== 300));
                    } else {
                      setPermissoes(permissoes.concat(300));
                    }
                  }}
                  checked={permissoes?.includes(300)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="301"
                  label="Valor Cupom"
                  onChange={() => {
                    if (permissoes?.includes(301)) {
                      setPermissoes(permissoes?.filter((f) => f !== 301));
                    } else {
                      setPermissoes(permissoes.concat(301));
                    }
                  }}
                  checked={permissoes?.includes(301)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="302"
                  label="Valor prêmio"
                  onChange={() => {
                    if (permissoes?.includes(302)) {
                      setPermissoes(permissoes?.filter((f) => f !== 302));
                    } else {
                      setPermissoes(permissoes.concat(302));
                    }
                  }}
                  checked={permissoes?.includes(302)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="303"
                  label="Cupons"
                  onChange={() => {
                    if (permissoes?.includes(303)) {
                      setPermissoes(permissoes?.filter((f) => f !== 303));
                    } else {
                      setPermissoes(permissoes.concat(303));
                    }
                  }}
                  checked={permissoes?.includes(303)}
                />
              </Col>
              <Col lg={4} md={4} sm={6}>
                <CheckBox
                  name="304"
                  label="Histórico de sorteios"
                  onChange={() => {
                    if (permissoes?.includes(304)) {
                      setPermissoes(permissoes?.filter((f) => f !== 304));
                    } else {
                      setPermissoes(permissoes.concat(304));
                    }
                  }}
                  checked={permissoes?.includes(304)}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Usuarios;
