/* eslint-disable react/destructuring-assignment */
import { Form } from '@unform/web';
import React, { useRef, useEffect } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { NumberFormat } from '../../components/unform/index';
import api from '../../services/api';

function ValorCupom() {
  const formRef = useRef();

  const handleSubmit = async (data) => {
    const errors = {};

    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      try {
        await api.post('/valor-cupom-backoffice', data);
        toast.success('Salvo com sucesso!');
      } catch (error) {
        toast.error('Erro ao salvar!');
      }
    }
  };

  useEffect(() => {
    async function getDados() {
      const { data } = await api.get('/valor-cupom-backoffice');

      if (data) {
        formRef.current.setFieldValue('valor', data?.valor);
      }
    }

    getDados();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Valor cupom</h4>
        </Col>
      </Row>
      <Card>
        <Form ref={formRef} onSubmit={(data) => handleSubmit(data)}>
          <CardBody>
            <Row>
              <Col lg={6}>
                <NumberFormat
                  name="valor"
                  label="Valor"
                  className="form-control"
                  number
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default ValorCupom;
