/* eslint-disable prefer-template */
/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState, useRef } from 'react';
import { FiFilter } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FaChevronRight, FaChevronLeft, FaEllipsisH } from 'react-icons/fa';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  Pagination,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Input, ReactSelectAsync, Select } from '../../components/unform/index';
import api from '../../services/api';

const showLoader = () =>
  Swal.fire({
    title: 'Aguarde',
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

const closeLoader = () => {
  Swal.close();
};

function Blog() {
  const formRef = useRef();
  const [dados, setDados] = useState({
    total: 0,
    pages: 1,
    page: 1,
    items: [],
  });
  const selectOptions = useRef();

  async function filtrarConsulta(inputValue) {
    const { data } = await api.get(`/categorias?titulo=${inputValue}`);
    return data.map((e) => ({ value: e.id, label: e.titulo }));
  }

  const optionsCategorias = async (inputValue) =>
    await new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  async function handleSubmit(page = 0) {
    showLoader();
    const query = Object.entries(formRef.current.getData()).reduce(
      (a, [key, value]) => {
        if (value) {
          return `${a}&${key}=${value}`;
        }

        return a;
      },
      `page=${Number(page) + 1}`
    );

    try {
      const { data } = await api.get(`/blog?${query}`);
      setDados(data);
    } catch (err) {
      toast.error('Erro ao buscar dados');
    } finally {
      closeLoader();
    }
  }

  useEffect(() => {
    async function getData() {
      showLoader();
      try {
        const { data } = await api.get('/blog');
        setDados(data);
      } catch (err) {
        toast.error('Erro ao buscar dados');
      } finally {
        closeLoader();
      }
    }
    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Post blog</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.total || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Link className="btn btn-primary" to="/blog/novo">
            Novo
          </Link>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Form ref={formRef} onSubmit={() => handleSubmit(0)}>
              <Row>
                <Col lg={4}>
                  <Input
                    name="titulo"
                    className="form-control"
                    placeholder="Título"
                  />
                </Col>
                <Col lg={4}>
                  <ReactSelectAsync
                    isClearable
                    loadOptions={(valor) => optionsCategorias(valor)}
                    placeholder="Categoria"
                    name="id_blog_categoria"
                    defaultOptions
                    onChange={() => formRef.current.submitForm()}
                  />
                </Col>
                <Col lg={4}>
                  <Select
                    name="status"
                    className="form-control"
                    onChange={() => formRef.current.submitForm()}
                    data={[
                      {
                        label: 'Status',
                        value: '',
                      },
                      {
                        label: 'Inativo',
                        value: 'Inativo',
                      },
                      {
                        label: 'Ativo',
                        value: 'Ativo',
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Título</th>
              <th>Categoria</th>
              <th>Status</th>
              <th className="text-center">Editar</th>
            </tr>
          </thead>
          <tbody>
            {dados?.items?.map((m) => (
              <tr key={m?.id}>
                <td>{m?.titulo}</td>
                <td>{m?.BlogCategorium?.titulo}</td>
                <td>{m?.status}</td>
                <td className="justify-center">
                  <Link to={'/blog/novo?id=' + (m || {})?.id || ''}>
                    Editar
                  </Link>
                </td>
              </tr>
            ))}
            {!dados?.total && (
              <tr>
                <td colSpan={5} className="text-center pt-3">
                  Sem registros encontrados
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.page || 1}
          onPageChange={({ selected }) => handleSubmit(selected)}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Blog;
