import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as auth from '../services/mockAuth';
import api from '../services/api';
import apiFinanceiro from '../services/apiFinanceiro';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [acessos, setAcessos] = useState([]);
  const [modulo, setModuloEscolhido] = useState(null);
  useEffect(() => {
    async function loadStorageData() {
      const {
        user: storagedUser,
        token: storagedToken,
        acessos: storagedAcessos,
        modulo: storagedModulo,
      } = localStorage;
      if (storagedUser && storagedToken) {
        if (storagedModulo) {
          setModuloEscolhido(storagedModulo);
        }
        setUser(JSON.parse(storagedUser));
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        api.interceptors.response.use(
          (res) => res,
          (error) => {
            if (error.response.status === 401 || error.status === 401) {
              setUser(null);
              localStorage.clear();
              return Promise.reject(error);
            }
            return Promise.reject(error);
          }
        );

        apiFinanceiro.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        apiFinanceiro.interceptors.response.use(
          (res) => res,
          (error) => {
            if (error.response.status === 401 || error.status === 401) {
              setUser(null);
              localStorage.clear();
              return Promise.reject(error);
            }
            return Promise.reject(error);
          }
        );
      }

      if (storagedAcessos) {
        setAcessos(JSON.parse(storagedAcessos));
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  // eslint-disable-next-line consistent-return
  async function signIn({ senha, email }) {
    try {
      setLoading(true);
      const response = await api.post('/sessions', {
        email,
        senha,
      });

      api.defaults.headers.Authorization = `Bearer ${response?.data?.token}`;
      api.interceptors.response.use(
        (res) => res,
        (error) => {
          if (error.response.status === 401 || error.status === 401) {
            setUser(null);
            localStorage.clear();
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );

      apiFinanceiro.defaults.headers.Authorization = `Bearer ${response?.data?.token}`;
      apiFinanceiro.interceptors.response.use(
        (res) => res,
        (error) => {
          if (error.response.status === 401 || error.status === 401) {
            setUser(null);
            localStorage.clear();
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );
      setUser(response?.data?.user);
      setAcessos(response?.data?.acessos);

      localStorage.setItem('acessos', JSON.stringify(response?.data?.acessos));
      localStorage.setItem('user', JSON.stringify(response?.data?.user));
      localStorage.setItem('token', response?.data?.token);
      setLoading(false);
      window.scrollTo(0, 0);
    } catch (error) {
      setLoading(false);

      return Promise.reject(error?.response?.data);
    }
  }

  async function signOut() {
    localStorage.clear();
    setUser(null);
    setModuloEscolhido(null);
  }

  async function changeName(nome) {
    const { user: storagedUser } = localStorage;
    const userLocal = JSON.parse(storagedUser);
    userLocal.nome = nome;
    setUser(userLocal);
    localStorage.setItem('user', JSON.stringify(userLocal));
  }

  function setModulo(moduloEscolhido) {
    setModuloEscolhido(moduloEscolhido);
    localStorage.setItem('modulo', moduloEscolhido);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signIn,
        signOut,
        acessos,
        changeName,
        modulo,
        setModulo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
