/* eslint-disable prefer-template */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaEdit,
  FaTrashAlt,
  FaSearch,
  FaArrowAltCircleDown,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardText,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  Button,
  Input,
  ButtonGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from 'reactstrap';
import Loading from './Skeleton';

import api from '../../services/apiFinanceiro';
import { ReactSelectAsync } from '../../components/index';
import getOptions from '../../utils/getOptions';
// import Select from '../../components/SearchSelect/Filter';

const today = new Date();
today.setHours(0, 0, 0, 0);

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

const Filtros = ({ arr = [] }) =>
  arr.map((e) => {
    if (e.value === 'tipo') {
      return (
        <Col lg={3} md={4} sm={6} key={e.value}>
          <span>{e.label}</span>
          <Input type="select" id={`fil_${e.value}`} name={e.value}>
            <option value="D">Despesa</option>
            <option value="R">Receita</option>
          </Input>
        </Col>
      );
    }
    return (
      <Col lg={3} md={4} sm={6} key={e.value}>
        <span>{e.label}</span>
        <Input type="text" id={`fil_${e.value}`} name={e.value} />
      </Col>
    );
  });
let debounceTimeout = null;

const ConsultaLancamento = (props) => {
  const [dados, setDados] = React.useState({ paginaInicial: 0 });
  const [loading, setLoading] = React.useState(true);
  const [parcela, setParcela] = React.useState(null);
  const [status, setStatus] = React.useState('Aberto');
  const [cliente, setCliente] = React.useState(null);
  const [documento, setDocumento] = React.useState('');
  const [tipo, setTipo] = React.useState('');
  const [ano, setAno] = React.useState(moment().year());
  const [mes, setMes] = React.useState(
    `${moment().month() + 1}`.padStart(2, '0')
  );

  const dateRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  function handeDeleteLancamento(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja cancelar esta parcela?',
        text: 'A parcela será cancelada e desconsiderada das projeções',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/despesa-receita/${id}/parcela`)
            .then((r) => {
              swalWithBootstrapButtons.fire(
                'Lançamento cancelado!',
                '',
                'success'
              );
              history.push('/lancamento');
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Ocorreu um erro, tente novamente mais tarde!',
                '',
                'error'
              );
            });
        }
      });
  }

  async function handleChange(selected) {
    if (!location.search.includes('page') && selected === 0) {
      return;
    }
    history.push(`lancamento?page=${selected + 1}`);
  }

  async function handleBaixa() {
    const date = dateRef.current.value;
    if (!date) {
      toast.error('Por favor digite uma data válida');
      return null;
    }
    try {
      const body = {
        data_pagamento: date,
        status: 'Fechado',
        valor_pago: parcela.valor,
      };
      await api.put(`/despesa-receita/${parcela.id}/parcela`, body);
      history.push('/lancamento');
      setParcela(null);
      toast.success('Parcela baixada com sucesso!');
    } catch (err) {
      toast.error('Ocorreu um erro ao salvar!');
    }
    return true;
  }

  useEffect(() => {
    async function getDespesas() {
      try {
        const params = location.search.split('&');
        const strPagina = params.find((e) => e.includes('page'));
        const pagina = Number((strPagina && strPagina?.split('=')[1]) || 1);
        let filtro = '';
        if (cliente) {
          filtro += `&cliente_fornecedor=${cliente}`;
        }
        if (documento) {
          filtro += `&documento=${documento}`;
        }
        if (tipo) {
          filtro += `&tipo=${tipo}`;
        }

        const url = `/despesa-receita?ano=${ano}&mes=${mes}&status=${status}&page=${pagina}${filtro}`;
        const { data, headers } = await api.get(url);
        const { 'x-total-count': total, 'x-total-pages': totalPaginas } =
          headers;

        setDados({
          lancamentos: data,
          total: Number(total),
          totalPaginas: Number(totalPaginas),
        });
        setLoading(false);

        return null;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    getDespesas();
  }, [status, mes, ano, location, cliente, documento, tipo]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Lançamentos</h4>
        </Col>
      </Row>
      <Card className="mb-3" style={{ padding: 0 }}>
        <CardBody>
          <Row>
            <Col
              lg={12}
              className="justify-content-between d-sm-grid d-md-flex mb-3 mt-1"
            >
              <CardText>Total de registros: {dados?.total || 0}</CardText>
              <Button
                color="muted"
                id="toggler"
                className="d-flex align-items-center justify-content-center"
              >
                <FiFilter size={20} />
                &nbsp;&nbsp;Filtros
              </Button>

              <div className="d-flex justify-content-center">
                <Link
                  to="/lancamento/cadastro"
                  className="btn btn-primary ml-3"
                >
                  Novo
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mb-2">
              <ButtonGroup className="mb-3">
                <Button
                  className="btn-md"
                  color={`${
                    mes === '01' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('01')}
                >
                  Jan.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '02' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('02')}
                >
                  Fev.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '03' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('03')}
                >
                  Mar.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '04' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('04')}
                >
                  Abr.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '05' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('05')}
                >
                  Mai.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '06' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('06')}
                >
                  Jun.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '07' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('07')}
                >
                  Jul.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '08' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('08')}
                >
                  Ago.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '09' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('09')}
                >
                  Set.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '10' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('10')}
                >
                  Out.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '11' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('11')}
                >
                  Nov.
                </Button>
                <Button
                  className="btn-md"
                  color={`${
                    mes === '12' ? 'primary active' : 'outline-primary'
                  }`}
                  onClick={() => setMes('12')}
                >
                  Dez.
                </Button>
              </ButtonGroup>
              <UncontrolledCollapse toggler="#toggler" className="toggle-bg">
                <Row className="justify-between px-1 mb-1">
                  <Col>
                    <FormGroup>
                      <label>Cliente/Fornecedor</label>
                      <ReactSelectAsync
                        isClearable
                        loadOptions={async (value) => {
                          const data = await getOptions({
                            value,
                            route: '/cliente-fornecedor',
                          });
                          return data;
                        }}
                        placeholder=" "
                        onChange={(opt) => setCliente(opt?.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Documento</label>
                      <Input
                        type="text"
                        onChange={(e) => {
                          clearTimeout(debounceTimeout);
                          debounceTimeout = setTimeout(() => {
                            setDocumento(e.target.value);
                          }, 400);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Tipo</label>
                      <Input
                        type="select"
                        onChange={async (e) => {
                          setTipo(e.target.value);
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="D">Despesa</option>
                        <option value="R">Receita</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Ano</label>
                      <Input
                        type="select"
                        onChange={async (e) => {
                          setAno(e.target.value);
                        }}
                        defaultValue={moment().year()}
                      >
                        {Array.from(Array(10).keys()).map((m) => {
                          const hoje = new Date().getFullYear();
                          const year = hoje - (m - 4);

                          return (
                            <option
                              value={year}
                              key={year}
                              defaultChecked={hoje === year}
                            >
                              {year}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label>Status</label>
                      <Input
                        type="select"
                        onChange={async (e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="Aberto">Abertos</option>
                        <option value="Cancelado">Cancelados</option>
                        <option value="Pago">Pago</option>
                        <option value="">Todos</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </UncontrolledCollapse>
            </Col>
          </Row>
          {loading ? (
            <Loading />
          ) : dados?.total > 0 ? (
            <>
              <div className="mx--15">
                <Table className="mb-0 rwd-table" responsive>
                  <thead>
                    <tr>
                      <th scope="col" className="bt-0 t-left">
                        Vencimento
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Lançamento
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Tipo Lançamento
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Documento
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Parcela
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Cliente/Fornecedor
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Valor
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Total
                      </th>
                      <th scope="col" className="bt-0 t-left">
                        Status
                      </th>
                      <th scope="col" className="bt-0 d-flex justify-center">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dados?.lancamentos?.map((lancamento) => (
                      <tr key={lancamento?.id}>
                        <td className="t-left" data-label="Data vencimento">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {new Date(
                              lancamento?.data_vencimento
                            ).toLocaleDateString('pt-BR')}
                          </Link>
                        </td>
                        <td className="t-left" data-label="Data lançamento">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {new Date(
                              lancamento?.despesa_receita?.data_lancamento
                            ).toLocaleDateString('pt-BR')}
                          </Link>
                        </td>
                        <td className="t-left" data-label="Tipo">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            <p
                              className={
                                lancamento?.despesa_receita?.tipo_lancamento
                                  ?.tipo === 'D'
                                  ? 'text-red'
                                  : 'text-green'
                              }
                            >
                              {
                                lancamento?.despesa_receita?.tipo_lancamento
                                  ?.tipo
                              }{' '}
                              -{' '}
                              {
                                lancamento?.despesa_receita?.tipo_lancamento
                                  ?.nome
                              }
                            </p>
                          </Link>
                        </td>
                        <td className="t-left" data-label="documento">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {lancamento?.despesa_receita?.documento}
                          </Link>
                        </td>
                        <td className="t-left" data-label="parcela">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {lancamento?.parcela}/
                            {lancamento?.despesa_receita?.quantidade_parcelas}
                          </Link>
                        </td>
                        <td className="t-left" data-label="Cliente/Fornecedor">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {
                              lancamento?.despesa_receita
                                ?.despesa_receita_cliente_fornecedor
                                ?.cliente_forn
                            }
                          </Link>
                        </td>
                        <td className="t-left" data-label="valor">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {Number(lancamento?.valor || 0).toLocaleString(
                              'pt-BR',
                              {
                                currency: 'BRL',
                                style: 'currency',
                              }
                            )}
                          </Link>
                        </td>
                        <td className="t-left" data-label="total">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {Number(
                              lancamento?.despesa_receita?.valor_total || 0
                            ).toLocaleString('pt-BR', {
                              currency: 'BRL',
                              style: 'currency',
                            })}
                          </Link>
                        </td>
                        <td className="t-left" data-label="total">
                          <Link
                            to={
                              '/lancamento/cadastro?id=' +
                              lancamento?.despesa_receita?.id
                            }
                            className="text-grey"
                          >
                            {lancamento?.status}
                          </Link>
                        </td>
                        <td data-label="Ações">
                          <div className="d-flex justify-content-center">
                            <UncontrolledButtonDropdown>
                              <DropdownToggle
                                color="link"
                                className=" text-decoration-none padding-0-5"
                              >
                                <FaCog className="mr-2" /> <FaAngleDown />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <Link
                                  to={
                                    '/lancamento/cadastro?id=' +
                                    lancamento?.despesa_receita?.id
                                  }
                                >
                                  <DropdownItem>
                                    <FaEdit className="mr-1" />
                                    Editar
                                  </DropdownItem>
                                </Link>
                                {lancamento?.status === 'Aberto' && (
                                  <>
                                    <DropdownItem
                                      onClick={() =>
                                        setParcela({
                                          valor: lancamento.valor,
                                          id: lancamento?.id,
                                          id_despesa_receita:
                                            lancamento.despesa_receita.id,
                                        })
                                      }
                                    >
                                      <FaArrowAltCircleDown className="mr-1" />
                                      Baixar
                                    </DropdownItem>
                                    <hr />
                                    <DropdownItem
                                      className="color-danger"
                                      onClick={() =>
                                        handeDeleteLancamento(lancamento?.id)
                                      }
                                    >
                                      <FaTrashAlt className="mr-1" />
                                      Excluir
                                    </DropdownItem>
                                  </>
                                )}
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <Row style={{ paddingTop: 30, paddingBottom: 0 }}>
              <Col className="mb-8">
                <div className="d-flex justify-center">
                  <strong style={{ color: '#ccc' }}>
                    Nenhum registro encontrado!
                  </strong>{' '}
                </div>
              </Col>
              <Col lg={12}>
                <div className="d-flex justify-center">
                  <h1>Sem registros</h1>
                </div>
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end">
            <ReactPaginate
              nextLabel={<FaChevronRight />}
              pageRangeDisplayed={2}
              previousLabel={<FaChevronLeft />}
              pageCount={Number(dados.totalPaginas || 0)}
              onPageChange={({ selected }) => handleChange(selected)}
              initialPage={dados.paginaInicial}
              forcePage={dados.paginaInicial}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </CardBody>
      </Card>
      <Modal isOpen={parcela} toggle={() => setParcela(null)}>
        <ModalHeader toggle={() => setParcela(null)}>Baixar</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <FormGroup>
                <Label for="dataVencimentoParcela">Data pagamento</Label>
                <Input
                  type="date"
                  name="dataPagamentoParcela"
                  innerRef={dateRef}
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => setParcela(null)}>
            Cancelar
          </Button>
          <Button color="success" onClick={handleBaixa}>
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConsultaLancamento;
