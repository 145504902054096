/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import ReactJson from 'react-json-view';
import api from '../../services/api';

function chunkSubstr(str, size = 5) {
  if (str) {
    const numChunks = Math.ceil(str.length / size);
    const chunks = new Array(numChunks);

    for (let i = 0, o = 0; i < numChunks; i += 1, o += size) {
      chunks[i] = str.substr(o, size);
    }

    return chunks;
  }
}
// import { Container } from './styles';

function Monitoria() {
  const [log, setLog] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modalCard, setModalCard] = useState(false);
  const [bodys, setBody] = useState([]);

  let timeoutConsulta;

  const toggleModalCard = () => setModalCard(!modalCard);

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });
  useEffect(() => {
    async function getLog() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/monitoria?${params}`);
      setLog(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getLog();
  }, [filtro]);

  async function getBody(id) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get(`/monitoria-detalhe/${id}`);
      setBody(data);
      Swal.close();
      toggleModalCard();
    } catch (err) {
      toast.error('Erro ao buscar log!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Monitoria</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3  d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted align-items-center">
              Total de registros: {log?.total || 0}
            </h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>

        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              {/* <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'cpf')}
                  className="form-control"
                  placeholder="Documento"
                />
              </Col> */}
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'tipo')}
                  className="form-control"
                  placeholder="Tipo"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'url')}
                  className="form-control"
                  placeholder="Url"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'method')}
                  className="form-control"
                  placeholder="Método"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'idempotency_key')
                  }
                  className="form-control"
                  placeholder="idempotency_key"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              {/* <th>Documento</th> */}
              <th>Tipo</th>
              <th>Url</th>
              <th>Método</th>
              <th>Status</th>
              <th>idempotency_key</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {log.logs?.length ? (
              log.logs?.map((tar) => (
                <>
                  <tr key={tar?.id}>
                    <td>{new Date(tar?.createdAt)?.toLocaleString()}</td>
                    <td>{tar?.tipo}</td>
                    <td>{tar?.url?.split('?')[0]}</td>
                    <td>{tar?.method}</td>
                    <td>{tar?.status}</td>
                    <td>{tar?.idempotency_key}</td>
                    <td>
                      {log && (
                        <UncontrolledDropdown size="xs">
                          <DropdownToggle color="link link-gray" size="xs">
                            <FaEllipsisH size="1.3em" />
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem
                              onClick={() => getBody(tar?.id)}
                              size="xs"
                            >
                              Visualizar
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(log.pages) || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modalCard} toggle={toggleModalCard} size="xl">
        <ModalHeader toggle={toggleModalCard}>Corpo da requisição</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <p>Tipo:</p>
            </Col>
            <Col lg={8}>
              <h6>
                <strong>{bodys?.tipo ? bodys?.tipo : ''}</strong>
              </h6>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>idempotency_key:</p>
            </Col>
            <Col lg={8}>
              <h6>
                <strong>
                  {bodys?.idempotency_key ? bodys?.idempotency_key : ''}
                </strong>
              </h6>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h6>Método HTTP:</h6>
            </Col>
            <Col lg={9}>
              <p>
                <strong>{bodys?.method ? bodys?.method : ''}</strong>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>URL:</h6>
            </Col>
            <Col lg={9}>
              <p>
                <strong>{bodys?.url ? chunkSubstr(bodys?.url) : ''}</strong>
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>Body</p>
              {bodys?.body ? (
                <ReactJson
                  displayDataTypes={false}
                  iconStyle="circle"
                  displayObjectSize={false}
                  indentWidth={3}
                  collapsed={false}
                  src={
                    bodys?.body
                      ? typeof bodys?.body === 'string'
                        ? JSON.parse(bodys?.body)
                        : bodys?.body
                      : {}
                  }
                />
              ) : (
                'Sem BODY na requisição'
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p>Response</p>
              {bodys?.response ? (
                <ReactJson
                  displayDataTypes={false}
                  iconStyle="circle"
                  displayObjectSize={false}
                  indentWidth={3}
                  collapsed={false}
                  src={
                    bodys?.response
                      ? typeof bodys?.response === 'string'
                        ? JSON.parse(bodys?.response)
                        : bodys?.response
                      : {}
                  }
                />
              ) : (
                'Sem RESPONSE na requisição'
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalCard}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Monitoria;
