/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import {
  Card,
  CardBody,
  Row,
  Table,
  Label,
  Input,
  FormGroup,
  Col,
} from 'reactstrap';
import { FaBan } from 'react-icons/fa';
import api from '../../services/api';

const Demonstrativo = (props) => {
  const [ano, setAno] = React.useState(new Date().getFullYear());

  const [planejamento, setPlanejamento] = React.useState(null);
  const [dre, setDre] = React.useState(null);

  const [getInitData, setGetInitData] = React.useState(false);

  useEffect(() => {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    async function initDataPlanejamento() {
      try {
        const { data } = await api.get(`/planejamentofinanceiro/${ano}`);
        const { data: dataDRE } = await api.get(`/dre/${ano}`);
        if (data && dataDRE) {
          setPlanejamento(data);
          setDre(dataDRE);
          Swal.close();
        } else {
          setPlanejamento(null);
          setDre(null);
          Swal.close();
        }
      } catch (e) {
        toast.error('Erro ao carregar dados!');
      }
    }
    initDataPlanejamento();
    setGetInitData(false);
  }, [ano, getInitData]);

  function retornaValor(tipo, mr, mes, format = false) {
    const result =
      planejamento?.[tipo]?.find((f) => f._id === mes)?.[mr] || '0,00';

    return format
      ? result.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : result;
  }

  function retornaValorDreImposto(mr, mes, format = false) {
    const result = dre?.find((f) => f.mes === mes) || 0;

    return format
      ? result?.[mr]?.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : result?.[mr];
  }

  function calculaMargemContribuicao(val1, val2, format = false) {
    const result = parseFloat(val1) - parseFloat(val2);
    return format
      ? result.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : result;
  }

  function calculaTotalMargemContribuicao() {
    const result =
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 1),
          retornaValor('despesas', 'realizadodespesa', 1)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 2),
          retornaValor('despesas', 'realizadodespesa', 2)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 3),
          retornaValor('despesas', 'realizadodespesa', 3)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 4),
          retornaValor('despesas', 'realizadodespesa', 4)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 5),
          retornaValor('despesas', 'realizadodespesa', 5)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 6),
          retornaValor('despesas', 'realizadodespesa', 6)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 7),
          retornaValor('despesas', 'realizadodespesa', 7)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 8),
          retornaValor('despesas', 'realizadodespesa', 8)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 9),
          retornaValor('despesas', 'realizadodespesa', 9)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 10),
          retornaValor('despesas', 'realizadodespesa', 10)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 11),
          retornaValor('despesas', 'realizadodespesa', 11)
        )
      ) +
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', 12),
          retornaValor('despesas', 'realizadodespesa', 12)
        )
      );
    return result;
  }

  function calculaTotalImpostos() {
    const result = dre?.reduce((a, b) => a + b?.impostos, 0);

    return result;
  }

  function calculaLucroPrejuizoMes(m, format = false) {
    const result =
      parseFloat(
        calculaMargemContribuicao(
          retornaValor('receitas', 'realizadoreceita', m),
          retornaValor('despesas', 'realizadodespesa', m)
        )
      ) - parseFloat(retornaValorDreImposto('impostos', m) || 0);

    return format
      ? result.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : result;
  }

  function calculaTotalLucroPrejuizoMes() {
    const resultArray = [];
    for (let index = 1; index < 12; index += 1) {
      resultArray.push(parseFloat(calculaLucroPrejuizoMes(index)));
    }

    const result = resultArray.reduce((a, b) => a + b, 0);
    return result;
  }

  function verificavalorDosCampos(m) {
    const result =
      retornaValor('receitas', 'realizadoreceita', m, true) !== '0,00';
    return result;
  }

  async function attImposto(valor, mes) {
    const dreFilter = dre.filter((d) => d.ano === ano && d.mes === mes)[0];

    if (dreFilter) {
      try {
        const removerMaiorQueMil = valor.replace('.', '');
        const valorReplace = removerMaiorQueMil.replace(',', '.');
        const body = { impostos: valorReplace };
        await api.put(`/dre/${dreFilter._id}`, body);
        setGetInitData(true);
      } catch (e) {
        toast.error('Erro ao realizar cáculo!');
      }
    } else {
      try {
        const removerMaiorQueMil = valor.replace('.', '');
        const valorReplace = removerMaiorQueMil.replace(',', '.');
        const body = { impostos: valorReplace, mes, ano };
        await api.post('/dre', body);
        setGetInitData(true);
      } catch (e) {
        toast.error('Erro ao realizar cáculo!');
      }
    }
  }

  return (
    <>
      <Row className="mb-2 mt-0">
        <Col md={2}>
          <div className="d-flex">
            <Input
              type="select"
              onChange={async (e) => {
                setAno(e.target.value);
              }}
              defaultValue={moment().year()}
              style={{ marginRight: 5 }}
            >
              {Array.from(Array(10).keys()).map((m) => {
                const hoje = new Date().getFullYear();
                const year = hoje - (m - 4);

                return (
                  <option
                    value={year}
                    key={year}
                    defaultChecked={hoje === year}
                  >
                    {year}
                  </option>
                );
              })}
            </Input>
          </div>
        </Col>
      </Row>
      <Card className="mb-0">
        <CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row>
            <Table className="mb-0 rwd-table table-fixed table-dre">
              <thead style={{ fontSize: 10 }}>
                <tr>
                  <th className="bt-0 t-center">DRE</th>
                  <th className="bt-0 t-left">Jan</th>
                  <th className="bt-0 t-left">Fev</th>
                  <th className="bt-0 t-left">Mar</th>
                  <th className="bt-0 t-left">Abr</th>
                  <th className="bt-0 t-left">Mai</th>
                  <th className="bt-0 t-left">Jun</th>
                  <th className="bt-0 t-left">Jul</th>
                  <th className="bt-0 t-left">Ago</th>
                  <th className="bt-0 t-left">Set</th>
                  <th className="bt-0 t-left">Out</th>
                  <th className="bt-0 t-left">Nov</th>
                  <th className="bt-0 t-left">Dez</th>
                  <th className="bt-0 t-left">Total</th>
                </tr>
              </thead>
              <tbody style={{ borderBottomWidth: 10 }}>
                <tr>
                  <td className="t-right">
                    <p style={{ fontSize: 12, color: 'lime' }}>
                      Receitas <br />
                      R$
                    </p>
                  </td>
                  <td className="t-left" data-label="Janeiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 1, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Fevereiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 2, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Março">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 3, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Abril">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 4, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Maio">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 5, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Junho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 6, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Julho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 7, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Agosto">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 8, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Setembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 9, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Outubro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 10, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Novembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 11, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Dezembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('receitas', 'realizadoreceita', 12, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Total">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {planejamento?.receitas
                          ?.reduce((a, b) => a + b?.realizadoreceita, 0)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                <tr style={{ paddingBottom: 0 }}>
                  <td className="t-right" data-label="">
                    <p style={{ fontSize: 12, color: 'orangered' }}>
                      Despesas <br />
                      R$
                    </p>
                  </td>
                  <td className="t-left" data-label="Janeiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 1, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Fevereiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 2, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Março">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 3, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Abril">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 4, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Maio">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 5, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Junho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 6, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Julho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 7, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Agosto">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 8, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Setembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 9, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Outubro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 10, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Novembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 11, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Dezembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {retornaValor('despesas', 'realizadodespesa', 12, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Total">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {planejamento?.despesas
                          ?.reduce((a, b) => a + b?.realizadodespesa, 0)
                          .toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="t-right" nowrap>
                    <p style={{ fontSize: 12, color: 'SlateBlue' }}>
                      Marg. C. <br />
                      R$
                    </p>
                  </td>
                  <td className="t-left" data-label="Janeiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 1),
                          retornaValor('despesas', 'realizadodespesa', 1),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Fevereiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 2),
                          retornaValor('despesas', 'realizadodespesa', 2),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Março">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 3),
                          retornaValor('despesas', 'realizadodespesa', 3),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Abril">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 4),
                          retornaValor('despesas', 'realizadodespesa', 4),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Maio">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 5),
                          retornaValor('despesas', 'realizadodespesa', 5),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Junho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 6),
                          retornaValor('despesas', 'realizadodespesa', 6),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Julho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 7),
                          retornaValor('despesas', 'realizadodespesa', 7),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Agosto">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 8),
                          retornaValor('despesas', 'realizadodespesa', 8),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Setembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 9),
                          retornaValor('despesas', 'realizadodespesa', 9),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Outubro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 10),
                          retornaValor('despesas', 'realizadodespesa', 10),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Novembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 11),
                          retornaValor('despesas', 'realizadodespesa', 11),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Dezembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaMargemContribuicao(
                          retornaValor('receitas', 'realizadoreceita', 12),
                          retornaValor('despesas', 'realizadodespesa', 12),
                          true
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Total">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaTotalMargemContribuicao().toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="t-right">
                    <p style={{ fontSize: 12, color: 'DodgerBlue' }}>
                      Impostos <br />
                      R$
                    </p>
                  </td>
                  <td className="t-left" data-label="Janeiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(1) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 1, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 1)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Fevereiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(2) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 2, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 2)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Março">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(3) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 3, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 3)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Abril">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(4) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 4, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 4)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Maio">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(5) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 5, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 5)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Junho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(6) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 6, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 6)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Julho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(7) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 7, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 7)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Agosto">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(8) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 8, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 8)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Setembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(9) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 9, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 9)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Outubro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(10) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 10, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 10)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Novembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(11) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 11, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 11)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Dezembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      {verificavalorDosCampos(12) ? (
                        <NumberFormat
                          style={{
                            fontSize: 11,
                            backgroundColor: 'var(--bg-placeholder)',
                            border: 'none',
                            color: 'var(--theme-menu-text-color-active)',
                            maxWidth: '100%',
                          }}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={
                            retornaValorDreImposto('impostos', 12, true) || ''
                          }
                          onBlur={(e) => attImposto(e.target.value, 12)}
                        />
                      ) : (
                        <p
                          style={{ fontSize: 9, paddingLeft: 10, color: 'red' }}
                        >
                          <FaBan />
                        </p>
                      )}
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Total">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label style={{ fontSize: 12 }}>
                        {calculaTotalImpostos()?.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                <tr>
                  <td className="t-right">
                    <p style={{ fontSize: 12, color: 'Orange' }}>
                      Lucro ou <br /> Prejuízo R$
                    </p>
                  </td>
                  <td className="t-left" data-label="Janeiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(1)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(1, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Fevereiro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(2)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(2, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Março">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(3)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(3, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Abril">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(4)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(4, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Maio">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(5)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(5, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Junho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(6)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(6, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Julho">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(7)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(7, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Agosto">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(8)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(8, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Setembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(9)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(9, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Outubro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(10)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(10, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Novembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(11)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(11, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Dezembro">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            parseFloat(calculaLucroPrejuizoMes(12)) >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaLucroPrejuizoMes(12, true)}
                      </Label>
                    </FormGroup>
                  </td>
                  <td className="t-left" data-label="Total">
                    <FormGroup style={{ marginBottom: 0 }}>
                      <Label
                        style={{
                          fontSize: 12,
                          color:
                            calculaTotalLucroPrejuizoMes() >= 0
                              ? 'lime'
                              : 'orangered',
                        }}
                      >
                        {calculaTotalLucroPrejuizoMes()?.toLocaleString(
                          'pt-BR',
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Demonstrativo;
