/* eslint-disable no-return-await */
/* eslint-disable react/void-dom-elements-no-children */
import React, { useEffect, useState, useRef } from 'react';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaTrashAlt,
  FaEye,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import api from '../../services/apiFinanceiro';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function ConsultaClienteFornecedor() {
  let timeoutConsulta;
  const history = useHistory();
  const [clienteFornecedor, setClienteFornecedor] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);

  const [filtros, setFiltros] = useState(null);

  const refNome = useRef(null);
  const refDocumento = useRef(null);

  const debounceConsulta = async (body) =>
    await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(setFiltros({ ...filtros, ...body }));
      }, 500);
    });

  useEffect(() => {
    async function getClienteFornecedor() {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        let filtroConsulta = '';
        if (filtros) {
          Object.entries(filtros).forEach(([key, value]) => {
            if (['string', 'number'].includes(typeof value)) {
              filtroConsulta += `${key}=${value}&`;
            } else if (value) {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          });
        }
        const { data, headers } = await api.get(
          `/cliente-fornecedor?page=${selectedPage}&${filtroConsulta}`
        );
        if (data) {
          setTotal(headers['x-total-count']);
          setPages(headers['x-total-pages']);
          setClienteFornecedor(data);
        }
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar dados!');
        Swal.close();
      }
    }
    getClienteFornecedor();
  }, [selectedPage, filtros]);

  function viewClienteFornecedor(id) {
    history.push(`/cliente-fornecedor/${id}`);
  }

  async function handleDeleteClienteFuncionario(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja deletar este Cliente / Fornecedor?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/cliente-fornecedor/${id}`)
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Cliente / Fornecedor excluído!',
                '',
                'success'
              );
              setTotal((p) => p - 1);
              setClienteFornecedor((cf) => cf.filter((e) => e.id !== id));
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Ocorreu um erro, tente novamente mais tarde!',
                '',
                'error'
              );
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Cliente Fornecedor</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {total || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              history.push('/novo-cliente-fornecedor');
            }}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  className="form-control"
                  placeholder="Nome"
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col>
                <input
                  className="form-control"
                  placeholder="Documento"
                  ref={refDocumento}
                  onChange={() =>
                    debounceConsulta({
                      documento: refDocumento?.current?.value,
                    })
                  }
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table className="mb-5" responsive>
          <thead>
            <tr>
              <th scope="col" className="bt-0 t-left">
                Nome
              </th>
              <th scope="col" className="bt-0 t-left">
                Tipo
              </th>
              <th scope="col" className="bt-0 t-left">
                Nome exibição
              </th>
              <th scope="col" className="bt-0 t-left">
                Documento
              </th>
              <th scope="col" className="bt-0 t-left">
                Celular / Telefone
              </th>
              <th scope="col" className="bt-0 t-left">
                Email
              </th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {clienteFornecedor?.length ? (
              clienteFornecedor?.map((cf) => (
                <tr key={cf?.id}>
                  <td className="t-left" data-label="Nome">
                    {cf?.nome}
                  </td>
                  <td className="t-left" data-label="Nome exibição">
                    {cf.tipo === 'C' ? 'Cliente' : 'Fornecedor'}
                  </td>
                  <td className="t-left" data-label="Nome exibição">
                    {cf.nome_exibicao}
                  </td>
                  <td className="t-left" data-label="Documento">
                    {cf?.documento}
                  </td>
                  <td className="t-left" data-label="Celular">
                    {cf?.codigo_pais} {cf?.celular}
                  </td>
                  <td className="t-left" data-label="Celular">
                    {cf?.email}
                  </td>
                  <td data-label="Ações" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => viewClienteFornecedor(cf.id)}
                        >
                          <FaEye className="mr-1" />
                          Vizualizar
                        </DropdownItem>
                        <hr />
                        <DropdownItem
                          className="color-danger"
                          onClick={() => handleDeleteClienteFuncionario(cf.id)}
                        >
                          <FaTrashAlt className="mr-1" />
                          Excluir
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={pages || 1}
          onPageChange={({ selected }) => setSelectedPage(selected + 1)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default ConsultaClienteFornecedor;
