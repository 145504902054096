function getVarValue(variable) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
}
const colors = {
  primary: () => getVarValue('--color-primary'),
  secondary: () => getVarValue('--color-secondary'),
  tertiary: () => getVarValue('--color-tertiary'),
  error: () => getVarValue('--color-error'),
  errorDarken: () => getVarValue('--color-error-darken'),
  success: () => getVarValue('--color-success'),
  successDarken: () => getVarValue('--color-success-darken'),
};

export { colors };
