import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { InputGroup, InputGroupAddon } from 'reactstrap';

export default function Select({
  name,
  label,
  type,
  prepend,
  append,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);
  return (
    <div className="form-group">
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <InputGroup
        className={error ? 'error flex-wrap-inherit' : 'flex-wrap-inherit'}
      >
        {prepend && (
          <InputGroupAddon
            addonType="prepend"
            className="d-flex justify-content-center align-items-center"
          >
            {prepend}
          </InputGroupAddon>
        )}

        <input
          ref={inputRef}
          defaultValue={defaultValue}
          type={type || 'text'}
          {...rest}
        />
        {append && (
          <InputGroupAddon
            className="d-flex justify-content-center align-items-center"
            addonType="append"
          >
            {append}
          </InputGroupAddon>
        )}
      </InputGroup>
    </div>
  );
}
