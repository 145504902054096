import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    id_cliente_fornecedor: Yup.number().required('Obrigatório').nullable(),
    id_tipo_contrato: Yup.number().required('Obrigatório').nullable(),

    data_assinatura: Yup.string().required('Obrigatório'),
    data_base_pagamento: Yup.string().required('Obrigatório'),
    data_fim_vigencia: Yup.string().required('Obrigatório'),
    data_inicio_vigencia: Yup.string().required('Obrigatório'),
    descricao: Yup.string().required('Obrigatório'),
    documento: Yup.string().required('Obrigatório'),
    quantidade_parcelas: Yup.string().required('Obrigatório'),
    // valor_entrada: Yup.string().required('Obrigatório'),
    valor_total: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
