import React, { useEffect, useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
} from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Select from '../../components/unform/select';
import Input from '../../components/unform/input';

function SolicitacoesEmprestimo() {
  const [creditoList, setCreditoList] = useState([]);
  const [filtro, setFiltro] = useState({
    status: 'Pendente',
  });
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState('');
  const formRef = useRef();
  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...(filtro || {}), [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getConta() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    try {
      const { data } = await api.get(`/listaemp?${params}`);

      setCreditoList(data);
      window.scrollTo(0, 0);

      Swal.close();
    } catch (err) {
      Swal.close();
      toast.error(err?.response?.data?.err || 'Ocorreu um erro');
    }
  }

  const toggleModal = () => {
    setModal(false);
    setStatus('');
  };

  async function handleResponder(dados) {
    const errors = {};
    if (dados.status !== 'Negado') {
      Object.entries(dados).forEach(([key, value]) => {
        if (!value) {
          errors[key] = 'Obrigatório';
        }
      });
    }
    formRef.current.setErrors(errors);

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos obrigatórios');
    }
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post(`/solicitacao-emprestimo/${modal?.id}`, dados);
      Swal.close();
      toggleModal();
      toast.success('Solicitação respondida');
      getConta();
    } catch (err) {
      Swal.close();
      toast.error(
        err?.response?.data?.error || 'Erro interno, tente novamente mais tarde'
      );
    }
  }

  useEffect(() => {
    if (filtro) {
      getConta();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Solicitação de empréstimo</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {creditoList.total}</h6>

          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <div />
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                >
                  <option value="Pendente">Pendente</option>
                  <option value="Aprovado">Aprovado</option>
                  <option value="Negado">Negado</option>
                  <option value="">Todos</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>CPF/CNPJ</th>
              <th>Nome</th>
              <th>Valor solicitado</th>
              <th>Observação</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {creditoList?.items?.length ? (
              creditoList?.items?.map((c) => (
                <tr key={c?.id}>
                  <td>{c?.Contum?.Pessoa?.documento}</td>
                  <td>{c?.Contum?.Pessoa?.nome_completo}</td>
                  <td>
                    {Number(c?.valor).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td>{c?.observacao}</td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                  <td data-label="Ação" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => setModal(c)}>
                          Responder
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={creditoList.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>
          {`${modal?.Contum?.Pessoa?.nome_completo} - ${modal?.Contum?.Pessoa?.documento}`}
        </ModalHeader>

        <Form ref={formRef} onSubmit={handleResponder}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <p className="bold">
                  Valor solicitado{' '}
                  {Number(modal?.valor).toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  })}
                </p>
              </Col>
              <Col lg={6} className="mb-3">
                <Select
                  name="status"
                  label="Aprovar/Negar"
                  className="form-control"
                  onChange={(e) => setStatus(e.target.value)}
                  data={[
                    { label: 'Selecione', value: '' },
                    { label: 'Aprovar', value: 'Aprovado' },
                    { label: 'Negar', value: 'Negado' },
                  ]}
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="observacao"
                  label="Observação"
                  placeholder="campo interno"
                  className="form-control"
                />
              </Col>
              {status === 'Aprovado' && (
                <>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="valor_concedido"
                      label="Valor concedido emprestimo"
                      className="form-control"
                      placeholder="0,00"
                      step={0.01}
                      type="number"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="fator_emprestimo"
                      label="Juros (%)"
                      className="form-control"
                      placeholder="%"
                      step={0.1}
                      type="number"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="parcelas"
                      label="Qtd. parcelas"
                      className="form-control"
                      placeholder="1"
                      step={1}
                      type="number"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="data_liberacao"
                      label="Data liberação empréstimo"
                      className="form-control"
                      type="date"
                    />
                  </Col>
                  <Col lg={6} className="mb-3">
                    <Input
                      name="data_primeira_cobranca"
                      label="Data primeira cobrança"
                      className="form-control"
                      type="date"
                    />
                  </Col>
                </>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default SolicitacoesEmprestimo;
