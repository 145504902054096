import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { NumberFormat } from '../../components/unform/index';

import api from '../../services/api';

function GestaoDeTarifas() {
  const formRef = useRef();
  const [taxas, setTaxas] = useState([]);

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    async function getTaxas() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data } = await api.get('/taxas');

        setTaxas(data);
        Swal.close();
      } catch (err) {
        toast.error('Erro ao buscar dados!');
        Swal.close();
      }
    }
    getTaxas();
  }, []);

  async function handleSubmit(data) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const obj = { taxasArray: [] };
      Object.entries(data).forEach(([key, value]) => {
        obj.taxasArray.push({
          parcela: key,
          taxa: Number(value) / 100,
        });
      });
      const { data: retorno } = await api.post('/taxas', obj);
      setTaxas(retorno);
      toggleModal();
      toast.success('Dados salvos com sucesso!');
      Swal.close();
    } catch (err) {
      toast.error('Erro ao salvar dados!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Gestão de tarifas</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-end d-flex align-items-center">
          <Button
            className="btn btn-primary"
            onClick={toggleModal}
            color="primary"
          >
            Adicionar
          </Button>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Parcela</th>
              <th style={{ textAlign: 'center' }}>Taxa</th>
            </tr>
          </thead>
          <tbody>
            {taxas?.length ? (
              taxas?.map((c) => (
                <tr key={c?.id}>
                  <td style={{ textAlign: 'center' }}>{c?.parcela}</td>
                  <td style={{ textAlign: 'center' }}>
                    {Number(Number(c?.taxa) * 100).toLocaleString('pt-BR')}%
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Taxas parcelas</ModalHeader>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <ModalBody>
            <Row>
              {taxas?.length ? (
                taxas?.map((c) => (
                  <Col lg={3}>
                    <NumberFormat
                      name={String(c?.parcela)}
                      label={String(c?.parcela)}
                      defaultValue={c?.taxa * 100}
                      className="form-control"
                      thousandSeparator="."
                      prefix="%"
                      min="0"
                      number
                    />
                  </Col>
                ))
              ) : (
                <p>Clique no botão + abaixo para adicionar parcelas</p>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                if (taxas.length < 24) {
                  setTaxas([
                    ...taxas,
                    {
                      parcela: Number(taxas.length) + 1,
                      taxa: 0,
                    },
                  ]);
                }
              }}
            >
              <FaPlus />
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setTaxas([...taxas.slice(0, -1)]);
              }}
            >
              <FaMinus />
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>{' '}
            <Button color="secondary" onClick={toggleModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default GestaoDeTarifas;
