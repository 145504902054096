import React from 'react';
import { Card, Col, Row, CardImg, CardBody } from 'reactstrap';
import UneoImage from '../../assets/images/uneohome.png';

function Home() {
  return (
    <>
      <div className="px-3 py-3 mt-3 container">
        <Row>
          <Col>
            <CardBody className="d-flex justify-content-center">
              <CardImg
                src={UneoImage}
                className="image-home"
                style={{
                  width: '80%',
                  marginTop: '4rem',
                }}
              />
            </CardBody>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
