/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import ReactJson from 'react-json-view';
import api from '../../services/api';

// import { Container } from './styles';

function Monitoria() {
  const [log, setLog] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modalCard, setModalCard] = useState(false);
  const [bodys, setBody] = useState([]);

  let timeoutConsulta;

  const toggleModalCard = () => setModalCard(!modalCard);

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });
  useEffect(() => {
    async function getLog() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/logs-celcoin?${params}`);
      setLog(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getLog();
  }, [filtro]);

  async function getBody(body, response) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      setBody({
        body,
        response,
      });
      Swal.close();
      toggleModalCard();
    } catch (err) {
      toast.error('Erro ao buscar log!');
      Swal.close();
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Log Celcoin</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3  d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted align-items-center">
              Total de registros: {log?.total || 0}
            </h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>

        <div className="filter-collapse px-3">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'tipo')}
                  className="form-control"
                  placeholder="Tipo"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'url')}
                  className="form-control"
                  placeholder="Url"
                />
              </Col>
              <Col>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'authcode')}
                  className="form-control"
                  placeholder="Codigo transação"
                />
              </Col>
              <Col lg={2}>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'data')}
                  className="form-control"
                  type="date"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Tipo</th>
              <th>Url</th>
              <th>Codigo transação</th>
              <th>Conta Id</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {log?.results?.length ? (
              log?.results?.map((tar) => (
                <tr key={tar?.id}>
                  <td>{new Date(tar?.createdAt)?.toLocaleString()}</td>
                  <td>{tar?.tipo}</td>
                  <td>{tar?.url}</td>
                  <td>{tar?.authcode}</td>
                  <td>{tar?.conta_id}</td>
                  <td>
                    {log && (
                      <UncontrolledDropdown size="xs">
                        <DropdownToggle color="link link-gray" size="xs">
                          <FaEllipsisH size="1.3em" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => getBody(tar?.body, tar?.response)}
                            size="xs"
                          >
                            Visualizar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(log.pages) || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modalCard} toggle={toggleModalCard} size="xl">
        <ModalHeader toggle={toggleModalCard}>Corpo da requisição</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <h5>Body</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              {bodys?.body ? (
                <>
                  {typeof bodys?.body === 'string' &&
                  bodys?.body?.includes('externalNSU') ? (
                    <div>{bodys?.body}</div>
                  ) : (
                    <ReactJson
                      displayDataTypes={false}
                      iconStyle="circle"
                      displayObjectSize={false}
                      indentWidth={3}
                      collapsed={false}
                      src={
                        bodys?.body
                          ? typeof bodys?.body === 'string'
                            ? JSON.parse(bodys?.body)
                            : bodys?.body
                          : {}
                      }
                    />
                  )}
                </>
              ) : (
                'Sem BODY na requisição'
              )}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <h5>Response</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              {bodys?.response ? (
                <ReactJson
                  displayDataTypes={false}
                  iconStyle="circle"
                  displayObjectSize={false}
                  indentWidth={3}
                  collapsed={false}
                  src={
                    bodys?.response
                      ? typeof bodys?.response === 'string'
                        ? JSON.parse(bodys?.response)
                        : bodys?.response
                      : {}
                  }
                />
              ) : (
                'Sem RESPONSE na requisição'
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalCard}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Monitoria;
