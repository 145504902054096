import React from 'react';

const ComprovanteResumido = ({ comprovante }) => {
  const {
    correntistaOrigem,
    correntistaDestino,
    bancoDestino,
    tipoContaUsuarioRecebedor,
    numeroOperacao,
    dtTransacao,
    vlrTransacao,
    transfCodigoAutenticacao,
    chave,
    tipo,
    documentoFederal,
    nome,
    codigoTransacao,
  } = comprovante;

  const hideDocumentNumber = (documentNumber) => {
    const visibleDigits = 5;
    const maskedDigits = documentNumber
      .slice(0, -visibleDigits)
      .replace(/\d/g, '*');
    return maskedDigits + documentNumber.slice(-visibleDigits);
  };

  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Date(date).toLocaleString('pt-BR', options);
  };

  const formattedValue = (value) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);

  return (
    <>
      {tipo === 'D' ? (
        <div className="comprovante-container">
          <h2>Comprovante</h2>
          <div className="comprovante-info">
            <p>
              <strong>Tipo:</strong> {chave ? 'PIX' : 'Transferência'}
            </p>
            <p>
              <strong>Origem:</strong> {correntistaOrigem.nome} (
              {hideDocumentNumber(correntistaOrigem.documentoFederal)})
            </p>
            <p>
              <strong>Destino:</strong> {correntistaDestino.nome} (
              {hideDocumentNumber(correntistaDestino.documentoFederal)})
            </p>
            <p>
              <strong>Banco Destino:</strong> {bancoDestino.descricao}
            </p>
            {tipoContaUsuarioRecebedor && (
              <p>
                <strong>Tipo de Conta do Destinatário:</strong>{' '}
                {tipoContaUsuarioRecebedor}
              </p>
            )}

            <p>
              <strong>Número da Operação:</strong>{' '}
              {numeroOperacao || transfCodigoAutenticacao}
            </p>
            <p>
              <strong>Data da Transação:</strong> {formattedDate(dtTransacao)}
            </p>
            <p>
              <strong>Valor da Transação:</strong>{' '}
              {formattedValue(vlrTransacao)}
            </p>
          </div>
        </div>
      ) : (
        <div className="comprovante-container">
          <h2>Informações</h2>
          <div className="comprovante-info">
            <p>
              <strong>Documento Origem:</strong>
              {hideDocumentNumber(documentoFederal)}
            </p>
            <p>
              <strong>Nome Origem:</strong> {nome}
            </p>
            <p>
              <strong>Código de transação:</strong> {codigoTransacao}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ComprovanteResumido;
