import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import ReactQuill from 'react-quill'; // ES6

export default function Editor({ name, label, defaultValue, ...rest }) {
  const quillRef = useRef();
  const { fieldName, registerField, error, defaultValue: val } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: quillRef.current,
      getValue: (ref) => ref.getEditorContents(),
      setValue: (ref, value) => {
        const editor = ref.createEditor(
          ref?.getEditingArea(),
          ref?.getEditorConfig()
        );

        ref.setEditorContents(editor, value);
        document
          .querySelector(`[name='${fieldName}'] .quill .ql-toolbar`)
          .remove();
      },
      clearValue: (ref) => {
        ref.setEditorContents('');
      },
    });
  }, [fieldName, registerField, quillRef]);
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>
      <div name={fieldName} className="editor-wrapper">
        <ReactQuill
          defaultValue={defaultValue || val}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ align: [] }],
              [{ font: [] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              ['link', 'image'],
              ['clean'],
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
          }}
          formats={[
            'header',
            'font',
            'color',
            'background',
            'size',
            'bold',
            'align',
            'italic',
            'underline',
            'strike',
            'script',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
          ]}
          ref={quillRef}
          theme="snow"
          {...rest}
        />
      </div>
      {error && <small className="error">{error}</small>}
    </div>
  );
}
