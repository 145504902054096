import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { Form } from '@unform/web';
import { Button, Col, ModalFooter, Row, Card, CardBody } from 'reactstrap';

import axios from 'axios';
import ufs from '../../data/ufs.json';

import api from '../../services/api';
import { Image, Input, Select } from '../../components/unform/index';

function AberturaContas() {
  const formRef = useRef();

  function debounce(func, timeout = 600) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  async function abrirConta() {
    const e = formRef.current.getData();

    e.estadoCivil = 8;
    e.tipo_pessoa = 'PF';
    e.nome_exibicao = e.nome_completo;

    const formData = new FormData();

    Object.entries(e).forEach(([key, value]) => {
      if (['documento_frente', 'documento_verso', 'selfie'].includes(key)) {
        formData.append('file', value, `${key}@${value.name}`);
      } else {
        formData.append(key, value);
      }
    });

    try {
      await api.post('/abertura-conta-backoffice', formData);
      formRef.current.reset();
      return Swal.fire({
        title: 'Conta criada com sucesso!',
        text: 'Acesse o app para mais informações',
        icon: 'success',
      });
    } catch (er) {
      const msg = er?.response?.data?.error;
      return Swal.fire({
        title: 'Erro ao abrir conta',
        text: typeof msg === 'string' ? msg : er.message,
        html:
          er?.response?.data?.tratado ||
          er?.response?.data?.erros?.join('<br />'),
        icon: 'error',
      });
    }
  }

  const processChange = debounce(() => abrirConta());

  async function handleSubmit(e) {
    const errors = {};
    Object.keys(e).forEach((f) => {
      if (
        !e[f] &&
        ![
          'complemento',
          'tipo_documento',
          'selfie',
          'documento_frente',
          'documento_verso',
        ].includes(f)
      ) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);
    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos em vermelho');
    }

    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    processChange();
  }

  const consultarCep = async (data) => {
    if (data) {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const { data: resultCep } = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${data}`
        );
        if (resultCep) {
          formRef.current.setFieldValue('logradouro', resultCep?.street || '');
          formRef.current.setFieldValue('estado', resultCep?.state || '');
          formRef.current.setFieldValue('cidade', resultCep?.city || '');
          formRef.current.setFieldValue(
            'bairro',
            resultCep?.neighborhood || ''
          );
        }
      } catch (err) {
        formRef.current.setFieldValue('logradouro', '');
        formRef.current.setFieldValue('estado', '');
        formRef.current.setFieldValue('cidade', '');
        formRef.current.setFieldValue('bairro', '');

        toast.warning('CEP não encontrado!');
      }
      Swal.close();
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Abrir conta PF</h4>
        </Col>
      </Row>
      <Card className="p-2">
        <CardBody>
          <Form ref={formRef} onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col lg={3}>
                <Input
                  name="documento"
                  label="CPF"
                  className="form-control"
                  placeholder="Digite o numero do CPF"
                />
              </Col>
              <Col lg={7}>
                <Input
                  name="nome_completo"
                  label="Nome completo"
                  className="form-control"
                  placeholder="Digite o nome completo"
                />
              </Col>
              <Col lg={2}>
                <Input
                  name="dtNasc"
                  label="Data de nascimento"
                  className="form-control"
                  type="date"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Input
                  name="rg"
                  label="RG"
                  className="form-control"
                  placeholder="Digite o numero do RG"
                />
              </Col>
              <Col lg={2}>
                <Input
                  name="rgorgao"
                  label="RG orgão"
                  className="form-control"
                  placeholder="Digite o orgão"
                />
              </Col>
              <Col lg={2}>
                <Select
                  name="rguf"
                  label="RG UF"
                  className="form-control"
                  data={ufs}
                />
              </Col>
              <Col lg={2}>
                <Input
                  name="rgdata"
                  label="Data de expedição RG"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={3}>
                <Select
                  name="sexo"
                  label="Sexo"
                  className="form-control"
                  data={[
                    { label: 'Selecione', value: '' },
                    {
                      label: 'Masculino',
                      value: 'M',
                    },
                    {
                      label: 'Feminino',
                      value: 'F',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <Select
                  name="modelo_conta"
                  label="Modelo conta"
                  className="form-control"
                  data={[
                    {
                      label: 'Pagamento',
                      value: 1,
                    },
                    {
                      label: 'Escrow',
                      value: 2,
                    },
                  ]}
                />
              </Col>
              <Col lg={6}>
                <Input
                  name="email"
                  label="Email"
                  className="form-control"
                  placeholder="Digite o email"
                />
              </Col>
              <Col lg={3}>
                <Input
                  name="telefone_pessoal"
                  label="Telefone"
                  className="form-control"
                  placeholder="Digite o telefone"
                  type="number"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  name="nomeMae"
                  label="Nome da mãe"
                  className="form-control"
                  placeholder="Digite o nome completo da mãe"
                />
              </Col>
              <Col>
                <Input
                  name="nomePai"
                  label="Nome do pai"
                  className="form-control"
                  placeholder="Digite o nome completo do pai"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={3}>
                <Select
                  name="tipo_documento"
                  label="Tipo do documento"
                  className="form-control"
                  data={[
                    {
                      value: '',
                      label: 'Selecione',
                    },
                    {
                      value: 'RG',
                      label: 'RG',
                    },
                    {
                      value: 'CNH',
                      label: 'CNH',
                    },
                    // {
                    //   value: 'RNE',
                    //   label: 'RNE',
                    // },
                    // {
                    //   value: 'CRNM',
                    //   label: 'CRNM',
                    // },
                    // {
                    //   value: 'DNI',
                    //   label: 'DNI',
                    // },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3} sm={6}>
                <Image
                  name="documento_frente"
                  label="Documento frente"
                  objectFit="fit"
                  btnSelect="Selecionar"
                />
              </Col>
              <Col lg={3} sm={6}>
                <Image
                  objectFit="fit"
                  name="documento_verso"
                  label="Documento verso"
                  btnSelect="Selecionar"
                />
              </Col>
              <Col lg={3} sm={6}>
                <Image
                  objectFit="fit"
                  name="selfie"
                  label="Selfie"
                  btnSelect="Selecionar"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={3} sm={3}>
                <Input
                  name="cep"
                  label="CEP"
                  className="form-control"
                  placeholder="Digite o CEP"
                  onBlur={(e) => {
                    consultarCep(e.target.value, 'principal');
                  }}
                />
              </Col>
              <Col md={7} sm={7}>
                <Input
                  name="logradouro"
                  label="Logradouro"
                  className="form-control"
                  placeholder="Digite o logradouro"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="numero"
                  label="Número"
                  className="form-control"
                  placeholder="Digite o nº"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={6}>
                <Input
                  name="bairro"
                  label="Bairro"
                  className="form-control"
                  placeholder="Digite o bairro"
                />
              </Col>
              <Col md={4} sm={4}>
                <Input
                  name="cidade"
                  label="Cidade"
                  className="form-control"
                  placeholder="Digite a cidade"
                />
              </Col>
              <Col md={2} sm={2}>
                <Input
                  name="estado"
                  label="Estado"
                  className="form-control"
                  placeholder="Digite o estado"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <Input
                  name="complemento"
                  label="Complemento"
                  className="form-control"
                  placeholder="Digite o complemento"
                />
              </Col>
            </Row>
            <ModalFooter>
              <Button type="submit">Continuar</Button>
            </ModalFooter>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

export default AberturaContas;
