import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import RoulettePro from 'react-roulette-pro';
import Swal from 'sweetalert2';
import 'react-roulette-pro/dist/index.css';
import { toast } from 'react-toastify';
import cumpom from '../../assets/images/comprovante.png';
import UneoCashLogo from '../../assets/images/UNEOGREEN.png';
// import UneoCashLogo from '../../assets/images/UNEOCASH.png';

import api from '../../services/api';

const getRandomIntInRange = (min = 0, max = 0) => {
  const minNumber = Math.ceil(min);
  const maxNumber = Math.floor(max);

  return Math.floor(Math.random() * (maxNumber - minNumber + 1) + minNumber);
};

const reproductionArray = (array = [], length = 0) => [
  ...Array(length)
    .fill('_')
    .map(() => array[Math.floor(Math.random() * array.length)]),
];

const generateId = () =>
  `${Date.now().toString(36)}-${Math.random().toString(36).substring(2)}`;

const formattedDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Date(date).toLocaleString('pt-BR', options);
};

const API = {
  getPrizeIndex: async (countCupons) => {
    const randomPrizeIndex = getRandomIntInRange(0, countCupons - 1);
    const randomPrizeIndexOffset = countCupons * 4;

    return randomPrizeIndex + randomPrizeIndexOffset;
  },
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Sorteio() {
  const history = useHistory();
  const [start, setStart] = useState(false);
  const [vencedor, setVencedor] = useState(null);
  const [prizeList, setPrizeList] = useState([]);
  const [prizeIndex, setPrizeIndex] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [countCupons, setCountCupons] = useState(0);
  const [valorPremio, setValorPremio] = useState(0);

  const handleStart = () => {
    setSpinning(true);

    setVencedor(null);
  };

  const handlePrizeDefined = () => {
    const cupomVencedor = prizeList[prizeIndex];

    setVencedor({
      id: cupomVencedor?.id_banco,
      cupom: cupomVencedor?.codigo,
      nome: cupomVencedor?.Contum?.Pessoa?.nome_completo,
    });
    setSpinning(false);
  };

  async function getCupons() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const { data } = await api.get('/cupons-sorteio');
    const { data: valor } = await api.get('/valor-premio-backoffice');
    if (valor) {
      setValorPremio(valor?.valor);
    }

    if (data) {
      const reproducedArray = [
        ...data,
        ...reproductionArray(data, data.length * 3),
        ...data,
        ...reproductionArray(data, data.length),
      ];

      const list = [...reproducedArray].map((item) => ({
        ...item,
        text: item?.codigo,
        image: cumpom,
        id_banco: item?.id,
        id:
          typeof crypto.randomUUID === 'function'
            ? crypto.randomUUID()
            : generateId(),
      }));

      setPrizeList(list);
      setCountCupons(data?.length);
    }
    Swal.close();
  }

  useEffect(() => {
    getCupons();
  }, []);

  useEffect(() => {
    if (!prizeIndex || start) {
      return;
    }

    setStart(true);
  }, [prizeIndex, start]);

  useEffect(() => {
    if (!spinning || !prizeList.length) {
      return;
    }

    const prepare = async () => {
      const newPrizeIndex = await API.getPrizeIndex(countCupons);
      setPrizeIndex(newPrizeIndex);
      setStart(false);
    };

    prepare();
  }, [spinning, prizeList, countCupons]);

  async function handleConcluir() {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja concluir o sorteio?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .post(`/finalizar-sorteio/${vencedor?.id}`)
            .then(() => {
              Swal.close();
              toast.success('Sorteio finalizado com sucesso!');
              history.push('/historico-sorteios');
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Sorteio</h4>
        </Col>
      </Row>
      <Card className="roulette-container">
        <Row className="justify-center">
          <Col lg={5} className="text-center">
            <img
              src={UneoCashLogo}
              className="paycash-logo"
              alt="UneocashLogo"
            />
            <hr />
            <h4>
              Realizado no dia: <br />{' '}
              <strong>{formattedDate(new Date())}</strong>
            </h4>
            <hr />
            <h1 className="text-premio">
              🤑💰{' '}
              {Number(valorPremio || 0).toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              })}{' '}
              💰🤑
            </h1>
          </Col>
        </Row>
        <Row className="justify-center mt-5 mb-5">
          <Col lg={7}>
            <div className="roulette horizontal">
              <RoulettePro
                start={start}
                prizes={prizeList}
                prizeIndex={prizeIndex}
                spinningTime={15} // Mudar pra 30 depois
                options={{ stopInCenter: true, withoutAnimation: true }}
                defaultDesignOptions={{ prizesWithText: true }}
                onPrizeDefined={handlePrizeDefined}
              />
            </div>
          </Col>
        </Row>
        {vencedor && (
          <>
            <Row className="justify-center mb-3">
              <Col lg={12} className="justify-center">
                <h2 className="flipX text-premio">
                  🎉&nbsp;&nbsp;VENCEDOR&nbsp;&nbsp;🎉
                </h2>
              </Col>
              <Col lg={12} className="justify-center">
                <h2>
                  🥳&nbsp;&nbsp; Parabéns {vencedor?.nome}! &nbsp;&nbsp;🥳
                </h2>
              </Col>
            </Row>
            <Row className="justify-center">
              <Col lg={4} className="justify-center">
                <h4>
                  UneoCash: <strong>{vencedor?.cupom}</strong>
                </h4>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <Row className="justify-center mb-3">
        <Col lg={3} className="justify-center">
          {spinning === true ? (
            <ReactLoading type="cylon" color="gray" height="20%" width="20%" />
          ) : (
            <>
              {vencedor ? (
                <>
                  <Col>
                    <hr />
                    <Button color="success" onClick={handleConcluir} block>
                      Concluír sorteio
                    </Button>
                    <br />
                    <Button
                      className="mt-3 mb-3"
                      color="danger"
                      onClick={handleStart}
                      block
                    >
                      Refazer sorteio
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <hr />
                  <Button className="mt-5" onClick={handleStart} block>
                    Sortear
                  </Button>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default Sorteio;
