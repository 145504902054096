import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { FiFilter } from 'react-icons/fi';
import {
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaAngleDown,
  FaPencilAlt,
} from 'react-icons/fa';
import Pagination from 'react-paginate';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  Input,
  Select,
  ReactSelectAsyncCreatable,
} from '../../components/unform/index';
import api from '../../services/api';
import Pessoa from './Cadastro';

function Chamados() {
  const formRef = useRef();
  const formRefEdit = useRef();
  const formRefOcorrencia = useRef();
  const formRefEditOcorrencia = useRef();
  const [chamados, setChamados] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modalFaq, setModalFaq] = useState(false);
  const [modalCad, setModalCad] = useState(false);
  const [modalPessoa, setModalPessoa] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalOccorencia, setModalOcorrencia] = useState(false);
  const [modalEditOccorencia, setModalEditOcorrencia] = useState(false);
  const [chamado, setChamado] = useState(null);
  const [ocorrencia, setOcorrencia] = useState(null);

  const [produtos, setProdutos] = useState([]);
  const [processos, setProcessos] = useState([]);
  const [sintomas, setSintomas] = useState([]);
  const [solucoes, setSolucoes] = useState([]);

  const [infosEdit, setInfosEdit] = useState({});
  const [detalhe, setDetalhe] = useState([]);

  const [pessoa, setPessoa] = useState(null);

  const toggleModalFaq = () => setModalFaq(!modalFaq);
  const toggleModalCad = () => setModalCad(!modalCad);
  const togglePessoa = () => setModalPessoa(!modalPessoa);
  const toggleModalEdit = () => setModalEdit(!modalEdit);
  const toggleModalOCorrencia = () => setModalOcorrencia(!modalOccorencia);
  const toggleModalEditOCorrencia = () =>
    setModalEditOcorrencia(!modalEditOccorencia);

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }

  // eslint-disable-next-line consistent-return
  async function filtrarConsultaPessoa(inputValue) {
    const { data } = await api.get(`/lista-pessoa?nome=${inputValue}`);
    return data.map((e) => ({ value: e.id, label: e.nome }));
  }

  let selectOptions;
  const optionsPessoas = async (inputValue) =>
    // eslint-disable-next-line no-return-await
    await new Promise((resolve) => {
      clearTimeout(selectOptions);

      selectOptions = setTimeout(() => {
        resolve(filtrarConsultaPessoa(inputValue));
      }, 500);
    });

  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getList() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/lista-ticket?${params}`);
      setChamados(data);
      window.scrollTo(0, 0);
    } catch (err) {
      toast.error('Erro ao carregar dados');
    }

    Swal.close();
  }

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  async function handleFaq(isFaq = true) {
    try {
      const { data } = await api.get('/lista-produtos');
      setProdutos(data);
      if (isFaq) {
        toggleModalFaq();
      }
    } catch (err) {
      toast.error('Erro ao buscar produtos!');
    }
  }

  async function handleProduto(e) {
    setSolucoes([]);
    setProcessos([]);
    setSintomas([]);

    if (infosEdit) {
      setInfosEdit({
        ...infosEdit,
        processo: null,
        sintoma: null,
      });
    }

    if (e) {
      try {
        const { data } = await api.get(`/lista-processo/${e}`);
        setProcessos(data);
      } catch (err) {
        toast.error('Erro ao buscar processos!');
      }
    } else {
      setProcessos([]);
    }
  }

  async function handleProcesso(e) {
    if (e) {
      try {
        if (e !== 'outro') {
          const { data } = await api.get(`/lista-sintoma/${e}`);
          setSintomas(data);
        } else {
          setSintomas([]);
        }
      } catch (err) {
        toast.error('Erro ao buscar sintomas!');
      }
    } else {
      setSintomas([]);
      setInfosEdit({
        ...infosEdit,
        sintoma: null,
      });
    }
  }

  async function handleSintoma(e) {
    if (e) {
      try {
        const { data } = await api.get(`/lista-solucao/${e}`);
        setSolucoes(data);
      } catch (err) {
        toast.error('Erro ao buscar solução!');
      }
    } else {
      setSolucoes([]);
    }
  }

  async function handleSubmit(body) {
    const errors = {};

    Object.keys(body).forEach((f) => {
      if (!body[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.showLoading();
      try {
        await api.post('/cria-ticket', body);

        getList();
        toggleModalCad();
        setSolucoes([]);
        setProcessos([]);
        setSintomas([]);
        Swal.close();
      } catch (err) {
        toast.error('Erro ao cadastrar chamado!');
        Swal.close();
      }
    }
  }

  async function handleAddPessoa(body) {
    Swal.showLoading();
    try {
      const { data } = await api.post('/cria-pessoa', body);

      const tipo = {
        value: data?.id,
        label: data?.nome,
      };
      if (formRef.current) {
        formRef.current.setFieldValue('ticketpessoa_id', tipo);
      }
      if (formRefEdit.current) {
        formRefEdit.current.setFieldValue('ticketpessoa_id', tipo);
      }
      togglePessoa();
      Swal.close();
    } catch (err) {
      toast.error('Erro ao cadastrar pessoa!');
      Swal.close();
    }
  }

  function handleChangePessoa(event) {
    setPessoa(event);
    togglePessoa();
  }

  async function handleEdit(body) {
    const errors = {};

    Object.keys(body).forEach((f) => {
      if (!body[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRefEdit.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.showLoading();
      try {
        await api.put(`/chamado/${chamado}`, body);
        getList();
        toggleModalEdit();
        setProdutos([]);
        setProcessos([]);
        setSintomas([]);
        Swal.close();
      } catch (err) {
        toast.error('Erro ao editar chamado!');
        Swal.close();
      }
    }
  }

  async function handleModalEdit(id) {
    Swal.showLoading();
    setInfosEdit({});
    setDetalhe({});
    try {
      setChamado(id);
      const { data } = await api.get(`/chamado-edit/${id}`);

      const { data: products } = await api.get('/lista-produtos');

      const produtoId = data?.infosChamadado?.produto?.id;
      const processId = data?.infosChamadado?.processo?.id;

      const { data: process } = await api.get(`/lista-processo/${produtoId}`);

      const { data: simtoms } = await api.get(`/lista-sintoma/${processId}`);

      setProdutos(products);
      setProcessos(process);
      setSintomas(simtoms);

      setInfosEdit(data?.infosChamadado);
      setDetalhe(data?.ticketDetalhe);

      Swal.close();
      toggleModalEdit();
    } catch (err) {
      toast.error('Erro ao procurar chamado!');
      Swal.close();
    }
  }

  async function handleAddOcocrrencia(body) {
    const errors = {};

    Object.keys(body).forEach((f) => {
      if (!body[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRefOcorrencia.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.showLoading();
      try {
        const { data } = await api.post(`/chamado-ocorrencia/${chamado}`, body);

        setDetalhe([data, ...detalhe]);
        getList();
        toggleModalOCorrencia();
        Swal.close();
      } catch (err) {
        toast.error('Erro ao procurar chamado!');
        Swal.close();
      }
    }
  }

  async function handleModalOcorrencia(id) {
    setChamado(id);
    toggleModalOCorrencia();
  }

  async function handleModalEditOccorencia(id) {
    setOcorrencia(id);
    toggleModalEditOCorrencia();
  }

  async function handleEditOcocrrencia(body) {
    const errors = {};

    Object.keys(body).forEach((f) => {
      if (!body[f]) {
        errors[f] = 'Obrigatório';
      }
    });

    formRefEditOcorrencia.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      Swal.showLoading();
      try {
        const { data } = await api.put(`/ocorrencia/${ocorrencia}`, body);

        setDetalhe([data, ...detalhe.filter((e) => e.id !== data.id)]);
        getList();
        toggleModalEditOCorrencia();
        Swal.close();
      } catch (err) {
        toast.error('Erro ao procurar ocorrencia!');
        Swal.close();
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Chamados</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {chamados.total}</h6>
          </Col>
          <Col>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
          <div className="d-flex justify-content-center">
            <Button
              onClick={() => {
                toggleModalCad();
                handleFaq(false);
              }}
              type="button"
              className="btn btn-primary ml-3"
            >
              Novo
            </Button>
            <Button
              onClick={() => {
                handleFaq();
              }}
              type="button"
              className="btn btn-secondary ml-1"
            >
              FAQ
            </Button>
          </div>
          <div className="d-flex justify-content-center" />
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <label className="form-label">Solicitante</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'solicitante')
                  }
                  className="form-control"
                  placeholder="Nome"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Data solicitação</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'data_solicitacao')
                  }
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Data atualização</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'data_atualizacao')
                  }
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={3}>
                <label className="form-label">Status</label>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  placeholder="Status"
                >
                  <option value="">Todos</option>
                  <option value="Aberto">Abertos</option>
                  <option value="Encerrado">Encerrados</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Assunto</th>
              <th>Data Solicitação</th>
              <th>Data Atualização</th>
              <th>Solicitante</th>
              <th>Telefone</th>
              <th>E-mail</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {chamados?.chamados?.length ? (
              chamados?.chamados?.map((tar) => (
                <tr key={tar?.id}>
                  <td>{tar?.assunto}</td>
                  <td>{new Date(tar?.createdAt)?.toLocaleString()}</td>
                  <td>{new Date(tar?.updatedAt)?.toLocaleString()}</td>
                  <td>{tar?.Ticket_pessoa?.nome}</td>
                  <td>{tar?.Ticket_pessoa?.telefone}</td>
                  <td>{tar?.Ticket_pessoa?.email}</td>
                  <td style={{ textAlign: 'center' }}>{tar?.status}</td>
                  <td data-label="Ações" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => {
                            handleModalEdit(tar?.id);
                          }}
                        >
                          <FaPencilAlt className="mr-1" />
                          Editar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={chamados.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
      <Modal isOpen={modalFaq} toggle={toggleModalFaq} size="xl">
        <ModalHeader toggle={toggleModalFaq}>Faq</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={3}>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label className="form-label">Produto</label>
                </div>

                <select
                  className="form-control"
                  onChange={(e) => {
                    handleProduto(e.target.value);
                  }}
                >
                  <option value="">Selecione</option>
                  {produtos?.map((m) => (
                    <option value={m.value}>{m.label}</option>
                  ))}
                </select>
              </div>
            </Col>
            {processos?.length ? (
              <Col lg={3}>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">Processo</label>
                  </div>

                  <select
                    className="form-control"
                    onChange={(e) => {
                      handleProcesso(e.target.value);
                    }}
                  >
                    <option value="">Selecione</option>
                    {processos?.map((m) => (
                      <option value={m.value}>{m.label}</option>
                    ))}
                  </select>
                </div>
              </Col>
            ) : (
              <></>
            )}
            {sintomas?.length ? (
              <Col lg={6}>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">Sintomas</label>
                  </div>

                  <select
                    className="form-control"
                    onChange={(e) => {
                      handleSintoma(e.target.value);
                    }}
                  >
                    <option value="">Selecione</option>
                    {sintomas?.map((m) => (
                      <option value={m.value}>{m.label}</option>
                    ))}
                  </select>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          {solucoes?.length ? (
            <Row>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Solução</th>
                  </tr>
                </thead>
                <tbody>
                  {solucoes?.length ? (
                    solucoes?.map((tar) => (
                      <tr key={tar?.id}>
                        <td>{tar?.label}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Sem registros encontrados</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Row>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalCad} toggle={toggleModalCad} size="lg">
        <Form ref={formRef} onSubmit={(data) => handleSubmit(data)}>
          <ModalHeader toggle={toggleModalCad}>Abrir chamado</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={6}>
                <ReactSelectAsyncCreatable
                  label="Pessoa"
                  name="ticketpessoa_id"
                  isClearable
                  loadOptions={optionsPessoas}
                  onCreateOption={handleChangePessoa}
                />
              </Col>
              <Col lg={6}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control"
                  data={[
                    { label: 'Aberto', value: 'Aberto' },
                    { label: 'Encerrado', value: 'Encerrado' },
                  ]}
                />
              </Col>

              <Col lg={4}>
                <Select
                  name="produto"
                  label="Produto"
                  className="form-control"
                  onChange={(e) => {
                    handleProduto(e.target.value);
                  }}
                  data={[{ label: 'Selecione', value: '' }, ...produtos]}
                />
              </Col>
              {processos?.length ? (
                <Col lg={4}>
                  <Select
                    name="processo"
                    label="Processo"
                    className="form-control"
                    onChange={(e) => {
                      handleProcesso(e.target.value);
                    }}
                    data={[
                      { label: 'Selecione', value: '' },
                      ...processos,
                      { label: 'Outro', value: 'Outro' },
                    ]}
                  />
                </Col>
              ) : (
                <></>
              )}
              {sintomas?.length ? (
                <Col lg={4}>
                  <Select
                    name="sintoma"
                    label="Sintomas"
                    className="form-control"
                    data={[
                      { label: 'Selecione', value: '' },
                      ...sintomas,
                      { label: 'Outro', value: 'Outro' },
                    ]}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col lg={12}>
                <Input
                  label="Assunto"
                  className="form-control"
                  type="text"
                  name="assunto"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Pessoa
        toggle={togglePessoa}
        isOpen={modalPessoa}
        value={pessoa}
        onAdd={handleAddPessoa}
      />

      <Modal isOpen={modalEdit} toggle={toggleModalEdit} size="xl">
        <Form ref={formRefEdit} onSubmit={(data) => handleEdit(data)}>
          <ModalHeader toggle={toggleModalEdit}>Editar chamado</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={4}>
                <ReactSelectAsyncCreatable
                  label="Pessoa"
                  name="ticketpessoa_id"
                  isClearable
                  loadOptions={optionsPessoas}
                  onCreateOption={handleChangePessoa}
                  defaultValue={
                    infosEdit?.Ticket_pessoa?.id && {
                      value: infosEdit?.Ticket_pessoa?.id,
                      label: infosEdit?.Ticket_pessoa?.nome,
                    }
                  }
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={4}>
                <Select
                  name="produto"
                  label="Produto"
                  className="form-control"
                  onChange={(e) => {
                    handleProduto(e.target.value);
                  }}
                  data={[{ label: 'Selecione', value: '' }, ...produtos]}
                  defaultValue={infosEdit?.produto?.id}
                />
              </Col>
              {processos?.length ? (
                <Col lg={4}>
                  <Select
                    name="processo"
                    label="Processo"
                    className="form-control"
                    onChange={(e) => {
                      handleProcesso(e.target.value);
                    }}
                    data={[
                      { label: 'Selecione', value: '' },
                      ...processos,
                      { label: 'Outro', value: 'Outro' },
                    ]}
                    defaultValue={infosEdit?.processo?.id}
                  />
                </Col>
              ) : (
                <></>
              )}
              {sintomas?.length ? (
                <Col lg={4}>
                  <Select
                    name="sintoma"
                    label="Sintomas"
                    className="form-control"
                    data={[
                      { label: 'Selecione', value: '' },
                      ...sintomas,
                      { label: 'Outro', value: 'Outro' },
                    ]}
                    defaultValue={infosEdit?.sintoma?.id}
                  />
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {infosEdit?.produto || infosEdit?.process || infosEdit?.sintoma ? (
              <hr />
            ) : (
              <></>
            )}
            <Row>
              <Col lg={12}>
                <Input
                  label="Assunto"
                  className="form-control"
                  type="text"
                  name="assunto"
                  defaultValue={infosEdit?.assunto}
                />
              </Col>
              <Col lg={6}>
                <Select
                  name="status"
                  label="Status"
                  className="form-control"
                  data={[
                    { label: 'Aberto', value: 'Aberto' },
                    { label: 'Encerrado', value: 'Encerrado' },
                  ]}
                  defaultValue={infosEdit?.status}
                />
              </Col>
              <Col lg={3}>
                <label className="form-label">Consultar FAQ</label>
                <Button
                  onClick={() => {
                    handleFaq();
                  }}
                  type="button"
                  className="btn btn-secondary"
                  block
                >
                  FAQ
                </Button>
              </Col>
              <Col lg={3}>
                <label className="form-label">Adicionar ocorrência</label>
                <Button
                  onClick={() => {
                    handleModalOcorrencia(infosEdit?.id);
                  }}
                  type="button"
                  className="btn btn-secondary"
                  block
                >
                  Ocorrência
                </Button>
              </Col>
            </Row>
            <Row>
              {detalhe?.length ? (
                <Row>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Última atualização</th>
                        <th>Ocorrência</th>
                        <th>Operador</th>
                        <th className="text-center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {detalhe?.length ? (
                        detalhe?.map((tar) => (
                          <tr key={tar?.id}>
                            <td>
                              {new Date(tar?.updatedAt)?.toLocaleString()}
                            </td>
                            <td>{tar?.ocorrencia}</td>
                            <td>{tar?.operador}</td>
                            <td data-label="Ações" className="text-center">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  color="link"
                                  className="text-decoration-none padding-0-5"
                                >
                                  <FaCog className="mr-2" /> <FaAngleDown />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    onClick={() => {
                                      handleModalEditOccorencia(tar?.id);
                                    }}
                                  >
                                    <FaPencilAlt className="mr-1" />
                                    Editar
                                  </DropdownItem>
                                  <hr />
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>Sem registros encontrados</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Row>
              ) : (
                <></>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modalOccorencia} toggle={toggleModalOCorrencia} size="md">
        <Form
          ref={formRefOcorrencia}
          onSubmit={(data) => handleAddOcocrrencia(data)}
        >
          <ModalHeader toggle={toggleModalOCorrencia}>
            Adicionar ocorrencia
          </ModalHeader>
          <ModalBody>
            <Input
              label="Ocorrência"
              className="form-control"
              type="text"
              name="ocorrencia"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        isOpen={modalEditOccorencia}
        toggle={toggleModalEditOCorrencia}
        size="md"
      >
        <Form
          ref={formRefEditOcorrencia}
          onSubmit={(data) => handleEditOcocrrencia(data)}
        >
          <ModalHeader toggle={toggleModalEditOCorrencia}>
            Editar ocorrencia
          </ModalHeader>
          <ModalBody>
            <Input
              label="Ocorrência"
              className="form-control"
              type="text"
              name="ocorrencia"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Chamados;
