import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import api from '../../services/api';

function Acessos() {
  const [acessos, setAcessos] = useState([]);
  const [filtro, setFiltro] = useState({});

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }

  // eslint-disable-next-line consistent-return
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getList() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/list-acessos?${params}`);
      setAcessos(data);
      window.scrollTo(0, 0);
    } catch (err) {
      toast.error('Erro ao carregar dados');
    }

    Swal.close();
  }

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Log Acesso</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {acessos.total}</h6>
          </Col>
          <Col>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
          <div className="d-flex justify-content-center" />
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={2}>
                <label className="form-label">Data acesso</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'data')}
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Módulo</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'modulo')}
                  className="form-control"
                  placeholder="Módulo"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Nome</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'usuario_nome')
                  }
                  className="form-control"
                  placeholder="Nome"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Email</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'usuario_email')
                  }
                  className="form-control"
                  placeholder="Email"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">IP</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'ip')}
                  className="form-control"
                  placeholder="IP"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Módulo</th>
              <th>Nome</th>
              <th>Email</th>
              <th>IP</th>
            </tr>
          </thead>
          <tbody>
            {acessos?.acessos?.length ? (
              acessos?.acessos?.map((tar) => (
                <tr key={tar?.id}>
                  <td>{new Date(tar?.createdAt)?.toLocaleString()}</td>
                  <td>{tar?.modulo}</td>
                  <td>{tar?.usuario_nome}</td>
                  <td>{tar?.usuario_email}</td>
                  <td>{tar?.ip}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={acessos.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Acessos;
