import React, { useRef, useEffect } from 'react';
import Select from 'react-select/async-creatable';
import { useField } from '@unform/core';
import customStyles from './stylesSelect';

export default function AsyncSelect({
  name,
  disabled = false,
  defaultValue,
  label,
  fullObj = false,
  className = '',
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (fullObj || !ref.select.state.value) {
            return ref.select.state.value || [];
          }

          if (ref.select.state.value) {
            return ref.select.state.value.map((option) => option.value);
          }
        }

        if (fullObj || !ref.select.state.value) {
          return ref.select.state.value;
        }

        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.state.value = value;
        ref.select.updater.enqueueForceUpdate(ref);
      },
    });
  }, [fieldName, registerField, rest.isMulti, fullObj]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <div className={error && 'error-select'}>
        <Select
          defaultValue={defaultValue}
          styles={customStyles}
          ref={selectRef}
          cacheOptions
          classNamePrefix="react-select"
          isDisabled={disabled}
          placeholder="Pesquisar"
          noOptionsMessage={() => 'Digite para pesquisar'}
          formatCreateLabel={(text) => `Criar "${text}"`}
          {...rest}
        />
      </div>
    </>
  );
}
