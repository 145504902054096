import React, { useRef, useEffect } from 'react';
import Select from 'react-select/async';
import { useField } from '@unform/core';
import customStyles from './stylesSelect';

export default function AsyncSelect({ name, defaultValue, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }
          return ref.select.state.value.map((option) => option.value);
        }
        if (!ref.select.state.value) {
          return '';
        }
        return ref.select.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.state.value = value;
        ref.select.updater.enqueueForceUpdate(ref);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <div className={error && 'error-select'}>
        <Select
          cacheOptions
          defaultValue={defaultValue}
          ref={selectRef}
          noOptionsMessage={() => 'Não há registros'}
          classNamePrefix="react-select"
          styles={customStyles}
          {...rest}
        />
      </div>
    </>
  );
}
