const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 'var(--border-radius)',
    boxShadow: isFocused ? '0 3px 10px 0 rgba(34, 41, 47, 0.1)' : null,
    borderColor: isFocused ? 'var(--color-secondary)' : '#ced4da',
    marginTop: -2,
  }),
};

export default customStyles;
