import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { FiFilter } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
} from 'reactstrap';
import api from '../../services/api';

function RecargaCelular() {
  const [recargasCelular, setRecargasCelular] = useState([]);
  const [filtro, setFiltro] = useState({});

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }

  function formatDate(date) {
    if (date) {
      const dataArray = date?.split('T')[0]?.split('-');
      return `${dataArray[2]}/${dataArray[1]}/${dataArray[0]}`;
    }
    return 'Sem data';
  }

  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  useEffect(() => {
    async function getList() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/recargalist?${params}`);
      console.log(data);
      setRecargasCelular(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getList();
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Recarga / Gift</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">
              Total de registros: {recargasCelular.total}
            </h6>
          </Col>
          <Col>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <label className="form-label">Conta</label>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'numero_conta')
                  }
                  className="form-control"
                  placeholder="Número conta"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Data</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'data')}
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={2}>
                <label className="form-label">Status</label>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  type="text"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Conta</th>
              <th>DDD</th>
              <th>Telefone</th>
              <th>Provider ID</th>
              <th>Transaction ID Celcoin</th>
              <th>ID Zoop</th>
              <th>Valor</th>
              <th>Origem</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {recargasCelular.results?.length ? (
              recargasCelular.results?.map((tar) => (
                <tr key={tar?.id}>
                  <td>{new Date(tar?.data)?.toLocaleString()}</td>
                  <td>{tar?.Contum?.numero_conta}</td>
                  <td>{tar?.ddd}</td>
                  <td>{tar?.telefone}</td>
                  <td>{tar?.provider_id}</td>
                  <td>{tar?.transaction_id}</td>
                  <td>{tar?.id_zoop}</td>
                  <td>
                    {Number(tar?.valor)?.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td>{tar?.origem}</td>
                  <td>{tar?.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={recargasCelular.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default RecargaCelular;
