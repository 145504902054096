import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select/creatable';
import { useField } from '@unform/core';
import customStyles from './stylesSelect';

export default function Select({ name, label, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  return (
    <>
      <div className="d-flex justify-content-between">
        <label htmlFor={fieldName} className="form-label">
          {label}
        </label>
        {error && <small className="error">{error}</small>}
      </div>
      <div className={error && 'error-select'}>
        <ReactSelect
          defaultValue={defaultValue}
          styles={customStyles}
          ref={selectRef}
          classNamePrefix="react-select"
          {...rest}
        />
      </div>
    </>
  );
}
