/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';

function Contas() {
  const [results, setResults] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getConta() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    const { data } = await api.get(`/transferecias-clientes?${params}`);
    setResults(data);
    window.scrollTo(0, 0);

    Swal.close();
  }
  useEffect(() => {
    getConta();
  }, [filtro]);

  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Date(date).toLocaleString('pt-BR', options);
  };

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Transferências Clientes</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <Col lg={6}>
            <h6 className="txt-muted">Total de registros: {results.total}</h6>
          </Col>
          <Col lg={6}>
            <Button
              color="muted"
              id="toggler"
              className="d-flex align-items-center"
            >
              <FiFilter size={20} />
              &nbsp;&nbsp;Filtros
            </Button>
          </Col>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <input
                  onChange={(e) =>
                    debounceConsulta(e.target.value, 'numero_conta')
                  }
                  className="form-control"
                  placeholder="Conta"
                />
              </Col>
              <Col lg={3}>
                <select
                  onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  className="form-control"
                  placeholder="Status"
                >
                  <option value="">Todas</option>
                  <option value="EFETUADO">Efetuado</option>
                  <option value="PENDENTE">Pendente</option>
                  <option value="ERRO">Erro</option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Conta</th>
              <th>Valor</th>
              <th>Código transação</th>
              <th>Código Autenticação</th>
              <th>Destinatário</th>
              <th>Data</th>
              <th>Status</th>
              {/* <th className="text-center">Ação</th> */}
            </tr>
          </thead>
          <tbody>
            {results?.results?.length ? (
              results.results?.map((c) => (
                <tr key={c?.id}>
                  <td>
                    {c?.Contum?.numero_conta} {c?.Contum?.digito_conta}
                  </td>
                  <td>
                    {Number(c?.valor_transacao || 0).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </td>
                  <td>{c?.codigo_transacao}</td>
                  <td>{c?.codigo_autenticacao}</td>
                  <td>
                    Nome: {c?.nome_destinatario} - Conta:{' '}
                    {c?.conta_destinatario} - Ag: {c?.agencia_destinatario} -
                    Compe: {c?.compe_destinatario}
                  </td>
                  <td>{formattedDate(c?.createdAt)}</td>
                  <td>{c?.status}</td>
                  {/* <td className="text-center">
                    <UncontrolledDropdown size="xs">
                      <DropdownToggle color="link link-gray" size="xs">
                        <FaEllipsisH size="1.3em" />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => {
                            informeRendimentos(c);
                          }}
                          size="xs"
                        >
                          Informe de rendimentos
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={results.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>
    </>
  );
}

export default Contas;
