import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import {
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaAngleDown,
  FaCubes,
  FaEye,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from 'reactstrap';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { ReactSelectAsync } from '../../components/index';
import getOptions from '../../utils/getOptionsUneo';

import api from '../../services/api';

function ControleCaixa() {
  const formRef = useRef(null);
  const [vendas, setVendas] = useState([]);
  const [filtro, setFiltro] = useState({});

  const [modal, setModal] = useState(false);

  const [estabelecimento, setEstabelecimento] = useState(null);

  const [estabelecimentos, setEstabelecimentos] = useState([]);

  const toggle = () => setModal(!modal);

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function pesquisaVendas() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    try {
      const url = `/vendas-estabelecimento?estabelecimento=${estabelecimento}&${params}`;

      const { data } = await api.get(url);

      setVendas(data);
      Swal.close();
    } catch (err) {
      toast.error('Erro ao buscar dados!');
      Swal.close();
    }
  }
  async function carregaEstabelecimantos() {
    try {
      const { data } = await api.get('/estabelecimentos');

      setEstabelecimentos(data);
    } catch (err) {
      toast.error('Erro ao buscar estabelecimentos!');
    }
  }

  useEffect(() => {
    if (estabelecimento) {
      pesquisaVendas();
    }
    carregaEstabelecimantos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro, estabelecimento]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Vendas</h4>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Form ref={formRef} onSubmit={() => pesquisaVendas()}>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <label>Estabelecimento</label>
                  <ReactSelectAsync
                    isClearable
                    loadOptions={async (value) => {
                      const data = await getOptions({
                        value,
                        route: '/estabelecimentos',
                      });
                      return data;
                    }}
                    defaultOptions={estabelecimentos}
                    placeholder=" "
                    onChange={(opt) => setEstabelecimento(opt?.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
        {estabelecimento && (
          <>
            <div className="p-3 justify-content-between d-flex align-items-center">
              <Col lg={6}>
                <h6 className="txt-muted">
                  Total de registros: {vendas.total}
                </h6>
              </Col>
              <Col>
                <Button
                  color="muted"
                  id="toggler"
                  className="d-flex align-items-center"
                >
                  <FiFilter size={20} />
                  &nbsp;&nbsp;Filtros
                </Button>
              </Col>
            </div>
            <div className="filter-collapse px-3 ">
              <UncontrolledCollapse toggler="#toggler" className="pb-3">
                <Row>
                  <Col lg={2}>
                    <label className="form-label">Data</label>
                    <input
                      onChange={(e) => debounceConsulta(e.target.value, 'data')}
                      className="form-control"
                      type="date"
                    />
                  </Col>
                  <Col lg={2}>
                    <label className="form-label">Bordero</label>
                    <input
                      onChange={(e) =>
                        debounceConsulta(e.target.value, 'bordero')
                      }
                      className="form-control"
                      type="number"
                    />
                  </Col>
                </Row>
              </UncontrolledCollapse>
            </div>

            <Table responsive>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Borderô</th>
                  <th>Produto</th>
                  <th>Valor</th>
                  <th>Forma Pagt.</th>
                  <th>Valor Pago</th>
                  <th>Troco</th>
                  <th>Status</th>
                  {/* <th style={{ textAlign: 'center' }}>Ações</th> */}
                </tr>
              </thead>
              <tbody>
                {vendas?.vendas?.length ? (
                  vendas?.vendas?.map((tar) => (
                    <tr key={tar?.id}>
                      <td>
                        {tar?.created_at &&
                          new Date(tar?.created_at)?.toLocaleString()}
                      </td>
                      <td>{tar?.Bordero?.id}</td>
                      <td>{tar?.produto}</td>
                      <td>
                        {Number(tar?.valor)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{tar?.forma_pagamento}</td>
                      <td>
                        {Number(tar?.valor_pago)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>
                        {Number(tar?.valor_troco)?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      <td>{tar?.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>Sem registros encontrados</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={vendas.pages || 1}
              onPageChange={({ selected }) =>
                setFiltro({ ...filtro, page: selected + 1 })
              }
              initialPage={0}
              containerClassName="pagination justify-end mr-1"
              activeClassName="active"
            />
          </>
        )}
      </Card>
    </>
  );
}

export default ControleCaixa;
