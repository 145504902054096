import React, { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import {
  Card,
  Col,
  Row,
  CardFooter,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { ValidationError } from 'yup';
import Swal from 'sweetalert2';
import { Input } from '../../components/unform/index';

import mudarNome from '../../validations/mudaNome';
import mudarSenha from '../../validations/mudaSenha';

import api from '../../services/api';
import { useAuth } from '../../contexts/auth';

function Perfil() {
  const { changeName } = useAuth();
  const formRef = useRef();
  const formRefModal = useRef();
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    async function getNome() {
      try {
        const { data } = await api.get('/nome');
        formRef.current.setFieldValue('nome', data.nome);
      } catch (er) {
        toast.error('Erro ao buscar nome!');
      }
    }
    getNome();
  }, []);

  async function hadlesubmit(e) {
    formRef.current.setErrors({});
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await mudarNome(e);
      const { data } = await api.put('/nome', e);
      changeName(data.nome);
      toast.success('Nome alterado com sucesso');
      toggle();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        Swal.close();
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        Swal.fire({
          text: error,
          icon: 'error',
        });
      }
    }
  }

  async function handlesubmitNovaSenha(e) {
    formRefModal.current.setErrors({});
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await mudarSenha(e);
      await api.put('/senha', e);
      toast.success('Senha alterada com sucesso');
      Swal.close();
      toggle();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        Swal.close();
        formRefModal.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        Swal.fire({
          text: error,
          icon: 'error',
        });
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Perfil</h4>
        </Col>
      </Row>
      <Card>
        <Form
          ref={formRef}
          onSubmit={(e) => {
            hadlesubmit(e);
          }}
        >
          <CardBody className="d-flex justify-content-center">
            <Col>
              <Input name="nome" label="Nome" className="form-control" />
            </Col>
          </CardBody>
          <CardFooter className="justify-end">
            <Button
              color="warning"
              style={{ marginRight: '5px' }}
              onClick={toggle}
            >
              Mudar senha
            </Button>
            <Button color="primary" type="submit">
              Salvar
            </Button>
          </CardFooter>
        </Form>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Mudar senha</ModalHeader>
        <Form
          ref={formRefModal}
          onSubmit={(e) => {
            handlesubmitNovaSenha(e);
          }}
        >
          <ModalBody>
            <Row>
              <Col>
                <Input
                  type="password"
                  name="senha"
                  label="Senha atual"
                  className="form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  type="password"
                  name="novaSenha"
                  label="Nova senha"
                  className="form-control"
                />
              </Col>
              <Col>
                <Input
                  type="password"
                  name="resenha"
                  label="Digite novamente"
                  className="form-control"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              Salvar
            </Button>{' '}
            <Button color="secondary" onClick={toggle}>
              Cancelar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Perfil;
