import React, { useEffect, useRef, useState } from 'react';
import { FiFilter, FiPlus } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  FaAngleDown,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaEdit,
  FaEye,
} from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse,
  ModalHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Select from '../../components/unform/select';
import Input from '../../components/unform/input';
import ReactSelectAsync from '../../components/unform/reactSelectAsync';

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) {
    month = `0${month}`;
  }
  if (day.length < 2) {
    day = `0${day}`;
  }

  return [day, month, year].join('/');
}

function GestaoDeCredito() {
  const [creditoList, setCreditoList] = useState([]);
  const [filtro, setFiltro] = useState({});
  const [modal, setModal] = useState(false);
  const optionsRef = useRef();
  const formRef = useRef();

  let timeoutConsulta;

  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e, page: 1 });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  async function getConta() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const params = Object.keys(filtro)
      .map((e) => {
        if (e === 'page') {
          if (Number.isNaN(filtro[e])) {
            return `${e}=${1}`;
          }
        }
        return `${e}=${filtro[e]}`;
      })
      .join('&');

    const { data } = await api.get(`/consulta-credito?${params}`);

    setCreditoList(data);
    window.scrollTo(0, 0);

    Swal.close();
  }
  const toggleModal = () => setModal(!modal);

  async function filtrarConsultaOpts({ documento }) {
    const result = await api.get(`/options/conta?documento=${documento}`);
    return result.data;
  }

  const promiseOptions = async (val) =>
    new Promise((resolve) => {
      clearTimeout(optionsRef.current);

      optionsRef.current = setTimeout(() => {
        resolve(filtrarConsultaOpts(val));
      }, 300);
    });

  async function handleEditar(dados) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/alterar-credito', {
        ...dados,
        id_conta: modal?.id,
      });
      Swal.close();
      toggleModal();
      toast.success('UneoCrédito alterado com sucesso');
    } catch (err) {
      Swal.close();
      toast.error(
        err?.response?.data?.error || 'Erro interno, tente novamente mais tarde'
      );
    }
  }

  async function handleCriarCredito(dados) {
    if (modal?.id) {
      return handleEditar(dados);
    }
    const errors = {};
    Object.entries(dados).forEach(([key, value]) => {
      if (!value) {
        errors[key] = 'Obrigatório';
      }
    });

    if (Object.keys(errors).length) {
      return toast.error('Verifique os campos obrigatórios');
    }

    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post('/abrir-credito', dados);
      Swal.close();
      toggleModal();
      toast.success('UneoCrédito criado com sucesso');
    } catch (err) {
      Swal.close();
      toast.error(
        err?.response?.data?.error || 'Erro interno, tente novamente mais tarde'
      );
    }
  }

  useEffect(() => {
    getConta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3">Gestão de crédito</h4>
        </Col>
      </Row>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {creditoList.total}</h6>

          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
          <Button onClick={toggleModal}>Novo</Button>
        </div>
        <div className="filter-collapse px-3 ">
          <UncontrolledCollapse toggler="#toggler" className="pb-3">
            <Row>
              <Col lg={3}>
                <input
                  onChange={(e) => debounceConsulta(e.target.value, 'conta')}
                  className="form-control"
                  placeholder="Conta"
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>Conta</th>
              <th>Limite</th>
              <th>Disponível</th>
              <th style={{ textAlign: 'center' }}>Dia de vencimento</th>
              {/* <th style={{ textAlign: 'center' }}>Latitude</th>
              <th style={{ textAlign: 'center' }}>Longitude</th> */}
              <th style={{ textAlign: 'center' }}>Data criação</th>
              <th style={{ textAlign: 'center' }}>Status</th>
              <th style={{ textAlign: 'center' }}>Ações</th>
            </tr>
          </thead>
          <tbody>
            {creditoList?.credito?.length ? (
              creditoList?.credito?.map((c) => (
                <tr key={c?.id}>
                  <td>{c?.Contum?.Pessoa?.nome_completo}</td>
                  <td>
                    {Number(c?.limite).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td>
                    {Number(c?.disponivel).toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                    })}
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.dia_vencimento}</td>
                  {/* <td style={{ textAlign: 'center' }}>{c?.latitude}</td>
                  <td style={{ textAlign: 'center' }}>{c?.longitude}</td> */}
                  <td style={{ textAlign: 'center' }}>
                    {formatDate(c?.createdAt)}
                  </td>
                  <td style={{ textAlign: 'center' }}>{c?.status}</td>
                  <td data-label="Ações" className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="link"
                        className="text-decoration-none padding-0-5"
                      >
                        <FaCog className="mr-2" /> <FaAngleDown />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() => {
                            setModal(c);
                            const obj = {
                              ...c,
                              id_conta: {
                                value: c.id_conta,
                                label: c?.Contum?.Pessoa?.nome_completo,
                              },
                              vencimento: String(c?.dia_vencimento).padStart(
                                2,
                                '0'
                              ),
                            };
                            setTimeout(() => {
                              formRef.current.setData(obj);
                            }, 300);
                          }}
                        >
                          <FaEdit className="mr-1" />
                          Editar
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>Sem registros encontrados</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={creditoList.pages || 1}
          onPageChange={({ selected }) =>
            setFiltro({ ...filtro, page: selected + 1 })
          }
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Abertura UneoCrédito</ModalHeader>
        <Form ref={formRef} onSubmit={handleCriarCredito}>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                <ReactSelectAsync
                  name="id_conta"
                  isClearable
                  isDisabled={!!modal?.id}
                  label="Conta"
                  loadOptions={(documento) => promiseOptions({ documento })}
                  placeholder="CPF/CNPJ conta"
                />
              </Col>
              <Col lg={6} className="mb-3">
                <Input
                  name="limite"
                  label="Limite de crédito"
                  className="form-control"
                  placeholder="0,00"
                  step={0.01}
                  type="number"
                />
              </Col>
              {modal?.id && (
                <Col lg={6} className="mb-3">
                  <Input
                    name="disponivel"
                    label="Crédito disponível"
                    className="form-control"
                    placeholder="0,00"
                    step={0.01}
                    disabled
                    type="number"
                  />
                </Col>
              )}
              <Col lg={6} className="mb-3">
                <Select
                  name="vencimento"
                  label="Vencimento fatura"
                  className="form-control"
                  data={[
                    { label: 'Selecione', value: '' },
                    { label: '05', value: '5' },
                    { label: '10', value: '10' },
                    { label: '15', value: '15' },
                  ]}
                />
              </Col>
              {modal?.id && (
                <Col lg={6} className="mb-3">
                  <Select
                    name="status"
                    label="Status"
                    className="form-control"
                    data={[
                      { label: 'Ativo', value: 'Ativo' },
                      { label: 'Inativo', value: 'Inativo' },
                    ]}
                  />
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button>Salvar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default GestaoDeCredito;
